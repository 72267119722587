export function forgotpassword(req) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType"),
    },
    body: JSON.stringify(req),
  };
  console.log("POST Login", requestOptions);
  return fetch("/shared/api/forgotpassword", requestOptions).then((response) =>
    response.json()
  );
}
