import React from 'react';
import { EReCaptchaV2Size, EReCaptchaV2Theme, ReCaptchaProvider, ReCaptchaV2 } from 'react-recaptcha-x';

import config from '../../../config/config.json'
class CaptchaComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    v2Callback = (token) => {
        let isCaptchaSelected = false
        let errorMsg = ""
        if (typeof token === 'string') {
            console.log('this is the token', token);
            isCaptchaSelected = true
        } else if (typeof token === 'boolean' && !token) {
            errorMsg = "token has expired, user must check the checkbox again"
            console.log(errorMsg);
        } else if (token instanceof Error) {
            errorMsg = "error. please check your network connection"
            console.log(isCaptchaSelected, errorMsg);
        }
        this.props.setCaptchDataCallback(isCaptchaSelected, errorMsg)
    };

    render() {
        return (
            <div className="Captcha_Component-div" >
                <ReCaptchaProvider
                    // siteKeyV2={config.CAPTCHA_SITE_KEY}
                    siteKeyV2={this.props.siteData.site.login_captcha_key}
                    // siteKeyV3={""}
                    langCode="en"
                    hideV3Badge={false}
                >
                    <ReCaptchaV2
                        callback={this.v2Callback}
                        theme={EReCaptchaV2Theme.Light}
                        size={EReCaptchaV2Size.Normal}
                        id="my-id"
                        data-test-id="my-test-id"
                        tabindex={0}
                    />
                    {/* <ReCaptchaV3 action="your-action" callback={() => { }} /> */}
                </ReCaptchaProvider>
                {this.props.errorMsg && <span>{this.props.errorMsg}</span>}
            </div>
        );
    }
}

export default CaptchaComponent;