export async function getSystemDetails() {
    let detail = {ip: '', userAgent: ''};
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        detail.ip = data.ip;
        detail.userAgent = navigator.userAgent
        return detail;
    } catch (error) {
        console.error('There was a problem fetching the IP address:', error.message);
        return detail;
    }
}