import CryptoJS from 'crypto-js';

const passphrase = "THEDADABHAIKEY"; // Declare passphrase here
// Encryption function
const encryptData = (data) => {
    try {
     
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), passphrase).toString();
      return encrypted;
    } catch (error) {
      console.error('Encryption Error:', error);
      return null;
    }
  };
  
  // Decryption function
  const decryptData = (encryptedData) => {
    try {
      
      const bytes = CryptoJS.AES.decrypt(encryptedData, passphrase);
      const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decrypted;
    } catch (error) {
      console.error('Decryption Error:', error);
      return null;
    }
  };

  export { encryptData, decryptData };