export default function ResetPassword(props) {
    let styles = props.cssfile;
    const BackToForgotPassword = ()=>{
        // console.log("testttt");
        window.location.href = "/signin?s=0"
        // props.setType("Forgotpassword")
    }

    const BackToLogin = ()=>{
        window.location.href = "/signin?s=0"
    }
    return(
    <>
        <div className={styles.invalidmodal_wrap}>
            {/* <div className={styles.brand_logo}>
                <img src="/images/logoele.png" alt="" />
            </div> */}
            <div className={styles.inv_heading}>Invalid Password Reset Link</div>
            < div className={styles.inv_title}> This link is no longer valid.Please try resetting
            your password again </div>
            <div className={styles.button_wrap}>
                <button className={styles.reset_btn} onClick={() => BackToForgotPassword()}>Reset</button>
                {/* <button className={styles.cancel_btn} onClick={() => BackToLogin()}>Cancel</button> */}
            </div>
        </div>
    </>
    )

}