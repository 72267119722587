// import styles from './Desktop/theme1/index.module.css';

const ChangePassword = (props) => {
    let styles = props.cssfile;
    return (
        <>
            {/* <div className={styles.back} onClick={props.onClickBackLink}>Back</div> */}
            <div className={styles.login_by_mail_id_text}>Change Password</div>
            <div className={styles.pswd_text}>A strong password is at least 8 characters in lenght and contains letters, number, and symbol</div>
            {props.changePasswordAPIErr !== "" ? <div className={styles.email_error_msg}>{props.changePasswordAPIErr}</div> : ""}
            <input className={styles.email_id} id="password" type="password" placeholder="New Password" maxLength="50" onChange={props.onChangeNewPassword} />
            {props.newPasswordErr !== "" ? <div className={styles.email_error_msg}>{props.newPasswordErr}</div> : ""}
            <input className={styles.email_id} id="password" type="password" placeholder="Confirm New Password" maxLength="50" onChange={props.onChangeConfirmPassword} />
            {props.confirmPasswordErr !== "" ? <div className={styles.email_error_msg}>{props.confirmPasswordErr}</div> : ""}
            <button className={styles.btn_login} onClick={props.onClickConfirmbtn}>Confirm</button>
        </>
    )
}
export default ChangePassword;