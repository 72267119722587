import Script from 'next/script'

import siteMetadata from '@/data/siteMetadata'

const GACScript = (props) => {
  // console.log('ANLAYTICS',props?.google?.google_analytics_id)
  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${props?.google?.google_analytics_id}`}
      />

      <Script strategy="lazyOnload" id="ga-script">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${props?.google?.google_analytics_id}', {
              page_path: window.location.pathname,
            });
        `}
      </Script>
    </>
  )
}

export default GACScript

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const logEvent = (action, category, label, value) => {
  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

//key:UA-245304868-2

