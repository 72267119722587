import Script from 'next/script'
const GTagScript = (props) => {
  // console.log('ANLAYTICS',props.google.google_tag_manager_id)
  return (
    <>
      <Script strategy="lazyOnload" id="gtag-script">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${props?.google?.google_tag_manager_id}');
        `}
      </Script>
    </>
  )
}
export default GTagScript

//key :GTM-TPG73H5

