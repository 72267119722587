import React, { Component, createRef } from 'react';
import { googlelogin } from '../../../../../common/lib/googlelogin'

import config from '../../../../../config/config.json'


class GoogleLoginComponent extends Component {
    constructor(props) {
        // console.log(props,"google login");
        super(props)
        this.googleLoginBtn = createRef()
    }
    componentDidMount() {
        this.googleSDK();
    }
    prepareLoginButton = () => {
        console.log("this.googleLoginBtn", this.googleLoginBtn.current);
        this.auth2.attachClickHandler(this.googleLoginBtn.current, {},
            (googleUser) => {
                console.log("googleUser", googleUser)
                let profile = googleUser.getBasicProfile();
                let data = {
                    "token": googleUser.getAuthResponse().id_token,
                    "id": profile.getId(),
                    "first_name": profile.getName(),
                    "email": profile.getEmail(),
                    "image": profile.getImageUrl()
                }
                console.log("data", data);
                //loin in with google 
                googlelogin(data).then(response => {
                    console.log("response", response);
                    if (response.status) {
                        if (response.type === "R") {
                            this.props.getSignUpForm({ ...data, first_name: response?.data?.first_name, registration_token: response?.data?.registration_token }, response.type)
                        } else {
                            location.reload()
                            // window.location.href = '/'
                        }
                        // } else {
                        // alert(response.message);
                    }
                })
                //Logic Here
            }, (error) => {
                console.log("error", error)
                // alert(JSON.stringify(error, undefined, 2));
            });
    }
    googleSDK = () => {
        window['googleSDKLoaded'] = () => {
            window['gapi'].load('auth2', () => {
                this.auth2 = window['gapi'].auth2.init({
                    // "client_id": "114646322675-doifv0tba7pito9obicmnd3127uet903.apps.googleusercontent.com", //For local
                    // client_id: '387851911169-j8kdaqfadnevqsp0rer5pef6u5toip3h.apps.googleusercontent.com',
                    // client_id: config.GOOGLE_CLIENT_ID,
                    client_id: this.props?.siteData?.google_login_key,
                    cookiepolicy: 'single_host_origin',
                    scope: 'profile email'
                });
                this.prepareLoginButton();
            });
        }
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = config.GOOGLE_API_SRC;
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
    }
    componentWillUnmount() {
        window['googleSDKLoaded'] = undefined
        let ggljssdkelement =  document.getElementById("google-jssdk")
        if(ggljssdkelement){
            ggljssdkelement.remove()
        }
    }
    render() {
        console.log("this.props",this.props)
        return (
            <>
            {/* {this.props.isSignIn ?"":
            <span >or</span>} */}
              {/* <div className={this.props.isSignIn ?this.props.styles?.btnor:""}></div> */}
            <button className={this.props.styles?.googleLoginBtn_theme3} ref={this.googleLoginBtn}>
                <img src='images/google_icon.svg' alt=""/>
                <span>{`${this.props.text} with Google`}</span>
            </button>
            </>
        );
    }
}
export default GoogleLoginComponent;