// import styles from './Theme5/theme1/index.module.css';

const ChangePassword = (props) => {
    console.log("ChangePassword",props)

    let styles = props.cssfile;
  
    return (
        <>
            {/* <div className={styles.back} onClick={props.onClickBackLink}>Back</div> */}
            {/* <div className={styles.login_by_mail_id_text}>Change Password</div>
            <div className={styles.pswd_text}>A strong password is at least 8 characters in lenght and contains letters, number, and symbol</div> */}
            <div class={styles.personal_login_text}>
                {/* <div class={styles.personalBackground}></div> */}
                <div className={styles.text_password}>
                        <h1>Change Password</h1> 
                        {/* <p>Lorem ipsum is a placeholder text commonly</p> */}
                    </div>
                {/* <div class={styles.personal_login_tab_active}>
                    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="150px" height="150px" viewBox="0 0 200 200">
                        <g>
                            <g>
                                <path fill-rule="evenodd" clip-rule="evenodd" fill="#3A7FAB" d="M114.084,87.491c4.692,0,8.495,3.803,8.495,8.494    s-3.803,8.495-8.495,8.495c-4.691,0-8.494-3.804-8.494-8.495S109.393,87.491,114.084,87.491z"/>
                            </g>
                            <g>
                                <path fill-rule="evenodd" clip-rule="evenodd" fill="#3A7FAB" d="M83.747,87.491c4.692,0,8.495,3.803,8.495,8.494    s-3.803,8.495-8.495,8.495c-4.691,0-8.494-3.804-8.494-8.495S79.056,87.491,83.747,87.491z"/>
                            </g>
                            <g>
                                <path fill-rule="evenodd" clip-rule="evenodd" fill="#3A7FAB" d="M54.624,87.491c4.691,0,8.494,3.803,8.494,8.494    s-3.803,8.495-8.494,8.495c-4.692,0-8.495-3.804-8.495-8.495S49.932,87.491,54.624,87.491z"/>
                            </g>
                            <g>
                                <path fill="#3A7FAB" d="M132.287,119.648H36.421c-5.687,0-10.314-4.628-10.314-10.314V81.423c0-5.688,4.627-10.314,10.314-10.314    h95.866c5.687,0,10.314,4.627,10.314,10.314v27.911C142.602,115.021,137.974,119.648,132.287,119.648z M36.421,79.604    c-1.003,0-1.82,0.816-1.82,1.82v27.911c0,1.004,0.816,1.82,1.82,1.82h95.866c1.003,0,1.82-0.816,1.82-1.82V81.423    c0-1.003-0.817-1.82-1.82-1.82H36.421z"/>
                            </g>
                        </g>
                        <g>
                            <path fill="#3A7FAB" d="M151.016,98.01c-12.634,0-22.877,10.242-22.877,22.876s10.243,22.877,22.877,22.877   c12.635,0,22.877-10.242,22.877-22.877S163.65,98.01,151.016,98.01z M161.798,123.938h-7.732v7.732c0,1.685-1.365,3.05-3.05,3.05   c-1.686,0-3.051-1.365-3.051-3.05v-7.732h-7.732c-1.684,0-3.05-1.365-3.05-3.051s1.366-3.05,3.05-3.05h7.732v-7.733   c0-1.684,1.365-3.05,3.051-3.05c1.685,0,3.05,1.366,3.05,3.05v7.733h7.732c1.686,0,3.051,1.364,3.051,3.05   S163.483,123.938,161.798,123.938z"/>
                        </g>
                    </svg>
                </div> */}
          
            <div className={styles.password_section}>
            {/* {props.changePasswordAPIErr !== "" ? <div className={styles.email_error_msg}>{props.changePasswordAPIErr}</div> : ""} */}
            <div className={styles.newPassword}>
            <label>Enter New Password</label>
                {/* <span><img src="/images/password-t3.svg" /></span> */}
                
                <div className={styles.flex_password}>
                <input className={styles.email_id} id="password" type={props.hidePass?"text":"password"} placeholder="Enter New Password" maxLength="50" onChange={props.onChangeNewPassword} />
                <div className={styles.checkbox_label}><input onClick={(pass)=>{ props.onClickHidePass(pass) }} type="checkbox" id="Show password" name="Show password"  className={styles.checkbox}/><label for="Show password">Show password</label></div>
                <div className={styles.special_character}>
                    <p>Password must be between 8-13 characters in length and must comprise of at least 1 lowercase alphabet (a-z), 1 uppercase alphabet (A-Z), 1 number (0-9) and 1 special character</p>
                </div>
                </div>
                {props.newPasswordErr !== "" ? <div className={styles.email_error_msg}>{props.newPasswordErr}</div> : ""}
               
            </div>
            <div className={styles.newPassword}>
            <label>Confirm New Password</label>
                {/* <span><img src="/images/password-t3.svg" /></span> */}
                <div className={styles.flex_password}>
                <input className={styles.email_id} id="password" type={props.hidePassConf?"text":"password"} placeholder="Confirm New Password" maxLength="50" onChange={props.onChangeConfirmPassword} />
                <div className={styles.checkbox_label}><input onClick={(pass)=>{ props.onClickHidePassConf(pass) }} type="checkbox" id="Show password1" name="Show password"  className={styles.checkbox}/><label for="Show password">Show password</label></div>
                <div className={styles.special_character}>
                    <p>Password must be between 8-13 characters in length and must comprise of at least 1 lowercase alphabet (a-z), 1 uppercase alphabet (A-Z), 1 number (0-9) and 1 special character</p>
                </div>
                </div>
                {props.confirmPasswordErr !== "" ? <div className={styles.email_error_msg}>{props.confirmPasswordErr}</div> : ""}
               
            </div>
            <button className={styles.btn_login} onClick={props.onClickConfirmbtn}>Confirm</button>
            </div>
            </div>
        </>
    )
}
export default ChangePassword;