import Head from "next/head";
import configUrl from "../config/config.json";

const PageSeo = ({ children }) => {
  //console.log("===PageSeo children===", children?.props);
  return (
    <Head>
      <title>{children?.props?.home?.data?.data?.seo?.title}</title>
      {children?.props?.home?.data?.data?.seo?.metadata.map((element, idx) => {
        if (element.attribute == "property") {
          return (
            <meta
              key={"metadata" + idx}
              property={element.tag}
              content={element.content}
            ></meta>
          );
        } else if (element.attribute == "http-equiv") {
          return (
            <meta
              key={"metadata" + idx}
              http-equiv={element.tag}
              content={element.content}
            ></meta>
          );
        } else {
          return (
            <meta
              key={"metadata" + idx}
              name={element.tag}
              content={element.content}
            ></meta>
          );
        }
      })}

      <link
        rel="canonical"
        href={children?.props?.home?.data?.data?.seo?.url}
      />
      {/* Pwa Tags */}
      {children?.props?.home?.data?.site?.allow_pwa_module == true && (
        <link rel="manifest" href={"/shared/api/pwa/manifest"} />
      )}
      {/* <link rel="manifest" href="/manifest.json" /> */}

      {/* Pwa Tags for Android  */}
      {/* <meta name="theme-color" content="#fff" /> */}
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="full-screen" content="yes" />

      {/* Pwa Tags for iOS */}
      <meta name="apple-touch-fullscreen" content="yes"></meta>
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta
        name="apple-mobile-web-app-title"
        content={children?.props?.home?.data?.data?.seo?.title}
      />
      <meta name="format-detection" content="telephone=no" />

      {/* Pwa Splash Screens for iOS */}
      {/* <!-- iPhone 15 Pro Max --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-15-Pro-Max-1290x2796.png"
        media="(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3)"
      /> */}

      {/* <!-- iPhone 15 Pro --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-15-Pro-1179x2556.png"
        media="(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3)"
      /> */}

      {/* <!-- iPhone 14 Pro Max and iPhone 13 Pro Max --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-14-Plus-iPhone-13-Pro-Max-iPhone-12-Pro-Max-1284x2778.png"
        media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3)"
      /> */}

      {/* <!-- iPhone 14 Pro and iPhone 13 Pro --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-14-iPhone-13-iPhone-12-1170x2532.png"
        media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)"
      /> */}

      {/* <!-- iPhone 14, iPhone 13, iPhone 12 --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-14-iPhone-13-iPhone-12-1170x2532.png"
        media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)"
      /> */}

      {/* <!-- iPhone 14 Plus, iPhone 13 Pro Max, iPhone 12 Pro Max --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-14-Plus-iPhone-13-Pro-Max-iPhone-12-Pro-Max-1284x2778.png"
        media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3)"
      /> */}

      {/* <!-- iPhone 11 Pro Max, iPhone XS Max --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-11-Pro-Max-iPhone-XS-Max-1242x2688.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
      /> */}

      {/* <!-- iPhone 11 Pro, iPhone XS, iPhone X --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-11-Pro-iPhone-XS-iPhone-X-1125x2436.png"
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
      /> */}

      {/* <!-- iPhone 11, iPhone XR --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-11-iPhone-XR-828x1792.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
      /> */}

      {/* <!-- iPhone 8 Plus, iPhone 7 Plus, iPhone 6s Plus --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-8-Plus-iPhone-7-Plus-iPhone-6s-Plus-1242x2208.png"
        media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)"
      /> */}

      {/* <!-- iPhone 8, iPhone 7, iPhone 6s --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-8-iPhone-7-iPhone-6s-750x1334.png"
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
      /> */}

      {/* <!-- iPhone SE --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPhone-SE-640x1136.png"
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
      /> */}

      {/* <!-- iPad Pro 12.9" --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPad-Pro-2048x2732.png"
        media="(min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-device-pixel-ratio: 2)"
      /> */}

      {/* <!-- iPad Pro 11" --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPad-Pro-11-1668x2388.png"
        media="(min-device-width: 834px) and (max-device-width: 1194px) and (-webkit-device-pixel-ratio: 2)"
      /> */}

      {/* <!-- iPad Air 10.5" --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPad-Air-10-5-1668x2224.png"
        media="(min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-device-pixel-ratio: 2)"
      /> */}

      {/* <!-- iPad 9.7" --> */}
      {/* <link
        rel="apple-touch-startup-image"
        href="/splashscreens/iPad-9-7--1536x2048.png"
        media="(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2)"
      /> */}

      {/* Pwa Icons for iOS */}
      {/* <link
        rel="apple-touch-icon"
        href={`${
          children?.props?.home?.data?.data?.seo?.base_url +
          "/shared/api/media/" +
          children?.props?.home?.data?.data?.header?.logo
        }`}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`${
          children?.props?.home?.data?.data?.seo?.base_url +
          "/shared/api/media/" +
          children?.props?.home?.data?.data?.header?.logo
        }`}
      />
      <link
        rel="apple-touch-icon"
        sizes="167x167"
        href={`${
          children?.props?.home?.data?.data?.seo?.base_url +
          "/shared/api/media/" +
          children?.props?.home?.data?.data?.header?.logo
        }`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${
          children?.props?.home?.data?.data?.seo?.base_url +
          "/shared/api/media/" +
          children?.props?.home?.data?.data?.header?.logo
        }`}
      /> */}

      {/*  Main Link Tags */}
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={
          "/shared/api/media/" +
          children.props?.home?.data?.data?.site_id +
          "/" +
          children?.props?.home?.data?.site?.favicon
        }
      />

      {/* dynamic linkdata icons */}
      {children?.props?.home?.data?.data?.seo?.linkdata &&
        children?.props?.home?.data?.data?.seo?.linkdata.map((element, idx) => {
          return (
            <link
              key={"link" + idx}
              rel={element.rel}
              type={element.type}
              size={element.size.width + "x" + element.size.height}
              href={element.href}
            ></link>
          );
        })}
    </Head>
  );
};

export default PageSeo;
