import React from "react";
import Link from 'next/link';

const MoreMenu = ({ menuItems, styles, setMoreWrapperRef, setMorePopupHandler, state, site_id, showIcon }) => {
  return (
    <div className={styles.suport_cont} ref={setMoreWrapperRef} onMouseEnter={setMorePopupHandler} onMouseLeave={setMorePopupHandler}>
      <div className={styles.znth_support} onClick={setMorePopupHandler}>
       {showIcon && <div className={styles.service_icon}><svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" width="60px" height="60px" viewBox="0 0 60 60">
<g>
	<path d="M29.858,1.16C13.96,1.16,1.024,14.094,1.024,29.994c0,15.899,12.936,28.833,28.834,28.833   c15.9,0,28.832-12.934,28.832-28.833C58.69,14.094,45.759,1.16,29.858,1.16z M29.858,56.424c-14.572,0-26.43-11.857-26.43-26.43   c0-14.572,11.857-26.43,26.43-26.43s26.43,11.857,26.43,26.43C56.288,44.566,44.431,56.424,29.858,56.424z"/>
	<g>
		<path d="M14.597,34.037c-1.634,0-3.108-0.984-3.734-2.496c-0.625-1.512-0.278-3.25,0.876-4.407    c1.156-1.156,2.896-1.502,4.407-0.877c1.513,0.627,2.496,2.1,2.496,3.736C18.642,32.225,16.83,34.037,14.597,34.037z"/>
		<path d="M29.857,34.037c-1.636,0-3.109-0.984-3.737-2.496c-0.625-1.512-0.278-3.25,0.88-4.407    c1.154-1.156,2.893-1.502,4.405-0.877c1.513,0.627,2.494,2.1,2.494,3.736C33.899,32.225,32.088,34.037,29.857,34.037z"/>
		<path d="M45.118,34.037c-1.638,0-3.11-0.984-3.737-2.496c-0.625-1.512-0.28-3.25,0.877-4.407s2.896-1.502,4.405-0.877    c1.513,0.627,2.497,2.1,2.497,3.736C49.16,32.225,47.348,34.037,45.118,34.037z"/>
	</g>
</g>
</svg></div>}
        <div className={styles.more_items}>More</div>
        {!showIcon &&
          <span class={styles.dropdownPosCat_more}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="11" class=""><path fill-rule="evenodd" fill="#266AAC" d="m17.373 2.754-7.394 7.457c-.139.14-.3.243-.472.311a1.386 1.386 0 0 1-1.513-.309L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.441 0 1.991z"></path></svg></span>
        }
        {/* <span className={styles.dropZ_arrow}>▾</span> */}
      </div>
      {state && (
        <div className={styles.supportdropNew} style={{ display: "block" }}>
          {menuItems.map((item, index) => (
            item.status.toLowerCase() === "active" && (
              <div key={index} className={styles.header_menuZth}>
                {item.url.includes("https") || item.url.includes("http") ? (
                  <Link
                    href={item.url}
                    passHref
                  >
                    <a>
                      {item.text}
                      {/* <img
                        src={`/shared/api/media/${site_id}/${item.icon}`}
                        alt={item.text}
                      /> */}
                    </a>
                  </Link>
                ) : (
                  <Link
                    href={item.url}
                    passHref
                  >
                    <a>
                      {item.text}
                      {/* <img
                        src={`/shared/api/media/${site_id}/${item.icon}`}
                        alt={item.text}
                      /> */}
                    </a>
                  </Link>
                )}
              </div>
            )
          ))}
        </div>
      )}
    </div>
  );
};

export default MoreMenu;