import GoogleLoginComponent from './GoogleLoginComponent.js';

const FacebookGoogleLogin = (props) => {
    return (
        <div className={props?.styles?.Social_login}>
            {props.siteData.site.google_login_key !== "" && <GoogleLoginComponent isSignUp={props.isSignUp} getSignUpForm={props.getSignUpForm} text={'Login'}  siteData={props.siteData.site} theme={props.theme} styles={props.styles} isSignIn={props.isSignIn} />}
            
        </div>
    )
}
export default FacebookGoogleLogin;