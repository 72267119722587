import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';


export default class DesktopCalender extends Component {
    constructor(props) {
        super(props);
        this.statusFilterLayerRef = createRef()
        this.state = {
            searchText: this.props.searchText,
            shouldShowFlag: false,
            selectedCountry: null,
            openList: false,
            showCountryCode: false,
            countryFlag: []
        }
    }

    componentDidMount = () => {
        this.setState({ shouldShowFlag: false, openList: false, showCountryCode: true });
        document.addEventListener('click', this.handleClickOutsideOfonStatusFilterClick, true);
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClickOutsideOfonStatusFilterClick, true);
    }
    onStatusFilterClick = () => {
        if (!this.state.openList) {
            this.setState({ openList: true });
            document.addEventListener('click', this.handleClickOutsideOfonStatusFilterClick, true);
        }
        else {
            document.removeEventListener('click', this.handleClickOutsideOfonStatusFilterClick, true);
            this.setState({ openList: false, shouldShowFlag: true });
        }
    }

    handleClickOutsideOfonStatusFilterClick = (event) => {

        // const statusFilterLayerNode = ReactDOM.findDOMNode(this.statusFilterLayerRef);
        // const statusFilterNode = ReactDOM.findDOMNode(this.statusFilterRef);

        /* if (statusFilterNode !== null && statusFilterNode.contains(event.target)) {
            return;
        } else  */
        if (this.statusFilterLayerRef.current && !this.statusFilterLayerRef.current.contains(event.target)) {
            this.setState({ openList: false, shouldShowFlag: true });
            document.removeEventListener('click', this.handleClickOutsideOfonStatusFilterClick, true);
        }
    }

    selectCountryCode = (ele) => {
        this.setState({ showCountryCode: true });
        this.props.selectCountryCode(ele);
    }

    render() {
        // console.log('this.props.searchText', this.props)
        // console.log("flag",this.state.shouldShowFlag);
        // console.log("country",this.state.showCountryCode)
        // console.log(this.props.selectedCountryInfo,);
        return (
            <div
                className={`${this.props.isSignUpCountryCode ? this.props.signup_country_class : this.props.country_drop} ${this.props.parentClass}`}
                onClick={this.onStatusFilterClick}
                /* ref={statusFilterLayerRef => this.statusFilterLayerRef = statusFilterLayerRef} */>
                <span
                    onClick={() => {
                        if (document.querySelectorAll(`[name=${this.props.dropdownName !== undefined ? this.props.dropdownName : "code-input"}]`) && document.querySelectorAll(`[name=${this.props.dropdownName !== undefined ? this.props.dropdownName : "code-input"}]`)[0]) {
                            document.querySelectorAll(`[name=${this.props.dropdownName !== undefined ? this.props.dropdownName : "code-input"}]`)[0].focus();
                        }
                        let countryFlag1 = this.props.CountryListOptions.filter(ele => {
                            let val22 = this.props.searchText !== undefined ? this.props.searchText.includes('+') ? this.props.searchText.split('+')[1] : this.props.searchText : this.props.searchText;
                            return (ele.isdCode.includes(val22) || ele.countryName.toLowerCase().includes(val22));
                        });
                        countryFlag1.sort((a, b) => {
                            let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                            let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                            return str1 - str2;
                        });
                        this.setState({ countryFlag: countryFlag1, showCountryCode: true });
                        if (countryFlag1.length > 0) {
                            this.setState({ showNoResultFound: false });
                        } else {
                            this.setState({ showNoResultFound: true });
                        }
                    }}
                    className={this.state.openList ? this.props.vt_country_select_main_container_new + this.props.open_state_dropdown : this.props.vt_country_select_main_container_new}>
                    <span
                        role="presentation"
                        className={this.props.vt_country_select_container}>   
                        {  this.state.shouldShowFlag !== false && this.props.selectedCountryInfo?.countryFlag !== '' && (
                            <div className={this.props.cnt_img}>
                                 <img
                                style={{ height: "20px", width: "25px" }}
                                className={this.props.vt_flag_img}
                                src={this.props.selectedCountryInfo.countryFlag}
                                alt=" "
                                // onError={(e) => { e.target.onerror = null; e.target.src = ''; }}
                            />
                            </div>)}
                        {this.state.showCountryCode &&
                            <span className={this.props.selected_ctry_name}>{this.props.selectedCountryInfo !== undefined && this.props.selectedCountryInfo !== null ? this.props.selectedCountryInfo.countryCode : ''}</span>
                        }
                        <input
                            // {...this.props}
                            name={this.props.dropdownName !== undefined ? this.props.dropdownName : 'code-input'}
                            className={
                                this.props.searchText !== '' ?
                                    this.props.vt_inspira_country_main
                                    : this.props.vt_inspira_country_main + this.props.vt_country_selected + this.props.countryname_null
                            }
                            onClick={() => {
                                this.props.onClickInputCountry();
                                this.setState({ openList: true, shouldShowFlag: true, showCountryCode: this.props.searchText !== '' ? true : false }, () => {
                                    let countryFlag1 = this.props.CountryListOptions.filter(ele => {
                                        let val22 = this.props.searchText !== undefined ? this.props.searchText.includes('+') ? this.props.searchText.split('+')[1] : this.props.searchText : this.props.searchText;
                                        return (ele.isdCode.includes(val22) || ele.countryName.toLowerCase().includes(val22));
                                    });
                                    countryFlag1.sort((a, b) => {
                                        let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                                        let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                                        return str1 - str2;
                                    });
                                    this.setState({ countryFlag: countryFlag1, showCountryCode: true });
                                    if (countryFlag1.length > 0) {
                                        this.setState({ showNoResultFound: false });
                                    } else {
                                        this.setState({ showNoResultFound: true });
                                    }
                                });
                            }}
                            title={((this.props.selectedCountryInfo !== null && this.props.selectedCountryInfo !== undefined) && this.props.selectedCountryInfo.countryName)} // + searchText}
                            onChange={(e) => {
                                let str = e.target.value;
                                let countryFlag1;
                                var pat = /^[0-9]+$/;
                                let val22 = (str.match(pat)) ? (parseInt(str)) : str;   //(e.target.value !== '' && e.target.value.match(pat)) ? str.split('+')[1] : str;
                                if (val22 === '+') {
                                    countryFlag1 = this.props.CountryListOptions;
                                    countryFlag1.sort((a, b) => {
                                        let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                                        let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                                        return str1 - str2;
                                    });
                                    this.setState({ countryFlag: countryFlag1 }, () => {
                                        this.setState({ openList: true, shouldShowFlag: true, showCountryCode: false });
                                    })
                                } else
                                    if (typeof val22 === "number") {
                                        countryFlag1 = this.props.CountryListOptions.filter(ele => {
                                            return ele.isdCode.includes(val22) //|| (ele.countryName.toLowerCase()).includes((val22.toLocaleLowerCase()))) || (ele.countryName.toUpperCase()).includes((val22.toLocaleLowerCase()));
                                        });
                                        countryFlag1.sort((a, b) => {
                                            let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                                            let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                                            return str1 - str2;
                                        });
                                        this.setState({ countryFlag: countryFlag1 }, () => {
                                            this.setState({ openList: true, shouldShowFlag: true, showCountryCode: false });
                                        });
                                    } else if (typeof val22 === 'string') {
                                        let val25 = (val22.includes('+') && (!val22.includes('-'))) ? parseInt(val22.split('+')[1]) : val22; //.includes('+') ? value.split('+')[1] : value;
                                        if (typeof val25 === 'number') {
                                            countryFlag1 = this.props.CountryListOptions.filter(ele => {
                                                return (ele.isdCode.includes(val25)) //|| (ele.countryName.toLowerCase()).includes((val22.toLocaleLowerCase()))) || (ele.countryName.toUpperCase()).includes((val22.toLocaleLowerCase()));
                                            });
                                            countryFlag1.sort((a, b) => {
                                                let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                                                let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                                                return str1 - str2;
                                            });
                                            this.setState({ countryFlag: countryFlag1 }, () => {
                                                this.setState({ openList: true, shouldShowFlag: true, showCountryCode: false });
                                            });
                                        } else if (typeof val25 === 'string') {
                                            countryFlag1 = this.props.CountryListOptions.filter(ele => {
                                                return (ele.isdCode.includes(val25) || (ele.countryName.toLowerCase()).includes((val22.toLocaleLowerCase()))) || (ele.countryName.toUpperCase()).includes((val22.toLocaleLowerCase()));
                                            });
                                            countryFlag1.sort((a, b) => {
                                                let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                                                let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                                                return str1 - str2;
                                            });
                                            this.setState({ countryFlag: countryFlag1 }, () => {
                                                this.setState({ openList: true, shouldShowFlag: true, showCountryCode: false });
                                            });
                                        }
                                        else {
                                            countryFlag1 = this.props.CountryListOptions.filter(ele => {
                                                return (ele.countryName.toLowerCase()).includes((val22.toLocaleLowerCase())) || (ele.countryName.toUpperCase()).includes((val22.toLocaleLowerCase()));
                                            });
                                            countryFlag1.sort((a, b) => {
                                                let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                                                let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                                                return str1 - str2;
                                            });
                                            this.setState({ countryFlag: countryFlag1 }, () => {
                                                this.setState({ openList: true, shouldShowFlag: true, showCountryCode: false });
                                            });
                                        }
                                    }
                                this.props.onChangeInputCountryCodeFunction(val22);
                                if (countryFlag1.length > 0) {
                                    this.setState({ showNoResultFound: false });
                                } else {
                                    this.setState({ showNoResultFound: true });
                                }
                            }}
                            onKeyUp={(e) => {
                                const key = e.key;
                                if (key === "Backspace" || key === "Delete") {
                                    let countryFlag1 = this.props.CountryListOptions;
                                    countryFlag1.sort((a, b) => {
                                        let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                                        let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                                        return str1 - str2;
                                    });
                                    this.setState({ countryFlag: countryFlag1, showCountryCode: false });
                                    if (countryFlag1.length > 0) {
                                        this.setState({ showNoResultFound: false });
                                    } else {
                                        this.setState({ showNoResultFound: true });
                                    }
                                    this.setState({ shouldShowFlag: true, showCountryCode: false }, () => {
                                        this.props.onKeyDownCountry(e);
                                    });
                                }
                                if (key === 'Tab') {
                                    this.setState({ openList: false, shouldShowFlag: true });
                                }
                            }}
                            value={this.props.searchText}
                            placeholder={this.props.placeholder}
                            autoComplete="new-password"
                        />
                    </span>
                    {
                        this.state.openList && (this.state.shouldShowFlag) && (
                            <div className={this.state.countryFlag.length <= 4 ? this.props.vt_country_select_list_container : this.props.vt_country_select_list_container}
                                ref={/* statusFilterLayerRef =>  */this.statusFilterLayerRef /* = statusFilterLayerRef */}>
                                {this.state.showNoResultFound && (<div style={{ padding: "8px" }} className='no_result_found'>{'No Result Found !'}</div>)}
                                <div className={this.props.country_code_list}>
                                    {this.state.countryFlag.map((ele, index) => {
                                        return (
                                            <div className={this.props.country_name_code} key={index} onClick={() => { this.setState({ shouldShowFlag: true }); this.selectCountryCode(ele) }}>
                                                <img
                                                    style={{ height: "20px", width: "25px" }}
                                                    src={ele.countryFlag}
                                                    alt=" "
                                                    // onError={e => {
                                                    //     e.target.onerror = null;
                                                    //     e.target.src = '';
                                                    // }}
                                                />
                                                <span className={this.props.ctry_name}>{ele.countryName}</span>
                                                <span className={this.props.ctry_code}>{ele.isdCode}</span>
                                            </div>

                                        );
                                    })}
                                </div>
                            </div>
                        )
                    }
                </span>

            </div >
        )
    }
}