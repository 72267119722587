import React from "react";
import { login } from "../../../lib/login";
import { signup } from "../../../lib/signup";
import FacebookGoogleLogin from "../Theme2/FacebookLogin";
import { changepassword } from '../../../lib/changepassword';
import ChangePassword from "../Theme2/ChangePassword";
import ModalPopupComponent from '../../ModalPopupComponent/ModalPopupComponent';
import IsdCountryDropdown from '../../IsdCountryDropdown/index';
import CountryDropdownComp from '../../CountryDropdown/index';
import { register } from "../../../lib/register";
import CaptchaComponent from "../CaptchaComponent";
import getCountriesList from "../../../lib/getCountriesList";

const errorsList = {
    firstName: {
        ERFN101: 'Please Enter First Name'
    },
    lastName: {
        ERLN101: 'Please Enter Last Name'
    },
    countryCode: {
        ERCC101: 'Please Select The Country Code'
    },
    nationality: {
        ERNT101: 'Please Select Nationality'
    },
    mobileNo: {
        ERMN101: 'Invalid Mobile Number',
        ERMN102: 'Please Enter Mobile Number'
    },
    email: {
        ERE101: 'Please Enter Email Id',
        ERE102: 'Please Enter Valid Email Id'
    },
    password: {
        ERP101: 'Password must be between 8-13 characters in length and must comprise of at least 1 lowercase alphabet (a-z), 1 uppercase alphabet (A-Z), 1 number (0-9) and 1 special character',
        ERP104: 'Please Enter Password',
        ERP105: 'Invalid Password'
    },
    confirmPassword: {
        ERP102: 'Please Re-Enter New Password',
        ERP103: 'Passwords Do Not Match'
    },
    OTP: {
        EROTP101: "Please Enter OTP",
        EROTP102: "Please Enter Valid OTP"
    },
    isTermsChecked: {
        ERTC101: 'Please Accept The Terms & Conditions To Proceed'
    }
};

const emailPattern = /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
let pwsEx = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,13}$/);
var alphabetonly = new RegExp(/^[a-zA-Z_\s]*$/);
let otpregex = new RegExp(/[a-z0-9]*\\d[a-z0-9]*/);
let alphanumericAndSpecialChars = new RegExp(/^[ A-Za-z0-9_@./#&+-]*$/)

export default class Theme2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleAgentLogin: false,
            headerPopup: true,
            popupType: "LogIn",
            logincred: { email: "", password: "" },
            loginErrors: { email: "", password: "" },
            loginErrorMsg: "",
            signUpEmail: "",
            signUpEmailErr: "",
            registerAPIErr: "",
            onClickLoginLoader: false,
            activationLink: false,
            forgotPasswordEmail: "",
            forgotPassText: false,
            forgotPasswordErr: "",
            loginWithOTPEmail: "",
            loginOTPEmailErr: "",
            verifyOTPEmail: "",
            verifyOTPEmailErr: "",
            signUpWithCaptchaMail: "",
            signUpWithCaptchaMailErr: "",
            passwordObj: { newPassword: "", confirmPassword: "" },
            passwordObjErr: { newPassword: "", confirmPassword: "" },
            changePasswordAPIErr: "",
            searchCountryText: "",
            selectedCountry: "",
            selectedNationality: "",
            signupForm: { firstName: "", lastName: "", countryCode: "", mobileNo: "", nationality: "", email: this.props.data.emailId !== "" ? this.props.data.emailId : "", password: "", confirmPassword: "" },
            signupFormErr: { firstName: "", lastName: "", countryCode: "", mobileNo: "", nationality: "", email: "", password: "", confirmPassword: "" },
            setToken: '',
            bounz_register: false,
            isCaptchaSelected: false,
            captchErrorMsg: "",
            originCountries: [],
            isSuccessPopupVisible: false,
            isReadOnly: true,
            isPasswordRequired: true,
            registrationToken: "",
            isLoginBlock: false,
        }
    }

    componentDidMount = () => {
        // console.log('get country list directopenlinkpopup', this.props.data.directOpenLinkPopup)
        getCountriesList().then(resp => {
            let countryList = []
            if (resp?.data?.length > 0) {
                resp?.data.map(item => {
                    countryList.push({
                        ...item,
                        value: item.contry_name,
                        isdCode: item.contry_isd,
                        countryCode: item.contry_code,
                        flag: item.contry_flag,
                        countryFlag: item.contry_flag,
                        countryName: item.contry_name
                    })
                })
            }
            this.setState({ originCountries: countryList });
        })
        if (this.props.data.directOpenLinkPopup === "SignUp" || this.props.data.directOpenLinkPopup === "LogIn" || this.state.popupType === "SignUp" || this.props.data.directOpenLinkPopup === "BeforeSignUp") {
            
        }

    }





    // Login Functinality Starts 
    onChangeLoginEmail = (e) => {
        this.setState({ logincred: { ...this.state.logincred, email: e.target.value }, loginErrors: { ...this.state.loginErrors, email: "" } });
    }
    onChangeLoginPassword = (e) => {
        let spaceEX = new RegExp(/(^\S*$)/)
        let confpwsEx = new RegExp(/^(?=.{0,13}$).*/)
        if (e.target.value.match(confpwsEx) && e.target.value.match(spaceEX)) {
            this.setState({ logincred: { ...this.state.logincred, password: e.target.value }, loginErrors: { ...this.state.loginErrors, password: "" } });
        } else {
            this.setState({ loginErrors: { ...this.state.loginErrors, password: "Please Enter Valid Password" } });
        }
    }
    //login in button 
    onClickLoginBtn = async () => {
        const errorsList = {

            email: {
                ERE101: 'Please Enter Email Id',
                ERE102: 'Please Enter Valid Email Id'
            },
            password: {
                ERP101: 'Password must be between 8-13 characters in length and must comprise of at least 1 lowercase alphabet (a-z), 1 uppercase alphabet (A-Z), 1 number (0-9) and 1 special character',
                ERP104: 'Please Enter Password',
                ERP105: 'Invalid Password'
            }
        };
        let pwsEx = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,13}$/);
        this.setState({
            loginErrors: {
                ...this.state.loginErrors,
                email: this.state.logincred.email === '' ? errorsList.email.ERE101 : /* (this.state.logincred.email.match(emailPattern) !== null) ? */ '' /* : errorsList.email.ERE102 */,
                password: this.state.logincred.password === '' ? errorsList.password.ERP104 : (this.state.logincred.password.match(pwsEx) !== null) ? '' : errorsList.password.ERP105,
            },
            loginErrorMsg: ""
        })
        if (this.state.logincred.email !== '' && this.state.logincred.password !== "" && (this.state.logincred.password.match(pwsEx) !== null) /* && (this.state.logincred.email.match(emailPattern) !== null) */) {
            let loginReq = {
                "email": this.state.logincred.email,
                "password": this.state.logincred.password,
                "type": this.state.toggleAgentLogin ? 'agent' : undefined
            }
            this.setState({ onClickLoginLoader: true });
            login(loginReq).then((responseJSON) => {
                // console.log("responseJSON", responseJSON);
                this.setState({ onClickLoginLoader: false });
                if (responseJSON.status === true && responseJSON.accessToken !== "" && responseJSON.accessToken !== undefined && responseJSON.accessToken !== null) {
                    this.props.data.onClickCross();
                    location.reload()
                } else if (!responseJSON.status && responseJSON.isPartialRegister) {
                    if (typeof this?.props?.data?.clearDirectOpenLinkPopup === "function") {
                        this.props.data.clearDirectOpenLinkPopup()
                    }
                    this.setState({
                        popupType: "SignUp", isPasswordRequired: true, registrationToken: responseJSON.registration_token,
                        signupForm: { ...this.state.signupForm, email: this.state.logincred.email }
                    })
                } else {
                    this.setState({ loginErrorMsg: typeof responseJSON.message === "string" ? responseJSON.message : "" });
                }
            })
        }
    }
    //Login Func Ended

    //signup Email 
    onChangeSignupEmailId = (e) => {
        this.setState({ signUpEmail: e.target.value, signUpEmailErr: "", activationLink: false });
    }

    onChangeBounzCheck = (value) => {
        // console.log("value", value)
        this.setState({ bounz_register: value });
    }
    onClickSignupBtn = () => {
        // console.log(this.props?.data?.siteData?.site?.login_captcha_key,"captcha key");
        this.setState({ signUpEmailErr: this.state.signUpEmail === '' ? errorsList.email.ERE101 : (this.state.signUpEmail.match(emailPattern) !== null) ? '' : errorsList.email.ERE102 });
            if (this.state.signUpEmail !== '' && (this.state.signUpEmail.match(emailPattern) !== null && (!this.props?.data?.siteData?.site?.login_captcha_key || this.state.isCaptchaSelected)
            )) {
               //api call
               let signupReq = {
                   "email": this.state.signUpEmail,
                   "bounz_register": this.state.bounz_register
               }
               signup(signupReq).then(res => {
                   if (res && res.status === true && res.message !== "") {
                       this.setState({ activationLink: true, signUpEmailErr: "" });
                       if (typeof this?.props?.data?.clearDirectOpenLinkPopup === "function") {
                           this.props.data.clearDirectOpenLinkPopup()
                       }
                       this.setState({
                           popupType: "SignUp", isPasswordRequired: true, registrationToken: res.registration_token,
                           signupForm: { ...this.state.signupForm, email: this.state.signUpEmail }
                       })
                   } else {
                    //    this.setState({ activationLink: false, signUpEmailErr: typeof res.message === "string" ? res.message : "" });
                    this.setState({ activationLink: false, 
                        signUpEmailErr: typeof res.message === "string" ? res.message : (res.message && res.message.length > 0 )? res.message[0].msg : "some thing went wrong"});
                   }
               })
           } else {
               this.setState({
                   activationLink: false,
                   captchErrorMsg: !this.state.isCaptchaSelected ? "Please Select Captcha" : ""
               });
           } 
      
    } //ended here


    //Forgot pass
    onChangeForgotPassEmail = (e) => {
        this.setState({ forgotPasswordEmail: e.target.value, forgotPasswordErr: "" });
    }

    onClickForgotSubimitBtn = () => {
        this.setState({ forgotPasswordErr: this.state.forgotPasswordEmail === '' ? errorsList.email.ERE101 : (this.state.forgotPasswordEmail.match(emailPattern) !== null) ? '' : errorsList.email.ERE102 });
        if (this.state.forgotPasswordEmail !== '' && (this.state.forgotPasswordEmail.match(emailPattern) !== null)) {
            //api call
            let forgotPassReq = {
                "email": this.state.forgotPasswordEmail
            }
            forgotpassword(forgotPassReq).then((res) => {
                // console.log('forgot pass in common', res);
                if (res && res.status === true && res.message && res.message !== "") {
                    this.setState({ forgotPassText: true, forgotPasswordErr: "" });
                } else {
                    this.setState({ forgotPassText: false, forgotPasswordErr: res.message });
                }
            })
        } else {
            this.setState({ forgotPassText: false })
        }
    }

    //send otp popup
    onClickSendOTP = () => {
        this.setState({ loginOTPEmailErr: this.state.loginWithOTPEmail === '' ? errorsList.email.ERE101 : (this.state.loginWithOTPEmail.match(emailPattern) !== null) ? '' : errorsList.email.ERE102 });
        if (this.state.loginWithOTPEmail !== '' && (this.state.loginWithOTPEmail.match(emailPattern) !== null)) {
            //api call
            // setPopupType("VerifyOTP")
            this.setState({ popupType: "VerifyOTP" });
        }
    }

    onChangeLoginOTPEmail = (e) => {
        this.setState({ loginWithOTPEmail: e.target.value, loginOTPEmailErr: "" });
    }

    onChangeEmailOTP = (e) => {
        setLoginWithOTPEmail({ loginWithOTPEmail: e.target.value, loginOTPEmailErr: "" });
    }

    onClickResendOTP = () => {
        //api call
    }

    //verify otp
    onChangeVerifyOTP = (e) => {
        this.setState({ verifyOTPEmail: e.target.value, verifyOTPEmailErr: "" });
    }

    onOTPLogin = () => {
        this.setState({ verifyOTPEmailErr: this.state.verifyOTPEmail === '' ? errorsList.OTP.EROTP101 : (this.state.verifyOTPEmail.match(otpregex) !== null) ? '' : errorsList.OTP.EROTP102 });
        if (this.state.verifyOTPEmail !== '' && (this.state.verifyOTPEmail.match(otpregex) !== null)) {
            //api call
        }
    }

    getSignUpForm = (data, type) => {
        // console.log(data);
        if (typeof this?.props?.data?.clearDirectOpenLinkPopup === "function") {
            this.props.data.clearDirectOpenLinkPopup()
        }
        this.setState({
            popupType: "SignUp", isPasswordRequired: false, registrationToken: data.registration_token,
            signupForm: { ...this.state.signupForm, email: data.email, firstName: data?.first_name || "" }
        })
    }

    onClickConfirmbtn = () => {
        this.setState({
            passwordObjErr: {
                ...this.state.passwordObjErr,
                newPassword: this.state.passwordObj.newPassword === '' ? errorsList.password.ERP104 : (this.state.passwordObj.newPassword.match(pwsEx) !== null) ? '' : errorsList.password.ERP101,
                confirmPassword: this.state.passwordObj.confirmPassword === '' ? errorsList.confirmPassword.ERP102 : (this.state.passwordObj.confirmPassword === this.state.passwordObj.newPassword) ? '' : errorsList.confirmPassword.ERP103,
            }
        })
        if (this.state.passwordObj.newPassword !== '' && this.state.passwordObj.confirmPassword !== "" && (this.state.passwordObj.newPassword.match(pwsEx) !== null) && (this.state.passwordObj.confirmPassword === this.state.passwordObj.newPassword)) {
            let changePassReq = {
                "confirm_password": this.state.passwordObj.confirmPassword,
                "new_password": this.state.passwordObj.newPassword,
                "resettoken": this.props.data.resetToken
            }
            changepassword(changePassReq).then((res) => {
                // console.log('change pass response', res);
                if (res && res.status === true) {
                    this.props.data.clearDirectOpenLinkPopup();
                    this.setState({ popupType: "PasswordUpdatedSuccessfully", changePasswordAPIErr: "" });
                } else {
                    this.setState({ changePasswordAPIErr: res.message });
                }
            })
        }
    }

    //signup form 
    onChangeSignFName = (e) => {
        let uppercase = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
        if (e.target.value.charCodeAt(0) !== 32 && e.target.value.match(alphanumericAndSpecialChars)) {
            this.setState({
                signupForm: { ...this.state.signupForm, firstName: uppercase, },
                signupFormErr: { ...this.state.signupFormErr, firstName: '' }
            });
        }
    }

    onChangeSignLName = (e) => {
        let uppercase = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
        if (e.target.value.charCodeAt(0) !== 32 && e.target.value.match(alphanumericAndSpecialChars)) {
            this.setState({
                signupForm: { ...this.state.signupForm, lastName: uppercase, },
                signupFormErr: { ...this.state.signupFormErr, lastName: '' }
            });
        }
    }

    onChangeSignMobile = (e) => {
        var pat = /^[0-9]*$/; var str = e.target.value;
        if (pat.test(str)) {
            this.setState({
                signupForm: { ...this.state.signupForm, mobileNo: str, },
                signupFormErr: { ...this.state.signupFormErr, mobileNo: '' }
            });
        }
    }

    onChangeSignEmail = (e) => {
        this.setState({ signupForm: { ...this.state.signupForm, email: e.target.value.replace(/\s/g, '') }, signupFormErr: { ...this.state.signupFormErr, email: "" } });
    }

    onChangeSignPassword = (e) => {
        let spaceEX = new RegExp(/(^\S*$)/); let confpwsEx = new RegExp(/^(?=.{0,13}$).*/)
        if (e.target.value.match(confpwsEx) && e.target.value.match(spaceEX)) {
            this.setState({ signupForm: { ...this.state.signupForm, password: e.target.value }, signupFormErr: { ...this.state.signupFormErr, password: "" } });
        } else {
            this.setState({ signupFormErr: { ...this.state.signupFormErr, password: "Please Enter Valid Password" } });
        }
    }

    onChangeSignCnfPassword = (e) => {
        let spaceEX = new RegExp(/(^\S*$)/); let confpwsEx = new RegExp(/^(?=.{0,13}$).*/)
        if (e.target.value.match(confpwsEx) && e.target.value.match(spaceEX)) {
            this.setState({ signupForm: { ...this.state.signupForm, confirmPassword: e.target.value }, signupFormErr: { ...this.state.signupFormErr, confirmPassword: "" } });
        } else {
            this.setState({ signupFormErr: { ...this.state.signupFormErr, confirmPassword: "Please Enter Valid Password" } });
        }
    }

    finalSignupClick = () => {
        this.setState({
            signupFormErr: {
                ...this.state.signupFormErr,
                email: (this.state.signupForm.email === '' || this.state.signupForm.email === undefined) ? errorsList.email.ERE101 : ((this.state.signupForm.email).match(emailPattern) !== null) ? '' : errorsList.email.ERE102,
                firstName: (this.state.signupForm.firstName === '' || this.state.signupForm.firstName === undefined) ? errorsList.firstName.ERFN101 : '',
                lastName: (this.state.signupForm.lastName === '' || this.state.signupForm.lastName === undefined) ? errorsList.lastName.ERLN101 : '',
                mobileNo: (this.state.signupForm.mobileNo === '' || this.state.signupForm.mobileNo === undefined) ? errorsList.mobileNo.ERMN102 : this.state.signupForm.mobileNo.toString().length < 4 || this.state.signupForm.mobileNo.toString().length > 22 ? errorsList.mobileNo.ERMN101 :'',
                countryCode: (this.state.signupForm.countryCode === '' || this.state.signupForm.countryCode === undefined) ? errorsList.countryCode.ERCC101 : '',
                nationality: (this.state.signupForm.nationality === '' || this.state.signupForm.nationality === undefined) ? errorsList.nationality.ERNT101 : '',
                password: this.state.isPasswordRequired ? 
                this.state.signupForm.password === '' ? errorsList.password.ERP104 : 
                // (this.state.signupForm.password.length !== this.props.data.siteData.site.min_password_length) ? `Password length must be ${this.props.data.siteData.site.min_password_length}`: ""
                (this.state.signupForm.password.match(pwsEx) !== null) ? '' : errorsList.password.ERP101 
                : "",
                confirmPassword: this.state.isPasswordRequired ? this.state.signupForm.confirmPassword === '' ? errorsList.confirmPassword.ERP102 : (this.state.signupForm.confirmPassword === this.state.signupForm.password) ? '' : errorsList.confirmPassword.ERP103 : "",
            }
        })
        if (this.state.signupForm.email !== "" && this.state.signupForm.firstName !== "" && this.state.signupForm.lastName !== "" && this.state.signupForm.mobileNo !== "" && (this.state.signupForm.nationality !== '' && this.state.signupForm.nationality !== undefined) && (this.state.signupForm.mobileNo !== '' && this.state.signupForm.mobileNo !== undefined) && this.state.signupForm.countryCode !== '' &&
            (!this.state.isPasswordRequired || (this.state.signupForm.password !== '' && this.state.signupForm.confirmPassword !== "" && (this.state.signupForm.password.match(pwsEx) !== null) && (this.state.signupForm.confirmPassword === this.state.signupForm.password)))) {
            let signupReq = {
                "first_name": this.state.signupForm.firstName,
                "last_name": this.state.signupForm.lastName,
                // "password": this.state.signupForm.password,
                "country_code": this.state.selectedCountry,
                "mobile_number": this.state.signupForm.mobileNo,
                "nationality": this.state.signupForm.nationality,
                "registraion_token": this.props?.data?.registrationToken || this.state.registrationToken,
                "isNormalLogin": this.state.isPasswordRequired
            }
            if (this.state.isPasswordRequired) {
                signupReq["password"] = this.state.signupForm.password || ""
            }
            // console.log("request", signupReq);
            register(signupReq).then((res) => {
                // console.log('res register', res);
                if (res && res.status === true && res.message && res.message !== "") {
                    // alert('you have registered successfully on Site');
                    this.setState({ registerAPIErr: "", isSuccessPopupVisible: true });
                    // this.props.history.push('/');
                    // window.location = '/'
                }
                else { this.setState({ registerAPIErr: res.message }); }
            })
        }
    }

    onContinueClickHandler = () => {
        window.location.reload() /* = '/' */
    }

    //captch signup
    onchangesigninWithCaptchaMail = (e) => {
        this.setState({ signUpWithCaptchaMail: e.target.value, signUpWithCaptchaMailErr: "" })
    }

    onClickCaptchaSignupbtn = () => {
        this.setState({ signUpWithCaptchaMailErr: this.state.signUpWithCaptchaMail === '' ? errorsList.email.ERE101 : (this.state.signUpWithCaptchaMail.match(emailPattern) !== null) ? '' : errorsList.email.ERE102 });
        if (this.state.signUpWithCaptchaMail !== '' && (this.state.signUpWithCaptchaMail.match(emailPattern) !== null)) {
            //api call
            let signupReq = {
                "email": this.state.signUpWithCaptchaMail
            }
            signup(signupReq).then(res => {
                if (res && res.status === true && res.message !== "") {
                    this.setState({ activationLink: true, signUpWithCaptchaMailErr: "" });

                } else {
                    this.setState({ activationLink: false, signUpWithCaptchaMailErr: res.message });
                }
            })
        } else {
            this.setState({ activationLink: false });
        }
    }

    /**Start of new traveller dropdown added for customisation */
    onClickInputCountryTraveller = () => {
        this.setState({ selectedCountry: { countryFlag: '' }, searchCountryText: this.state.searchCountryText, signupForm: { ...this.state.signupForm, countryCode: "" }, signupFormErr: { ...this.state.signupFormErr, countryCode: "" }, });
    }

    onKeyDownCountryCodeTraveller = (e) => {
        this.setState({ searchCountryText: '', signupForm: { ...this.state.signupForm, countryCode: "" }, signupFormErr: { ...this.state.signupFormErr, countryCode: "" }, selectedCountry: { countryFlag: '/images/ax-tick.svg' } });
    }

    selectCountryCodeFunctionTraveller = (ele) => {
        var numberPat = /^[0-9]*$/;
        if (numberPat.test(+ele.isdCode)) {
            this.setState({ searchCountryText: ('+' + ele.isdCode), selectedCountry: ele, signupForm: { ...this.state.signupForm, countryCode: ('+' + ele.isdCode) }, signupFormErr: { ...this.state.signupFormErr, countryCode: "" } });
        } else {
            this.setState({ searchCountryText: ele.isdCode, selectedCountry: ele, signupForm: { ...this.state.signupForm, countryCode: ele.isdCode }, signupFormErr: { ...this.state.signupFormErr, countryCode: "" } });
        }
    }

    onChangeInputCountryCodeFunctionTraveller = (value) => {
        var numberPat = /^[0-9]*$/;
        if (typeof value === "number") {
            let val22 = value.toString().includes('+') ? value.toString().split('+')[1] : value; //.includes('+') ? value.split('+')[1] : value;
            if (val22 !== '') {
                this.setState({ searchCountryText: '+' + val22, signupForm: { ...this.state.signupForm, countryCode: ('+' + val22) }, signupFormErr: { ...this.state.signupFormErr, countryCode: "" } })
            }
        } else if (typeof value === 'string') {
            let val22 = value.includes('+') ? value.split('+')[1] : value; //.includes('+') ? value.split('+')[1] : value;
            if (typeof val22 === 'number') {
                this.setState({ searchCountryText: '+' + val22, signupForm: { ...this.state.signupForm, countryCode: ('+' + val22) }, signupFormErr: { ...this.state.signupFormErr, countryCode: "" } })
            } else {
                let val22 = value;
                if (val22 !== '') {
                    this.setState({ searchCountryText: val22, signupForm: { ...this.state.signupForm, countryCode: val22 }, signupFormErr: { ...this.state.signupFormErr, countryCode: "" } })
                }
            }
        }
    }
    /**End of new traveller dropdown added for customisation */


    /** start of nationality */
    onClickNationalityHandler = (params) => {
        this.setState({ selectedNationality: params, signupForm: { ...this.state.signupForm, nationality: params.value }, signupFormErr: { ...this.state.signupFormErr, nationality: "" } });
    }

    onChangeInputNationality = (params) => {
        this.setState({ selectedNationality: { flag: '/images/ax-tick.svg' }, signupForm: { ...this.state.signupForm, nationality: params.target.value } })
    }

    onClosePopup = () => {
        if (this.state.isSuccessPopupVisible) {
            window.location.reload() /* = '/' */
        } else {
            this.props.data.onClickCross()
        }
    }

    renderLoginPopup = (popup) => {
        let styles = this.props.data.logincss;
        switch (popup) {
            case "LogIn": return (
                <>
                   
             <div className={`${styles.flex_div_login} ${!this.state.isLoginBlock ? styles.flex_row : styles.flex_col}  ${this.state.isMobile ? styles.abc : ''}` }>
                    <div className={styles.newspan}>
                    { !this.state.isLoginBlock &&
            <>
               <span onClick={() => {this.setState({ isLoginBlock:true });}} > Sign In    </span>
              
               </>}
                        {/* <div className={styles.login_by_mail_id_text}>Login With Email Id</div> */}
                        {/* <div className='flex flex-col justify-center items-center'>
                                    <span className={styles.new}>New</span>
                                    <button className={styles.viaotp} onClick={() => { this.setState({ popupType: "LoginViaOTP" }) }}>Login With OTP</button>
                                </div> */}
                    </div>
                    <div className={styles.login_container}>
                    { this.state.isLoginBlock &&
               
                        <form className={styles.login_detail_form} onSubmit={e => { e.preventDefault(); }}>
                            {this.state.loginErrorMsg !== "" ? <div className={styles.email_error_msg}>{this.state.loginErrorMsg}</div> : ""}
                            <input className={styles.email_id} id="email" autoComplete="off" type="email" placeholder="Enter your email address" maxLength="50" value={this.state.logincred.email} onChange={this.onChangeLoginEmail} />
                            {this.state.loginErrors.email !== "" ? <div className={styles.email_error_msg}>{this.state.loginErrors.email}</div> : ""}
                            <input tabindex="0" className={styles.email_id} id="pass" type="password" placeholder="Enter your Password" autoComplete="new-password"
                                maxLength="50" value={this.state.logincred.password} onChange={this.onChangeLoginPassword}
                                onClick={() => { this.setState({ isReadOnly: false }) }} onBlur={() => { this.setState({ isReadOnly: true }) }} />
                            {this.state.loginErrors.password !== "" ? <div className={styles.email_error_msg}>{this.state.loginErrors.password}</div> : ""}
                            {/* <div style={{marginTop:20}}><span className={styles.forgot_password} onClick={() => { this.props.data.clearDirectOpenLinkPopup(); this.setState({ popupType: "ForgotPassword", forgotPasswordEmail: this.state.logincred.email }) }}>Forgot Password?</span></div> */}
                            <button type="submit" className={styles.btn_login_td} onClick={this.onClickLoginBtn}>{this.state.onClickLoginLoader ? "" : "LOGIN"}</button>
                            {this.state.onClickLoginLoader && <div className={styles.login_loader} />}
                        
                            {/* <div className={styles.noaccount}>Don't have an account <span onClick={() => { this.props.data.clearDirectOpenLinkPopup(); this.setState({ popupType: "BeforeSignUp" }) }}>Sign Up</span></div> */}
                        </form>}
                    </div>
                         <FacebookGoogleLogin getSignUpForm={this.getSignUpForm} siteData={this.props.data.siteData} theme={'theme2'} styles={styles} isLoginBlock={this.state.isLoginBlock} />
                    </div>
                </>
            )
            case "SignUp": return (
                <ModalPopupComponent isMobile={this.props.data.isMobile} isPopup={this.state.headerPopup} onClosePopup={this.onClosePopup} modalCss={this.props.data.modalCss}>
                    <div className={styles.singup_header}>You are just one step away from
                        <br /> activating your Elevate Trips account</div>
                    {this.state.registerAPIErr !== "" ? <div className={styles.email_error_msg}>{this.state.registerAPIErr}</div> : ""}
                    <input className={styles.email_id} type="text" placeholder="First Name" maxLength="100" value={this.state.signupForm.firstName} onChange={this.onChangeSignFName} />
                    {this.state.signupFormErr.firstName !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.firstName}</div> : ""}
                    <input className={styles.email_id} type="text" placeholder="Last Name" maxLength="100" value={this.state.signupForm.lastName} onChange={this.onChangeSignLName} />
                    {this.state.signupFormErr.lastName !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.lastName}</div> : ""}
                    <div className={styles.country_drp}>
                        <div>
                            <IsdCountryDropdown
                                CountryListOptions={this.state.originCountries}
                                selectCountryCode={this.selectCountryCodeFunctionTraveller}
                                searchText={this.state.signupForm.countryCode}
                                selectedCountryInfo={this.state.selectedCountry}
                                onClickInputCountry={this.onClickInputCountryTraveller}
                                onChangeInputCountryCodeFunction={this.onChangeInputCountryCodeFunctionTraveller}
                                onKeyDownCountry={this.onKeyDownCountryCodeTraveller}
                                placeholder={'ISD'}
                                {...this.props.data.ISDstyles}
                                isSignUpCountryCode={true}
                            />
                            {this.state.signupFormErr.countryCode !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.countryCode}</div> : ""}
                        </div>
                        <div>
                            <input className={styles.email_id} type="text" placeholder="Mobile No." maxLength="22" value={this.state.signupForm.mobileNo} onChange={this.onChangeSignMobile} />
                            {this.state.signupFormErr.mobileNo !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.mobileNo}</div> : ""}
                        </div>
                    </div>
                    <CountryDropdownComp
                        countryFlag={this.state.originCountries}
                        searchText={this.state.signupForm.nationality}
                        selectCountry={this.onClickNationalityHandler}
                        selectedCountry={this.state.selectedNationality}
                        onChangeInputCountryCode={this.onChangeInputNationality}
                        placeholder={'Nationality'}
                        isSignUpNationality={true}
                        {...this.props.data.Countrystyles}
                    />
                    {this.state.signupFormErr.nationality !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.nationality}</div> : ""}
                    <input className={styles.email_id} id="email" autoComplete="off" type="email" placeholder="Email ID" maxLength="50" disabled={(this.props.data.emailId !== "" || this.state.signupForm.email) ? true : false} value={this.state.signupForm.email} onChange={this.onChangeSignEmail} />
                    {this.state.signupFormErr.email !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.email}</div> : ""}
                    {this.state.isPasswordRequired ?
                        <>
                            <input className={styles.email_id} id="password" type="password" autoComplete="new-password" placeholder="Password" maxLength="50" value={this.state.signupForm.password} onChange={this.onChangeSignPassword} />
                            {this.state.signupFormErr.password !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.password}</div> : ""}
                            <input className={styles.email_id} id="password" type="password" autoComplete="new-password" placeholder="Confirm Password" maxLength="50" value={this.state.signupForm.confirmPassword} onChange={this.onChangeSignCnfPassword} />
                            {this.state.signupFormErr.confirmPassword !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.confirmPassword}</div> : ""}
                        </>
                        : null
                    }
                    <button className={styles.btn_login_td} onClick={this.finalSignupClick}>Signup</button>
                    </ModalPopupComponent>
            )

            case "ChangePassword": return (
                <ModalPopupComponent isMobile={this.props.data.isMobile} isPopup={this.state.headerPopup} onClosePopup={this.onClosePopup} modalCss={this.props.data.modalCss}>
                <ChangePassword cssfile={this.props.data.logincss} onClickBackLink={() => { this.setState({ popupType: "LogIn" }) }} onClickConfirmbtn={this.onClickConfirmbtn}
                    newPasswordErr={this.state.passwordObjErr.newPassword} confirmPasswordErr={this.state.passwordObjErr.confirmPassword} changePasswordAPIErr={this.state.changePasswordAPIErr}
                    onChangeNewPassword={(e) => { this.setState({ passwordObj: { ...this.state.passwordObj, newPassword: e.target.value }, passwordObjErr: { ...this.state.passwordObjErr, newPassword: "" } }) }}
                    onChangeConfirmPassword={(e) => { this.setState({ passwordObj: { ...this.state.passwordObj, confirmPassword: e.target.value }, passwordObjErr: { ...this.state.passwordObjErr, confirmPassword: "" } }) }}
                    />
                    </ModalPopupComponent>
            )
            case "PasswordUpdatedSuccessfully": return (
                <ModalPopupComponent isMobile={this.props.data.isMobile} isPopup={this.state.headerPopup} onClosePopup={this.onClosePopup} modalCss={this.props.data.modalCss}>
                    {/* <div className={styles.back} onClick={() => {window.location.href = "/" }}>Back</div> */}
                    <div className={styles.login_by_mail_id_text + " " + "!text-center"}>Password Updated</div>
                    <div className={styles.pswd_success}>We successfully changed your password.</div>
                    <button className={styles.btn_login} onClick={() => { /* this.setState({ popupType: "LogIn" }) */window.location.href = "/signin" }}>Back to Login</button>
                </ModalPopupComponent>
            )
            case "SignUpSuccessful":
                return (
                    <ModalPopupComponent isMobile={this.props.data.isMobile} isPopup={this.state.headerPopup} onClosePopup={this.onClosePopup} modalCss={this.props.data.modalCss}>
                <div className={styles.SignUpSuccessful}>
                    <img src={"images/complete_tick.svg"} alt=""/>
                    <div className={styles.SignUpSuccessful_inner}>
                        <div className={styles.sucess_signup}>Success</div>
                        <span>Congratulations !! </span>
                        your registration was successful on Elevate Trips..
                    </div>
                    <button onClick={this.onContinueClickHandler}>{"CONTINUE".toUpperCase()}</button>
                </div>
                </ModalPopupComponent>
                )
            default:
                return null;

        }
    }


        render() {
            
            return (
                    <>{this.renderLoginPopup(this.state.isSuccessPopupVisible ? "SignUpSuccessful" : this.props.data.directOpenLinkPopup ? this.props.data.directOpenLinkPopup : this.state.popupType)}</>
                
            )
        }
    }