// import styles from "./index.module.css";

export default function ModalPopupComponent(props) {
  let styles = props ? props.modalCss : { "modal_container": "" };
  return (
    <>
      {props.isPopup === true ? (
        <div className={`${styles.modal_container} modal-container`}>
          <div className={`${styles.modal_background} modal-background`} onClick={() => { if(props.outSideClickBool) { props.onOutSideClick(false)} }}></div>
          <div className={`${styles.modal_overflow} modal-overflow`}>
            {props.isMobile === true ? (
              
              
              <div
                className={props.isHolidaySuccess==true?`${styles.success_mob_content} modal-content`:styles.modal_content + " " + styles.mobile_modal +"modal-content"
                }
              >
                 {props.showCrossButton === false ? (null) : (
                <div onClick={props.onClosePopup} className={`${styles.modal_close} modal-close`}>
                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#000000"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> */}
                <svg style={{top: '20px'}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </div>)}
                {props.children}
           
          </div>
            ) : (
              
              <div className={(props.isHolidaySuccess&&props.isHolidaySuccess==true? `${styles.success_email} success-email`:`${styles.modal_content} modal-content`)}>
                {props.showCrossButton === false ? (null) : (
                  <div onClick={props.onClosePopup} className={`${styles.modal_close} modal-close`}>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#000000"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> */}
                  {/* close btn */}
                  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                  </div>
                )}
                {props.children}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}