const assistedNewToken = (req) => {
    console.log("agentid:", req);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(req)
    };
    console.log("r*", requestOptions);
    return fetch(`/shared/api/assistedNewToken`, requestOptions)
      .then((response) => response.json());
  };
  
  export default assistedNewToken;

// pass id in from of object from index