
import Header1 from "./theme1";
import Header2 from "./theme2";
import Header3 from "./theme3";
import Header4 from "./theme4";
import Header5 from "./theme5";
import Header7 from "./theme7";
import commonFunction from '../../lib/utils/common';

export function Header(props) {
  const { theme } = props
   
  switch (theme) {
    case 'theme1':
      return !commonFunction.isValueEmpty(props) && Object.keys(props).length > 0 &&
        <Header1 data={props} />
      break;
      case 'theme2':
        return !commonFunction.isValueEmpty(props) && Object.keys(props).length > 0 &&
          <Header2 data={props} />
        break;
    case 'theme3':
      return !commonFunction.isValueEmpty(props) && Object.keys(props).length > 0 &&
        <Header3 data={props} />
      break;
    case 'theme4':
        return !commonFunction.isValueEmpty(props) && Object.keys(props).length > 0 &&
          <Header4 data={props} />
        break;
    case 'theme5':
        return !commonFunction.isValueEmpty(props) && Object.keys(props).length > 0 &&
        <Header5 data={props} />
        break;
    case 'theme7':
        return !commonFunction.isValueEmpty(props) && Object.keys(props).length > 0 &&
        <Header7 data={props} />
        break;   
    default:
      return <Header1 data={props} />
  }

}

export default Header;
