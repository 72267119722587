import FaceBookLoginComponent from './FacebookLoginComponent.js';
import GoogleLoginComponent from './GoogleLoginComponent.js';
import ReactFacebookLogin from 'react-facebook-login';
import config from '../../../../config/config.json'
import { facebooklogin } from '../../../lib/facebooklogin.js';
// import styles from "./index.module.css"

const FacebookGoogleLogin = (props) => {
    // console.log(props,"facebook");
    return (
        <div className={ `${!props.isLoginBlock ? props.styles?.googleComp : props.styles?.flex_col}`}>
            {/* {props.siteData.site.facebook_login_key !== "" && <FaceBookLoginComponent btnText={props.theme == 'theme2' ? '' : "Login with Facebook"} imageSrc='images/facebook.svg' siteData={props.siteData.site} />} */}
            {props.siteData.site.google_login_key !== "" && <GoogleLoginComponent isSignUp={props.isSignUp} getSignUpForm={props.getSignUpForm} text={props.signupText || props.theme == 'theme2' ? '' : 'Login'}  siteData={props.siteData.site} theme={props.theme} styles={props.styles} isSignIn={props.isSignIn} />}
            
        </div>
    )
}
export default FacebookGoogleLogin;