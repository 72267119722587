// import styles from "./index.module.css";

export default function ModalPopupComponent(props) {
  let styles = props ? props.modalCss : { "modal_container": "" };
  return (
    <>
      {props.isPopup === true ? (
        <div className={styles.modal_container}>
          <div className={styles.modal_background} onClick={() => { if(props.outSideClickBool) { props.onOutSideClick(false)} }}></div>
          <div className={styles.modal_overflow}>
            {props.isMobile === true ? (
              
              <div
              className={`${props.isLoginPopup==true?styles.success_mob_content:""} ${styles.modal_content} ${styles.mobile_modal}
              `}
              >
                 {props.showCrossButton === false ? (null) : (
                <div onClick={props.onClosePopup} className={styles.modal_close}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>)}
                {props.children}
              </div>
            ) : (
              <div className={(props.isHolidaySuccess&&props.isHolidaySuccess==true? styles.success_email:styles.modal_content)}>
                {props.showCrossButton === false ? (null) : (
                  <div onClick={props.onClosePopup} className={styles.modal_close}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                )}
                {props.children}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}