
import Menu1 from "./theme1";
import Menu2 from "./theme2";
import Menu3 from "./theme3";
import Menu4 from "./theme4";
import Menu5 from "./theme5";

export function Menu(props) {
  // console.log('props in header component ',props);
  const { theme } = props
      switch (theme) {
      case 'theme1':
        return  <Menu1 {...props}/>
          break;
      case 'theme2':
          return <Menu1 {...props}/>
          break;
      case 'theme3':
          return <Menu3 {...props}/>
          break;
      case 'theme4':
        return <Menu4 {...props}/>
        break;
      case 'theme5':
          return <Menu5 {...props}/>
          break;  
      default:
        return <Menu1 {...props}/>
  }

}

export default Menu;