import Script from "next/script";
export const GTagNoScript = (props) => {
  // console.log("==GTagNoScript==",props)
  return (
    <>
      {/* < !--Google Tag Manager(noscript)-- > */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${props?.google_tag_manager_id}
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      ></noscript>
      {/* < !--End Google Tag Manager(noscript)-- > */}
    </>
  );
};
export default GTagNoScript;

// key :GTM-TPG73H5
