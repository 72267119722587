import Router from 'next/router';
import { getProfileData } from "../myProfile";

const commonFunction = {
    isValueEmpty: function (data) {
        if (data !== undefined && data !== null && data !== '') {
            return false;
        } else {
            return true;
        }
    },
    redirectTo: function (url, querys) {
        Router.push({
            pathname: url,
            query: querys,
        })
    },
    getCookieTocken: function (cname) {
        var name = cname + '=';
        var decodedCookie = (typeof window !== "undefined") ? decodeURIComponent(document.cookie) : "";
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    },
    getUserProfile: (userToken,site_id) => {
        return new Promise(function (resolve, reject) {
            let data = {
                "accessToken": userToken !== undefined && userToken !== null && userToken !== "" ? userToken : '',
                "site_id": site_id !== undefined && site_id !== null && site_id !== "" ? site_id : '' /*"6124e1368ede4f08bf2d9ad4"*/
            }
            getProfileData(data).then(res => {
                if (res && res?.status && res?.status === true) {
                    if (res?.data && res?.data && res?.data?.data && res?.data?.data?.length > 0) {
                        res.data.data.map(ele => {
                            resolve({
                                status: true, data: {
                                    email: ele.email, registraion_token_status: ele.registraion_token_status,
                                    type: ele.type, user_agent: ele.user_agent, bounz_membership: ele.bounz_membership, bounz_status: ele.bounz_status,
                                    travellers: ele.travellers,
                                    corporate_detail:ele.corporate_detail
                                }
                            })
                        })
                    }
                    resolve({ status: false, message: "No data" })
                }
                resolve({ status: false, message: "Something is missing" })
                reject({ status: false, message: "Service Error" })
            }).catch(err => {
                reject({ status: false, error: err })
            })
        });
    },
    currencyAmountRounding:function(num, site_decimal_place) {
        try {
            const decimal_digits = !isNaN(site_decimal_place) ? site_decimal_place : 2;
            if (!isNaN(num)) {
                num = Number(num).toFixed(decimal_digits);
                // num = num.toLocaleString();
            }
        } catch (err) {
            console.log("currencyAmountRounding num =", num);
            console.log("currencyAmountRounding", err);
        }
        return num;
    },
}
export default commonFunction;