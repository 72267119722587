import React, { Component, Fragment } from "react";
import Link from "../../../Link";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";

class Footer1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: "",
      linksData: [],
    };
  }

  // const footerPaymentCard = [""
  //     { title: 'visa', imgSrc: "../images/visa.png" },
  //     { title: 'master', imgSrc: "../images/mastercard.png" },
  //     { title: 'discover', imgSrc: "../images/discover.png" },
  //     { title: 'american express', imgSrc: "../images/americanexpress.png" },
  //     { title: 'union pay', imgSrc: "../images/union-pay-01.png" },

  // ]
  // fna=()=>{
  //     console.log("props ka pointer",this.props);
  // }

  onChangeEmail = (data) => {
    this.setState({
      emailId: data.target.value,
    });
  };
  componentDidMount() {
    if (
      !commonFunction.isValueEmpty(this.props) &&
      !commonFunction.isValueEmpty(this.props.data)
    ) {
      if (
        !commonFunction.isValueEmpty(this.props.data.footermenu) &&
        !commonFunction.isValueEmpty(this.props.data.footermenu.menu)
      ) {
        this.setState({ linksData: this.props?.data?.footermenu?.menu });
      }
    }
  }
  render() {
    return (
      <footer className={this.props?.data?.cssfile?.footer_main}>
        <div className={this.props?.data?.cssfile?.footer_theme1_new}>
          <div className={this.props?.data?.cssfile?.footer_logo}>
            <input
              type="image"
              src={`${
                "/shared/api/media/" +
                this.props?.data?.siteData?.site.id +
                "/" +
                this.props?.data?.footermenu?.logo
              }`}
              alt="Logo"
            />

            <span>{this.props?.data?.footermenu?.text}</span>
          </div>
          <div className={this.props?.data?.cssfile?.footer_links}>
            {this.state.linksData?.map((link) => {
              return link.active == "active" ? (
                <Link key={link.text} href={link.url} className="">
                  {link.text}
                </Link>
              ) : (
                ""
              );
            })}
          </div>
          <div className={this.props?.data?.cssfile?.footer_send_email}>
            <div className={this.props?.data?.cssfile?.email_block}>
              <label className="">Signup for our Newsletter</label>
              <input
                type="email"
                placeholder="Your email address"
                value={this.state.emailId}
                onChange={(e) => {
                  this.onChangeEmail(e);
                }}
              />
              <button>Submit</button>
              {/* <form action="">
                                <input type="text" placeholder="Your email address" name="search" className={this.props.data.cssfile.footer_search} autocomplete="off" onChange={(e) => { this.onChangeEmail(e) }} />
                                <button type="submit">SUBMIT</button>
                            </form> */}
            </div>
            <div className={this.props?.data?.cssfile?.social_media}>
              {!commonFunction.isValueEmpty(this.props) &&
                !commonFunction.isValueEmpty(this.props.data) &&
                !commonFunction.isValueEmpty(this.props?.data?.footermenu) &&
                !commonFunction.isValueEmpty(
                  this.props?.data?.footermenu?.socialmedia
                ) &&
                this.props?.data?.footermenu?.socialmedia.map(
                  (sociallink, index) => (
                    <Fragment key={"socialmedia" + index}>
                      {sociallink.active == "active" ? (
                        <Link
                          key={sociallink.text}
                          href={sociallink.url}
                          className=""
                        >
                          {/* {sociallink.text} */}
                          <img
                            src={`${
                              "/shared/api/media/" +
                              this.props?.data?.siteData?.site.id +
                              "/" +
                              sociallink?.icon
                            }`}
                            alt="social"
                          />
                        </Link>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  )
                )}
            </div>

            <div className={this.props?.data?.cssfile?.payment_card}>
              {/* {footerPaymentCard.map((sociallink) => (
                            <img src={sociallink.imgSrc} alt="" />

                        ))} */}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer1;
