export const getProfileData = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('/shared/api/getProfile',requestOptions).then((response)=>response.json())
}
export const getCountry = (req)=>{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify(req)
    };
    return fetch('/shared/api/getCountryList').then((response)=>response.json())
}
export const getCity = (req)=>{
   
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify(req)
    };
    return fetch('/shared/api/getCityList').then((response)=>response.json())
}

export const getCityByCountry = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('api/getCityListByCountry',requestOptions).then((response)=>response.json())
}
export const updateProfile = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    console.log('updateProfile',JSON.stringify(req))
    return fetch('api/updateProfile',requestOptions).then((response)=>response.json())
}
export const getAirlines = (req)=>{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify(req)
    };
    return fetch('api/airlinesList').then((response)=>response.json())
}

export const deleteFrequentFlayer = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    console.log('deleteFrequentFlayer',JSON.stringify(req))
    return fetch('api/deleteFrequentFlayer',requestOptions).then((response)=>response.json())
}

export const getCurrency = (req)=>{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify(req)
    };
    return fetch('/shared/api/getCurrency').then((response)=>response.json())
}

export const getLanguage = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('api/getLanguage',requestOptions).then((response)=>response.json())
}

export const getBounzDetails =(req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('api/getBounzDetails',requestOptions).then((response)=>response.json())
}

export const delinkBounz = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('api/delinkBounz',requestOptions).then((response)=>response.json())
}

export const registerBounz = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('api/registerBounz',requestOptions).then((response)=>response.json())
}


