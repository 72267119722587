import LoginDesktop1 from "./Theme1/theme1/index";
import LoginDesktop2 from "./Theme2/index";
import LoginDesktop5 from "./Theme5/theme1/indexBackup";
import LoginDesktop4 from "./Theme4/theme1/index";
import LoginDesktop7 from "./theme7/index";

import LoginDesktop3 from "./Theme3/theme1/index";
import LoginMobile1 from "./Mobile/theme1/index";
import LoginMobile3 from "./Mobile/theme3/index";
import LoginMobile5 from "./Mobile/theme5/index";


const LoginWrapper = (props) => {
    const userAgent = typeof navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

    // return (
    //     !mobile ?
    //         props.loginTheme == "theme1" ? (<LoginDesktop1 data={props} />) : (<LoginDesktop2 />)
    //         :
    //         props.loginTheme == "theme1" ? (<LoginMobile1 data={props} />) : (<LoginMobile2 />)
    // );


    const theme = props.loginTheme

    console.log("props.loginTheme", props.loginTheme, theme)


    switch (theme) {
        case "theme1":
            if (mobile) {
                return <LoginMobile1 data={props} />
            } else {
                return <LoginDesktop1 data={props} />
            }
            break;

        case "theme2":
            return <LoginDesktop2 data={props} />

            break;
        case "theme3":
            if (mobile) {
                return <LoginMobile3 data={props} />
            } else {
                return <LoginDesktop3 data={props} />
            }
            break;

        case "theme4":
            return <LoginDesktop4 data={props} />

            break;

        case "theme5":
            return <LoginDesktop5 data={props} />
            break;
        case "theme7":
            return <LoginDesktop7 data={props} />
            break;

        default:
            if (mobile) {
                return <LoginMobile1 data={props} />
            } else {
                return <LoginDesktop1 data={props} />
            }


    }
}
export default LoginWrapper;



