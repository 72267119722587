import React from 'react';
import configUrl from '../../config/config.json'
// import configUrl from '../../../config/config.json';


const HideHeader = React.createContext({
    linkLogo: configUrl.BASE_URL,
    isHeader: false
})

export default HideHeader