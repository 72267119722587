import React from 'react';
import configUrl from '../../config/config.json'
// import configUrl from '../../../config/config.json';


const UploadLogo = React.createContext({
    linkLogo: configUrl.BASE_URL,
    isLogo: false
})

export default UploadLogo