import React, { Component, createRef } from 'react';
import Router from 'next/router';
import { logout } from '../../../lib/logout';
import commonFunction from '../../../lib/utils/common';


class Menu extends Component {
  constructor(props) {
    super(props);
    this.menusWrapper = createRef();
    this.state = {
      hamburgerOpen: this.props.isopen
    }

  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside = event => {
    if (this.menusWrapper.current && !this.menusWrapper.current.contains(event.target)) {
      this.props.closeMenusHandler(false)
    }
  }

  toggleHamburger = () => {
    this.setState({ hamburgerOpen: !this.state.hamburgerOpen })
  }

  onClickRedirect = (data, index) => {
    console.log("data.href", data.href);
    // Router.push({
    //   pathname: '/myprofile',
    //   query: `menu=${data.href}`,
    if (data.href == 'Logout') {
      this.props.onclickLogout()
    } else {
      this.props.clickedTab(index)
      window.location.href = `/myprofile?Linkindex=${index}`
    }
    // })

  }

  onClickProfile = () => {
    this.props.clickedTab(0)
    commonFunction.redirectTo('/myprofile')
  }
  onClickLogout = () => {
    logout().then((responseJSON) => {
      if (responseJSON.status === true || responseJSON.message === "Logout successfull") {
        this.setState({
          userFirststname: "",
          userType: ""
        })
        location.reload()
        commonFunction.redirectTo('/')
    
      } else {
      }
    }).catch(err => {
      console.log("errrrrrrrrrrr", err);
    })
  }

  myBookingClickHandler = () => {
    
    // this.props.clickedTab(1)
    window.location.href = `/myprofile?Linkindex=2`
    // commonFunction.redirectTo('/myprofile', { Linkindex: 1 })
    
  }
 homeClickHandler = () => {
    
    // this.props.clickedTab(1)
    window.location.href = `/`
    // commonFunction.redirectTo('/myprofile', { Linkindex: 1 })
    
  }

  render() {
    // console.log("=========================>", this.props);
    return (
      <div>
        {this.props.isopen &&
          <div ref={this.menusWrapper} className={this.props.styles.navigation_ineline}>
            {this.props.pathname === "/myprofile" ?
              this.props.MenuList.length > 0 && this.props.MenuList.map((element, index) => {
                return (
                  <div className={this.props.styles.mob_brd} key={"menulist" + index} onClick={() => { this.onClickRedirect(element, index) }}>
                    <div className={this.props.styles.mobile_sidenav}>
                      <span className={`${this.props.styles.my_settings_section_card_icon} ${this.props.styles.bui_u_margin8}`}><img src={element.imgInActiveSrc} alt={'imapo'} width="20" height="20" /> </span>
                      <a id="myprofile" role="button" className={this.props.styles.menu_item}  >{element.linkItem}</a>
                    </div>
                  </div>
                )
              }) : <div className={this.props?.cssfile?.afterlogin}> <span className={this.props?.cssfile?.user_code}>{this.state.userFirststname}</span>
                <div className={this.props?.cssfile?.profile_frst}>
                                  
                  {/* <span onClick={this.homeClickHandler}> <div className={this.props?.cssfile?.menu_icon}><img type="image" src="/images/profile-01.svg" alt="profile" /></div>Home</span> */}
                 
                  <span onClick={this.onClickProfile}> <div className={this.props?.cssfile?.menu_icon}><img type="image" src="/images/profile-01.svg" alt="profile" /></div>My Profile</span>
                  <span onClick={this.myBookingClickHandler}> <div className={this.props?.cssfile?.menu_icon}><img type="image" src="/images/booking-01.svg" alt="mybooking" /></div>My Trips</span>
                  
                  <span onClick={this.onClickLogout}> <div className={this.props?.cssfile?.menu_icon}><img type="image" src="/images/logout-01.svg" alt="Logout" /></div>Logout</span>
                </div>
                {/* <div className={`${styles.profile_frst} ${styles.support_scnd}`}> */}
                {/* <div className={`${this.props?.cssfile?.profile_frst} ${this.props?.cssfile?.support_scnd}`}>
                  <div class={this.props?.cssfile?.support_heading}>Support</div>
                  <a href="tel:+91 9988776655">
                    <span> <div className={this.props?.cssfile?.menu_icon}><img type="image" src="/images/call-01.svg" alt="call" /></div>+91 9988776655</span>
                  </a>
                  <a href="mailto:customercare@zenithholidays.com"><span><div className={this.props?.cssfile?.menu_icon}><img type="image" src="/images/email-01.svg" alt="connect" /></div>customercare@zenithholidays.com</span></a>
                </div> */}
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export default Menu;
