import React, { Component } from "react";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";
// import cssfile from '../../ModalPopupComponent/index.module.css';
import ModalPopupComponent from "../../ModalPopupComponent/ModalPopupComponent";
class MobileSubHeader1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getMenuData: "",
      isPopup: false,
    };
  }

  // let menuData = ''
  onClickHeaderMenu = (data, i) => {
    // console.log("onClickHeaderMenu=============", data, "iiiiiiii", this.props)
    this.setState({
      getMenuData: data.text,
      isPopup: true,
    });
    // menuData=data.text
  };

  renderInfo = (menudata) => {
    // console.log("props.......renderInfo.........", menudata)

    switch (menudata) {
      case "Flight":
        // console.log("props.......renderInfo.........menu", menudata)
        return (
          <div className="renderinfo-flight">
            {/* <ModalPopupComponent isMobile={true} isPopup={this.state.isPopup} onClosePopup={()=>this.setState({isPopup:false})} modalCss={ this.props.data.popupcss}> */}
            Flight
            {/* {renderLoginPopup(props.data.popupType)} */}
            {/* </ModalPopupComponent> */}
          </div>
        );
      case "Hotels":
        // console.log("props.......renderInfo.........menu", menudata)
        return <div className="renderinfo-flight">Hotel</div>;
      case "Holidays":
        // console.log("props.......renderInfo.........menu", menudata)
        return <div className="renderinfo-flight">Holiday</div>;
      case "Activities":
        // console.log("props.......renderInfo.........menu", menudata)
        return <div className="renderinfo-flight">activity</div>;
    }
  };
  render() {
    // console.log("onClickHeaderMenu=============", "iiiiiiii", this.props)
    return (
      // <div></div>

      <header className={this.props.data.cssfile.new_header}>
        <div className="all_section">
          {!commonFunction.isValueEmpty(this.props) &&
            !commonFunction.isValueEmpty(this.props.data) &&
            !commonFunction.isValueEmpty(this.props.data.headermenu) &&
            !commonFunction.isValueEmpty(this.props.data.headermenu.menu) &&
            this.props.data.headermenu.menu.map((link, i) => (
              <div key={"headerMenu" + i} className="section_div">
                <div onClick={() => this.onClickHeaderMenu(link, i)}>
                  <img
                    src={`${
                      "/shared/api/media/" +
                      this.props.data.site_id +
                      "/" +
                      link.icon
                    }`}
                    alt="headermenu"
                  />
                  {link.text}
                </div>
              </div>
            ))}
          {/* {console.log("menudat========",menuData)} */}
          {this.renderInfo(this.state.getMenuData)}
        </div>
      </header>
    );
  }
}

export default MobileSubHeader1;
