import React from "react";
import { forgotpassword } from "../../../../lib/forgotpassword";
import { login } from "../../../../lib/login";
import { signup } from "../../../../lib/signup";
import ModalPopupComponent from "../../../ModalPopupComponent/ModalPopupComponent";
import ChangePassword from "../../ChangePassword";
import {
  setTockenInCoockie,
  getCookieTocken,
  removeUserTokenFromCookie,
} from "../../userToken";
import IsdCountryDropdown from "../../../IsdCountryDropdown/index";
import CountryDropdownComp from "../../../CountryDropdown/index";
import { register } from "../../../../lib/register";
import FacebookGoogleLogin from "../../FacebookLogin";
import CaptchaComponent from "../../CaptchaComponent";
import getCountriesList from "../../../../lib/getCountriesList";
import { changepassword } from "../../../../lib/changepassword";

const listOptions = [
  { countryName: "India", isdCode: "+91" },
  { countryName: "Japan", isdCode: "+81" },
];

const errorsList = {
  firstName: {
    ERFN101: "Please Enter First Name",
  },
  lastName: {
    ERLN101: "Please Enter Last Name",
  },
  countryCode: {
    ERCC101: "Please Select The Country Code",
  },
  nationality: {
    ERNT101: "Please Select Nationality",
  },
  mobileNo: {
    ERMN101: "Invalid Mobile Number",
    ERMN102: "Please Enter Mobile Number",
  },
  email: {
    ERE101: "Please Enter Email Id",
    ERE102: "Please Enter Valid Email Id",
  },
  password: {
    ERP101:
      "Password must be between 8-13 characters in length and must comprise of at least 1 lowercase alphabet (a-z), 1 uppercase alphabet (A-Z), 1 number (0-9) and 1 special character",
    ERP104: "Please Enter Password",
    ERP105: "Invalid Password",
  },
  confirmPassword: {
    ERP102: "Please Re-Enter New Password",
    ERP103: "Passwords Do Not Match",
  },
  OTP: {
    EROTP101: "Please Enter OTP",
    EROTP102: "Please Enter Valid OTP",
  },
  isTermsChecked: {
    ERTC101: "Please Accept The Terms & Conditions To Proceed",
  },
};

const emailPattern =
  /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
let pwsEx = new RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,13}$/
);
var alphabetonly = new RegExp(/^[a-zA-Z_\s]*$/);
export default class Theme1 extends React.Component {
  constructor(props) {
    // console.log(props,"mobile login component");
    super(props);
    this.state = {
      toggleAgentLogin: false,
      headerPopup: true,
      popupType: "LogIn",
      logincred: { email: "", password: "" },
      loginErrors: { email: "", password: "" },
      loginErrorMsg: "",
      signUpEmail: "",
      signUpEmailErr: "",
      onClickLoginLoader: false,
      activationLink: false,
      forgotPasswordEmail: "",
      forgotPassText: false,
      forgotPasswordErr: "",
      loginWithOTPEmail: "",
      loginOTPEmailErr: "",
      verifyOTPEmail: "",
      verifyOTPEmailErr: "",
      signUpWithCaptchaMail: "",
      signUpWithCaptchaMailErr: "",
      passwordObj: { newPassword: "", confirmPassword: "" },
      passwordObjErr: { newPassword: "", confirmPassword: "" },
      changePasswordAPIErr: "",
      searchCountryText: "",
      selectedCountry: "",
      selectedNationality: "",
      signupForm: {
        firstName: "",
        lastName: "",
        countryCode: "",
        mobileNo: "",
        nationality: "",
        email: this.props.data.emailId !== "" ? this.props.data.emailId : "",
        password: "",
        confirmPassword: "",
      },
      signupFormErr: {
        firstName: "",
        lastName: "",
        countryCode: "",
        mobileNo: "",
        nationality: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      setToken: "",
      bounz_register: false,
      isCaptchaSelected: false,
      captchErrorMsg: "",
      originCountries: [],
      isSuccessPopupVisible: false,
      isReadOnly: true,
      isPasswordRequired: true,
      registrationToken: "",
    };
  }

  componentDidMount = () => {
    if (
      this.props.data.directOpenLinkPopup === "SignUp" ||
      this.props.data.directOpenLinkPopup === "LogIn" ||
      this.state.popupType === "SignUp" ||
      this.state.popupType === "LogIn" ||
      this.props.data.directOpenLinkPopup === "BeforeSignUp"
    ) {
      getCountriesList().then((resp) => {
        let countryList = [];
        if (resp?.data?.length > 0) {
          resp?.data.map((item) => {
            countryList.push({
              ...item,
              value: item.contry_name,
              isdCode: item.contry_isd,
              countryCode: item.contry_code,
              flag: item.contry_flag,
              countryFlag: item.contry_flag,
              countryName: item.contry_name,
            });
          });
        }
        this.setState({ originCountries: countryList });
      });
    }
  };

  // Login Functinality Starts
  onChangeLoginEmail = (e) => {
    this.setState({
      logincred: { ...this.state.logincred, email: e.target.value },
      loginErrors: { ...this.state.loginErrors, email: "" },
    });
  };
  onChangeLoginPassword = (e) => {
    let spaceEX = new RegExp(/(^\S*$)/);
    let confpwsEx = new RegExp(/^(?=.{0,13}$).*/);
    if (e.target.value.match(confpwsEx) && e.target.value.match(spaceEX)) {
      this.setState({
        logincred: { ...this.state.logincred, password: e.target.value },
        loginErrors: { ...this.state.loginErrors, password: "" },
      });
    } else {
      this.setState({
        loginErrors: {
          ...this.state.loginErrors,
          password: "Please Enter Valid Password",
        },
      });
    }
  };
  onClickLoginBtn = async () => {
    this.setState({
      loginErrors: {
        ...this.state.loginErrors,
        email:
          this.state.logincred.email === ""
            ? errorsList.email.ERE101
            : /* (this.state.logincred.email.match(emailPattern) !== null) ? */ "" /* : errorsList.email.ERE102 */,
        password:
          this.state.logincred.password === ""
            ? errorsList.password.ERP104
            : this.state.logincred.password.match(pwsEx) !== null
            ? ""
            : errorsList.password.ERP105,
      },
      loginErrorMsg: "",
    });
    if (
      this.state.logincred.email !== "" &&
      this.state.logincred.password !== "" &&
      this.state.logincred.password.match(pwsEx) !==
        null /* && (this.state.logincred.email.match(emailPattern) !== null) */
    ) {
      let loginReq = {
        // "site_id": "6124e1368ede4f08bf2d9ad4",
        email: this.state.logincred.email,
        password: this.state.logincred.password,
        type: this.state.toggleAgentLogin ? "agent" : undefined,
      };
      this.setState({ onClickLoginLoader: true });
      login(loginReq).then((responseJSON) => {
        console.log("responseJSON", responseJSON);
        this.setState({ onClickLoginLoader: false });
        if (
          responseJSON.status === true &&
          responseJSON.accessToken !== "" &&
          responseJSON.accessToken !== undefined &&
          responseJSON.accessToken !== null
        ) {
          // this.setState({
          //     setToken: responseJSON.accessToken
          //     // setToken: responseJSON
          // }, () => {
          //     // setTockenInCoockie(JSON.stringify(this.state.setToken));
          //     setTockenInCoockie(this.state.setToken);
          // })
          this.props.data.onClickCross();
          location.reload();
        } else if (!responseJSON.status && responseJSON.isPartialRegister) {
          if (
            typeof this?.props?.data?.clearDirectOpenLinkPopup === "function"
          ) {
            this.props.data.clearDirectOpenLinkPopup();
          }
          this.setState({
            popupType: "SignUp",
            isPasswordRequired: true,
            registrationToken: responseJSON.registration_token,
            signupForm: {
              ...this.state.signupForm,
              email: this.state.logincred.email,
            },
          });
        } else {
          this.setState({ loginErrorMsg: responseJSON.message });
        }
      });
    }
  };
  //Login Func Ended

  //signup Email
  onChangeSignupEmailId = (e) => {
    this.setState({
      signUpEmail: e.target.value,
      signUpEmailErr: "",
      activationLink: false,
    });
  };

  onClickSignupBtn = () => {
    //  console.log(this.props?.data?.siteData?.site?.login_captcha_key,"captcha key");
    this.setState({
      signUpEmailErr:
        this.state.signUpEmail === ""
          ? errorsList.email.ERE101
          : this.state.signUpEmail.match(emailPattern) !== null
          ? ""
          : errorsList.email.ERE102,
    });

    if (
      this.state.signUpEmail !== "" &&
      this.state.signUpEmail.match(emailPattern) !== null &&
      (!this.props?.data?.siteData?.site?.login_captcha_key ||
        this.state.isCaptchaSelected)
    ) {
      //api call
      let signupReq = {
        email: this.state.signUpEmail,
      };
      signup(signupReq).then((res) => {
        if (res && res.status === true && res.message !== "") {
          this.setState({ activationLink: true, signUpEmailErr: "" });
          if (
            typeof this?.props?.data?.clearDirectOpenLinkPopup === "function"
          ) {
            this.props.data.clearDirectOpenLinkPopup();
          }
          this.setState({
            popupType: "SignUp",
            isPasswordRequired: true,
            registrationToken: res.registration_token,
            signupForm: {
              ...this.state.signupForm,
              email: this.state.signUpEmail,
            },
          });
        } else {
          // this.setState({ activationLink: false, signUpEmailErr: res.message[0].msg });
          this.setState({
            activationLink: false,
            signUpEmailErr:
              typeof res.message === "string"
                ? res.message
                : res.message && res.message.length > 0
                ? res.message[0].msg
                : "some thing went wrong",
          });
        }
      });
    } else {
      this.setState({
        activationLink: false,
        captchErrorMsg: !this.state.isCaptchaSelected
          ? "Please Select Captcha"
          : "",
      });
    }
  }; //ended here

  //Forgot pass
  onChangeForgotPassEmail = (e) => {
    this.setState({
      forgotPasswordEmail: e.target.value,
      forgotPasswordErr: "",
    });
  };

  onClickForgotSubimitBtn = () => {
    this.setState({
      forgotPasswordErr:
        this.state.forgotPasswordEmail === ""
          ? errorsList.email.ERE101
          : this.state.forgotPasswordEmail.match(emailPattern) !== null
          ? ""
          : errorsList.email.ERE102,
    });
    if (
      this.state.forgotPasswordEmail !== "" &&
      this.state.forgotPasswordEmail.match(emailPattern) !== null
    ) {
      //api call
      let forgotPassReq = {
        email: this.state.forgotPasswordEmail,
        site_id: this.props.data.siteData.site.id,
      };
      forgotpassword(forgotPassReq).then((res) => {
        console.log("forgot pass in common", res);
        if (res && res.status === true && res.message && res.message !== "") {
          this.setState({ forgotPassText: true, forgotPasswordErr: "" });
        } else {
          this.setState({
            forgotPassText: false,
            forgotPasswordErr: res.message,
          });
        }
      });
    } else {
      this.setState({ forgotPassText: false });
    }
  };

  //send otp popup
  onClickSendOTP = () => {
    this.setState({
      loginOTPEmailErr:
        this.state.loginWithOTPEmail === ""
          ? errorsList.email.ERE101
          : this.state.loginWithOTPEmail.match(emailPattern) !== null
          ? ""
          : errorsList.email.ERE102,
    });
    if (
      this.state.loginWithOTPEmail !== "" &&
      this.state.loginWithOTPEmail.match(emailPattern) !== null
    ) {
      //api call
      // setPopupType("VerifyOTP")
      this.setState({ popupType: "VerifyOTP" });
    }
  };

  onChangeLoginOTPEmail = (e) => {
    this.setState({ loginWithOTPEmail: e.target.value, loginOTPEmailErr: "" });
  };

  onChangeEmailOTP = (e) => {
    setLoginWithOTPEmail({
      loginWithOTPEmail: e.target.value,
      loginOTPEmailErr: "",
    });
  };

  onClickResendOTP = () => {
    //api call
  };

  //verify otp
  onChangeVerifyOTP = (e) => {
    this.setState({ verifyOTPEmail: e.target.value, verifyOTPEmailErr: "" });
  };

  onOTPLogin = () => {
    this.setState({
      verifyOTPEmailErr:
        this.state.verifyOTPEmail === ""
          ? errorsList.OTP.EROTP101
          : this.state.verifyOTPEmail.match(otpregex) !== null
          ? ""
          : errorsList.OTP.EROTP102,
    });
    if (
      this.state.verifyOTPEmail !== "" &&
      this.state.verifyOTPEmail.match(otpregex) !== null
    ) {
      //api call
    }
  };

  // onClickConfirmbtn = () => {
  //     this.setState({
  //         passwordObjErr: {
  //             ...this.state.passwordObjErr,
  //             newPassword: this.state.passwordObj.newPassword === '' ? errorsList.password.ERP104 : (this.state.passwordObj.newPassword.match(pwsEx) !== null) ? '' : errorsList.password.ERP101,
  //             confirmPassword: this.state.passwordObj.confirmPassword === '' ? errorsList.confirmPassword.ERP102 : (this.state.passwordObj.confirmPassword === this.state.passwordObj.newPassword) ? '' : errorsList.confirmPassword.ERP103,
  //         }
  //     })
  //     if (this.state.passwordObj.newPassword !== '' && this.state.passwordObj.confirmPassword !== "" && (this.state.passwordObj.newPassword.match(pwsEx) !== null) && (this.state.passwordObj.confirmPassword === this.state.passwordObj.newPassword)) {

  //         let changePassReq = {
  //             "old_password": "Amaanpatel#21",
  //             "new_password": "Amaanpatel@22",
  //             "email": "amaanpatel565@gmail.com",
  //             "site_id": "6124e1368ede4f08bf2d9ad4"
  //         }

  //         // fetch('/api/sso/resetpassword', changePassReq).then((res) => {

  //         // })

  //         this.setState({ popupType: "PasswordUpdatedSuccessfully" });
  //     }
  // }

  onClickConfirmbtn = () => {
    this.setState({
      passwordObjErr: {
        ...this.state.passwordObjErr,
        newPassword:
          this.state.passwordObj.newPassword === ""
            ? errorsList.password.ERP104
            : this.state.passwordObj.newPassword.match(pwsEx) !== null
            ? ""
            : errorsList.password.ERP101,
        confirmPassword:
          this.state.passwordObj.confirmPassword === ""
            ? errorsList.confirmPassword.ERP102
            : this.state.passwordObj.confirmPassword ===
              this.state.passwordObj.newPassword
            ? ""
            : errorsList.confirmPassword.ERP103,
      },
    });
    if (
      this.state.passwordObj.newPassword !== "" &&
      this.state.passwordObj.confirmPassword !== "" &&
      this.state.passwordObj.newPassword.match(pwsEx) !== null &&
      this.state.passwordObj.confirmPassword ===
        this.state.passwordObj.newPassword
    ) {
      let changePassReq = {
        confirm_password: this.state.passwordObj.confirmPassword,
        new_password: this.state.passwordObj.newPassword,
        resettoken: this.props.data.resetToken,
      };
      changepassword(changePassReq).then((res) => {
        console.log("change pass response", res);
        if (res && res.status === true) {
          this.props.data.clearDirectOpenLinkPopup();
          this.setState({
            popupType: "PasswordUpdatedSuccessfully",
            changePasswordAPIErr: "",
          });
        } else {
          this.setState({ changePasswordAPIErr: res.message });
        }
      });
    }
  };

  //signup form
  onChangeSignFName = (e) => {
    let uppercase =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    if (
      e.target.value.charCodeAt(0) !== 32 &&
      e.target.value.match(alphabetonly)
    ) {
      this.setState({
        signupForm: { ...this.state.signupForm, firstName: uppercase },
        signupFormErr: { ...this.state.signupFormErr, firstName: "" },
      });
    }
  };

  onChangeSignLName = (e) => {
    let uppercase =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    if (
      e.target.value.charCodeAt(0) !== 32 &&
      e.target.value.match(alphabetonly)
    ) {
      this.setState({
        signupForm: { ...this.state.signupForm, lastName: uppercase },
        signupFormErr: { ...this.state.signupFormErr, lastName: "" },
      });
    }
  };

  onChangeSignMobile = (e) => {
    var pat = /^[0-9]*$/;
    var str = e.target.value;
    if (pat.test(str)) {
      this.setState({
        signupForm: { ...this.state.signupForm, mobileNo: str },
        signupFormErr: { ...this.state.signupFormErr, mobileNo: "" },
      });
    }
  };

  onChangeSignEmail = (e) => {
    this.setState({
      signupForm: { ...this.state.signupForm, email: e.target.value },
      signupFormErr: { ...this.state.signupFormErr, email: "" },
    });
  };

  onChangeSignPassword = (e) => {
    let spaceEX = new RegExp(/(^\S*$)/);
    let confpwsEx = new RegExp(/^(?=.{0,13}$).*/);
    if (e.target.value.match(confpwsEx) && e.target.value.match(spaceEX)) {
      this.setState({
        signupForm: { ...this.state.signupForm, password: e.target.value },
        signupFormErr: { ...this.state.signupFormErr, password: "" },
      });
    } else {
      this.setState({
        signupFormErr: {
          ...this.state.signupFormErr,
          password: "Please Enter Valid Password",
        },
      });
    }
  };

  onChangeSignCnfPassword = (e) => {
    let spaceEX = new RegExp(/(^\S*$)/);
    let confpwsEx = new RegExp(/^(?=.{0,13}$).*/);
    if (e.target.value.match(confpwsEx) && e.target.value.match(spaceEX)) {
      this.setState({
        signupForm: {
          ...this.state.signupForm,
          confirmPassword: e.target.value,
        },
        signupFormErr: { ...this.state.signupFormErr, confirmPassword: "" },
      });
    } else {
      this.setState({
        signupFormErr: {
          ...this.state.signupFormErr,
          confirmPassword: "Please Enter Valid Password",
        },
      });
    }
  };

  finalSignupClick = () => {
    this.setState({
      signupFormErr: {
        ...this.state.signupFormErr,
        email:
          this.state.signupForm.email === "" ||
          this.state.signupForm.email === undefined
            ? errorsList.email.ERE101
            : this.state.signupForm.email.match(emailPattern) !== null
            ? ""
            : errorsList.email.ERE102,
        firstName:
          this.state.signupForm.firstName === "" ||
          this.state.signupForm.firstName === undefined
            ? errorsList.firstName.ERFN101
            : "",
        lastName:
          this.state.signupForm.lastName === "" ||
          this.state.signupForm.lastName === undefined
            ? errorsList.lastName.ERLN101
            : "",
        mobileNo:
          this.state.signupForm.mobileNo === "" ||
          this.state.signupForm.mobileNo === undefined
            ? errorsList.mobileNo.ERMN102
            : this.state.signupForm.mobileNo.toString().length < 4 ||
              this.state.signupForm.mobileNo.toString().length > 22
            ? errorsList.mobileNo.ERMN101
            : "",
        countryCode:
          this.state.signupForm.countryCode === "" ||
          this.state.signupForm.countryCode === undefined
            ? errorsList.countryCode.ERCC101
            : "",
        nationality:
          this.state.signupForm.nationality === "" ||
          this.state.signupForm.nationality === undefined
            ? errorsList.nationality.ERNT101
            : "",
        password: this.state.isPasswordRequired
          ? this.state.signupForm.password === ""
            ? errorsList.password.ERP104
            : this.state.signupForm.password.length !==
              this.props.data.siteData.site.min_password_length
            ? `Password length must be ${this.props.data.siteData.site.min_password_length}`
            : ""
          : // (this.state.signupForm.password.match(pwsEx) !== null) ? '' : errorsList.password.ERP101
            "",
        confirmPassword: this.state.isPasswordRequired
          ? this.state.signupForm.confirmPassword === ""
            ? errorsList.confirmPassword.ERP102
            : this.state.signupForm.confirmPassword ===
              this.state.signupForm.password
            ? ""
            : errorsList.confirmPassword.ERP103
          : "",
      },
    });
    if (
      this.state.signupForm.email !== "" &&
      this.state.signupForm.firstName !== "" &&
      this.state.signupForm.lastName !== "" &&
      this.state.signupForm.mobileNo !== "" &&
      this.state.signupForm.nationality !== "" &&
      this.state.signupForm.nationality !== undefined &&
      this.state.signupForm.mobileNo !== "" &&
      this.state.signupForm.mobileNo !== undefined &&
      this.state.signupForm.countryCode !== "" &&
      (!this.state.isPasswordRequired ||
        (this.state.signupForm.password !== "" &&
          this.state.signupForm.confirmPassword !== "" &&
          this.state.signupForm.password.match(pwsEx) !== null &&
          this.state.signupForm.confirmPassword ===
            this.state.signupForm.password))
    ) {
      let signupReq = {
        first_name: this.state.signupForm.firstName,
        last_name: this.state.signupForm.lastName,
        // "password": this.state.signupForm.password,
        country_code: this.state.selectedCountry,
        mobile_number: this.state.signupForm.mobileNo,
        nationality: this.state.signupForm.nationality,
        registraion_token:
          this.props?.data?.registrationToken || this.state.registrationToken,
        isNormalLogin: this.state.isPasswordRequired,
      };
      if (this.state.isPasswordRequired) {
        signupReq["password"] = this.state.signupForm.password || "";
      }
      //console.log("request", signupReq);
      register(signupReq).then((res) => {
        console.log("res register", res);
        if (res && res.status === true && res.message && res.message !== "") {
          this.setState({ registerAPIErr: "", isSuccessPopupVisible: true });
        } else {
          this.setState({ registerAPIErr: res.message });
        }
      });
    }
  };

  //captch signup
  onchangesigninWithCaptchaMail = (e) => {
    this.setState({
      signUpWithCaptchaMail: e.target.value,
      signUpWithCaptchaMailErr: "",
    });
  };

  onClickCaptchaSignupbtn = () => {
    this.setState({
      signUpWithCaptchaMailErr:
        this.state.signUpWithCaptchaMail === ""
          ? errorsList.email.ERE101
          : this.state.signUpWithCaptchaMail.match(emailPattern) !== null
          ? ""
          : errorsList.email.ERE102,
    });
    if (
      this.state.signUpWithCaptchaMail !== "" &&
      this.state.signUpWithCaptchaMail.match(emailPattern) !== null
    ) {
      //api call
      // setPopupType("VerifyOTP")
      this.setState({ activationLink: true });
      // this.setState({ popupType: "VerifyOTP" });
    } else {
      this.setState({ activationLink: false });
    }
  };

  /**Start of new traveller dropdown added for customisation */
  onClickInputCountryTraveller = () => {
    this.setState({
      searchCountryText: this.state.searchCountryText,
      signupForm: { ...this.state.signupForm, countryCode: "" },
      signupFormErr: { ...this.state.signupFormErr, countryCode: "" },
    });
  };

  onKeyDownCountryCodeTraveller = (e) => {
    this.setState({
      searchCountryText: "",
      signupForm: { ...this.state.signupForm, countryCode: "" },
      signupFormErr: { ...this.state.signupFormErr, countryCode: "" },
      selectedCountry: { countryFlag: "/images/ax-tick.svg" },
    });
  };

  selectCountryCodeFunctionTraveller = (ele) => {
    var numberPat = /^[0-9]*$/;
    if (numberPat.test(ele.isdCode)) {
      this.setState({
        searchCountryText: "+" + ele.isdCode,
        selectedCountry: ele,
        selectedCountry: ele,
        signupForm: {
          ...this.state.signupForm,
          countryCode: "+" + ele.isdCode,
        },
        signupFormErr: { ...this.state.signupFormErr, countryCode: "" },
      });
    } else {
      this.setState({
        searchCountryText: ele.isdCode,
        selectedCountry: ele,
        selectedCountry: ele,
        signupForm: { ...this.state.signupForm, countryCode: ele.isdCode },
        signupFormErr: { ...this.state.signupFormErr, countryCode: "" },
      });
    }
  };

  onChangeInputCountryCodeFunctionTraveller = (value) => {
    var numberPat = /^[0-9]*$/;
    if (typeof value === "number") {
      let val22 = value.toString().includes("+")
        ? value.toString().split("+")[1]
        : value; //.includes('+') ? value.split('+')[1] : value;
      if (val22 !== "") {
        this.setState({
          searchCountryText: "+" + val22,
          signupForm: { ...this.state.signupForm, countryCode: "+" + val22 },
          signupFormErr: { ...this.state.signupFormErr, countryCode: "" },
        });
      }
    } else if (typeof value === "string") {
      let val22 = value.includes("+") ? value.split("+")[1] : value; //.includes('+') ? value.split('+')[1] : value;
      if (typeof val22 === "number") {
        this.setState({
          searchCountryText: "+" + val22,
          signupForm: { ...this.state.signupForm, countryCode: "+" + val22 },
          signupFormErr: { ...this.state.signupFormErr, countryCode: "" },
        });
      } else {
        let val22 = value;
        if (val22 !== "") {
          this.setState({
            searchCountryText: val22,
            signupForm: { ...this.state.signupForm, countryCode: val22 },
            signupFormErr: { ...this.state.signupFormErr, countryCode: "" },
          });
        }
      }
    }
  };
  /**End of new traveller dropdown added for customisation */

  /** start of nationality */
  onClickNationalityHandler = (params) => {
    this.setState({
      selectedNationality: params,
      signupForm: { ...this.state.signupForm, nationality: params.value },
      signupFormErr: { ...this.state.signupFormErr, nationality: "" },
    });
  };

  onChangeInputNationality = (params) => {
    this.setState({
      selectedNationality: { flag: "/images/ax-tick.svg" },
      signupForm: {
        ...this.state.signupForm,
        nationality: params.target.value,
      },
    });
  };

  onClosePopup = () => {
    if (this.state.isSuccessPopupVisible) {
      window.location = "/";
    } else {
      this.props.data.onClickCross();
    }
  };

  onContinueClickHandler = () => {
    window.location = "/";
  };

  getSignUpForm = (data) => {
    console.log(data);
    if (typeof this?.props?.data?.clearDirectOpenLinkPopup === "function") {
      this.props.data.clearDirectOpenLinkPopup();
    }
    this.setState({
      popupType: "SignUp",
      isPasswordRequired: false,
      registrationToken: data.registration_token,
      signupForm: {
        ...this.state.signupForm,
        email: data.email,
        firstName: data?.first_name || "",
      },
    });
  };
  setCaptchDataCallBack = (bool, errorMsg) => {
    this.setState({ isCaptchaSelected: bool, captchErrorMsg: errorMsg });
  };

  renderLoginPopup = (popup) => {
    let styles = this.props.data.logincss;
    switch (popup) {
      case "LogIn":
        return (
          <>
            <div className={styles.personal_login_text}>
              <div
                className={
                  this.state.toggleAgentLogin
                    ? ""
                    : styles.personal_login_tab_active
                }
                onClick={() => {
                  this.setState({ toggleAgentLogin: false });
                }}
              >
                <span>Personal Login</span>
              </div>
              {/* <div className={!this.state.toggleAgentLogin ? "" : styles.personal_login_tab_active} onClick={() => { this.setState({ toggleAgentLogin: true }) }}><span>AGENT LOGIN</span></div> */}
            </div>
            {this.state.toggleAgentLogin ? (
              <>
                <div className="flex justify-between mb-2">
                  <div className={styles.login_by_mail_id_text}>Login</div>
                  <div className="flex flex-col justify-center items-center">
                    <div className={styles.noaccount}>
                      Don't have an account{" "}
                      <span
                        onClick={() => {
                          this.setState({ popupType: "BeforeSignUp" });
                        }}
                      >
                        Sign Up
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.email_error_msg}>
                  {this.state.loginErrorMsg}
                </div>
                <input
                  className={styles.email_id}
                  id="email"
                  type="email"
                  placeholder="Email Id"
                  maxLength="50"
                  value={this.state.logincred.email}
                  onChange={this.onChangeLoginEmail}
                />
                <div className={styles.email_error_msg}>
                  {this.state.loginErrors.email !== ""
                    ? this.state.loginErrors.email
                    : ""}
                </div>
                <input
                  className={styles.email_id}
                  id="password"
                  type="password"
                  placeholder="Password"
                  maxLength="50"
                  value={this.state.logincred.password}
                  onChange={this.onChangeLoginPassword}
                />
                <div className={styles.email_error_msg}>
                  {this.state.loginErrors.password !== ""
                    ? this.state.loginErrors.password
                    : ""}
                </div>
                <div
                  className={styles.forgot_password}
                  onClick={() => {
                    this.props.data.clearDirectOpenLinkPopup();
                    this.setState({
                      popupType: "ForgotPassword",
                      forgotPasswordEmail: this.state.logincred.email,
                    });
                  }}
                >
                  Forgot Password?
                </div>
                <button
                  className={styles.btn_login}
                  onClick={this.onClickLoginBtn}
                >
                  {/* this.state.onClickLoginLoader ? "" : */ "Login"}
                </button>
                {this.state.onClickLoginLoader && (
                  <div className={styles.login_loader} />
                )}
                {/* <div className={styles.policytext}>By proceeding, you agree to Elevate Trips <span> Privacy Policy</span>, <span>User Agreement</span> and <span>T&Cs</span></div> */}
              </>
            ) : (
              <>
                <div className="flex justify-between mb-2">
                  <div className={styles.login_by_mail_id_text}>
                    Login With Email Id
                  </div>
                  {/* <div className='flex flex-col justify-center items-center'>
                                    <span className={styles.new}>New</span>
                                    <button className={styles.viaotp} onClick={() => { this.setState({ popupType: "LoginViaOTP" }) }}>Login With OTP</button>
                                </div> */}
                </div>
                {this.state.loginErrorMsg !== "" && (
                  <div className={styles.email_error_msg}>
                    {this.state.loginErrorMsg}
                  </div>
                )}
                <input
                  className={styles.email_id}
                  id="email"
                  autoComplete="off"
                  type="email"
                  placeholder="EMAIL ID"
                  maxLength="50"
                  value={this.state.logincred.email}
                  onChange={this.onChangeLoginEmail}
                />
                {this.state.loginErrors.email !== "" && (
                  <div className={styles.email_error_msg}>
                    {this.state.loginErrors.email}
                  </div>
                )}
                <input
                  className={styles.email_id}
                  id="password"
                  type="password"
                  placeholder="PASSWORD"
                  autoComplete="new-password"
                  maxLength="50"
                  value={this.state.logincred.password}
                  /* readOnly={this.state.isReadOnly} */ onChange={
                    this.onChangeLoginPassword
                  }
                  /* onClick={() => { this.setState({ isReadOnly: false }) }} onBlur={() => { this.setState({ isReadOnly: true }) }} */
                />
                {this.state.loginErrors.password !== "" && (
                  <div className={styles.email_error_msg}>
                    {this.state.loginErrors.password}
                  </div>
                )}
                <div style={{ marginTop: 20 }}>
                  <span
                    className={styles.forgot_password}
                    onClick={() => {
                      this.props.data.clearDirectOpenLinkPopup();
                      this.setState({
                        popupType: "ForgotPassword",
                        forgotPasswordEmail: this.state.logincred.email,
                      });
                    }}
                  >
                    Forgot Password?
                  </span>
                </div>
                <button
                  className={styles.btn_login}
                  onClick={this.onClickLoginBtn}
                >
                  {/* this.state.onClickLoginLoader ? "" : */ "LOGIN"}
                </button>
                {this.state.onClickLoginLoader && (
                  <div className={styles.login_loader} />
                )}
                <div className={styles.or_login_with}>
                  Or Login/Sign Up With
                </div>
                <FacebookGoogleLogin
                  getSignUpForm={this.getSignUpForm}
                  siteData={this.props.data.siteData}
                />
                <div className={styles.noaccount}>
                  Don't have an account{" "}
                  <span
                    onClick={() => {
                      this.props.data.clearDirectOpenLinkPopup();
                      this.setState({ popupType: "BeforeSignUp" });
                    }}
                  >
                    Sign Up
                  </span>
                </div>
                <div className={styles.policytext}>
                  By proceeding, you agree to Elevate Trips
                  <span
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/Privacy_policy`,
                        "_blank"
                      );
                    }}
                  >
                    {" "}
                    Privacy Policy
                  </span>
                  ,
                  <span
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/User_Agreement`,
                        "_blank"
                      );
                    }}
                  >
                    {" "}
                    User Agreement
                  </span>{" "}
                  and
                  <span
                    onClick={() => {
                      window.open(`${window.location.origin}/tnc`, "_blank");
                    }}
                  >
                    {" "}
                    T&Cs
                  </span>
                </div>
                {/* <div onClick={() => { this.setState({ popupType: "ChangePassword" }) }}>Change Password</div>
                            <div onClick={() => { this.setState({ popupType: "SignUpWithCaptcha" }) }}>Sign Up With Captcha</div>
                            <div onClick={() => { this.setState({ popupType: "SignUp" }) }}>Sign Up</div> */}
              </>
            )}
          </>
        );
      case "BeforeSignUp":
        return (
          <>
            <div className={styles.login_by_mail_id_text}>Sign Up</div>
            <input
              className={styles.email_id}
              id="email"
              type="text"
              placeholder="Email ID"
              autoComplete="off"
              value={this.state.signUpEmail}
              maxLength="50"
              onChange={this.onChangeSignupEmailId}
            />
            <div className={styles.email_error_msg}>
              {this.state.signUpEmailErr !== ""
                ? this.state.signUpEmailErr
                : ""}
            </div>
            {this.props.data.siteData.site.login_captcha_key !== "" &&
              this.props?.data?.siteData?.site?.login_captcha_key !==
                undefined && (
                <CaptchaComponent
                  errorMsg={this.state.captchErrorMsg}
                  setCaptchDataCallback={this.setCaptchDataCallBack}
                  siteData={this.props.data.siteData}
                />
              )}
            <button
              className={styles.btn_login}
              onClick={() => this.onClickSignupBtn()}
            >
              Sign Up
            </button>
            {this.state.activationLink && (
              <div className={styles.cong_text}>
                <span>Congratulations</span> have send activation link on{" "}
                {this.state.signUpEmail}
              </div>
            )}
            <FacebookGoogleLogin
              isSignUp={true}
              getSignUpForm={this.getSignUpForm}
              signupText={"SignUp"}
              siteData={this.props.data.siteData}
            />
            <div className={styles.noaccount}>
              Already have an account{" "}
              <span
                onClick={() => {
                  this.props.data.clearDirectOpenLinkPopup();
                  this.setState({ popupType: "LogIn" });
                }}
              >
                Login
              </span>
            </div>
            <div className={styles.policytext}>
              By proceeding, you agree to Elevate Trips
              <span
                onClick={() => {
                  window.open(
                    `${window.location.origin}/Privacy_policy`,
                    "_blank"
                  );
                }}
              >
                {" "}
                Privacy Policy
              </span>
              ,
              <span
                onClick={() => {
                  window.open(
                    `${window.location.origin}/User_Agreement`,
                    "_blank"
                  );
                }}
              >
                User Agreement
              </span>{" "}
              and
              <span
                onClick={() => {
                  window.open(`${window.location.origin}/tnc`, "_blank");
                }}
              >
                {" "}
                T&Cs
              </span>
            </div>
          </>
        );
      case "SignUpWithCaptcha":
        return (
          <>
            <div className={styles.personal_login_text}>
              <div className={styles.personal_login_tab_active}>
                <span>PERSONAL LOGIN</span>
              </div>
            </div>
            <div className="mb-2">
              <div className={styles.login_by_mail_id_text}>Sign Up</div>
            </div>
            <input
              className={styles.email_id}
              id="email"
              type="email"
              placeholder="EMAIL ID"
              value={this.state.signUpWithCaptchaMail}
              maxLength="50"
              onChange={this.onchangesigninWithCaptchaMail}
            />
            {this.props.data.siteData.site.login_captcha_key !== "" && (
              <CaptchaComponent
                errorMsg={this.state.captchErrorMsg}
                setCaptchDataCallback={this.setCaptchDataCallBack}
                siteData={this.props.data.siteData}
              />
            )}
            <button
              className={styles.btn_login + " !mb-0"}
              onClick={this.onClickCaptchaSignupbtn}
            >
              Sign Up
            </button>
            {this.state.activationLink && (
              <div className={styles.cong_text}>
                <span>Congratulations</span> have send activation link on
                abc@gmail.com
              </div>
            )}
            <div className={styles.or_login_with}>Sign Up With</div>
            <FacebookGoogleLogin
              getSignUpForm={this.getSignUpForm}
              signupText={"SignUp"}
              siteData={this.props.data.siteData}
            />
            <div className={styles.noaccount}>
              Don't have an account{" "}
              <span
                onClick={() => {
                  this.setState({ popupType: "LogIn" });
                }}
              >
                Login
              </span>
            </div>
            <div className={styles.policytext}>
              By proceeding, you agree to Elevate Trips{" "}
              <span> Privacy Policy</span>, <span>User Agreement</span> and{" "}
              <span>T&Cs</span>
            </div>
            <div className="align-center">
              <img
                src={"images/bounz_big.png"}
                alt={"BOUNZ"}
                height="40px"
                width="80px"
                className="object-scale-down mx-auto my-4"
              />
            </div>
            <div className={styles.accept_policy}>
              <label className={styles.checkbox_common}>
                <input
                  type="checkbox"
                  id=""
                  name="accept-checkbox"
                  value="BOUNZ"
                />
                <span className={styles.checkmark}></span>
              </label>
              <label for="accept-checkbox">
                {" "}
                I accept that my details will be sent to BOUNZ to create a new
                account or link with an existing account
              </label>
            </div>
          </>
        );
      case "SignUp":
        return (
          <>
            <div className={styles.singup_header}>
              You are just one step away from
              <br /> activating your Elevate Trips account
            </div>
            {this.state.registerAPIErr !== "" ? (
              <div className={styles.email_error_msg}>
                {this.state.registerAPIErr}
              </div>
            ) : (
              ""
            )}
            <input
              className={styles.email_id}
              type="text"
              placeholder="First Name"
              maxLength="50"
              value={this.state.signupForm.firstName}
              onChange={this.onChangeSignFName}
            />
            {this.state.signupFormErr.firstName !== "" && (
              <div className={styles.email_error_msg}>
                {this.state.signupFormErr.firstName}
              </div>
            )}
            <input
              className={styles.email_id}
              type="text"
              placeholder="Last Name"
              maxLength="50"
              value={this.state.signupForm.lastName}
              onChange={this.onChangeSignLName}
            />
            {this.state.signupFormErr.lastName !== "" && (
              <div className={styles.email_error_msg}>
                {this.state.signupFormErr.lastName}
              </div>
            )}
            <div className={styles.country_drp}>
              <div>
                <IsdCountryDropdown
                  CountryListOptions={this.state.originCountries}
                  selectCountryCode={this.selectCountryCodeFunctionTraveller}
                  searchText={this.state.signupForm.countryCode}
                  selectedCountryInfo={this.state.selectedCountry}
                  onClickInputCountry={this.onClickInputCountryTraveller}
                  onChangeInputCountryCodeFunction={
                    this.onChangeInputCountryCodeFunctionTraveller
                  }
                  onKeyDownCountry={this.onKeyDownCountryCodeTraveller}
                  placeholder={"ISD"}
                  {...this.props.data.ISDstyles}
                  isSignUpCountryCode={true}
                />
                {this.state.signupFormErr.countryCode !== "" && (
                  <div className={styles.email_error_msg}>
                    {this.state.signupFormErr.countryCode}
                  </div>
                )}
              </div>
              <div>
                <input
                  className={styles.email_id}
                  type="text"
                  placeholder="Mobile No."
                  maxLength="22"
                  value={this.state.signupForm.mobileNo}
                  onChange={this.onChangeSignMobile}
                />
                {this.state.signupFormErr.mobileNo !== "" && (
                  <div className={styles.email_error_msg}>
                    {this.state.signupFormErr.mobileNo}
                  </div>
                )}
              </div>
            </div>
            <CountryDropdownComp
              countryFlag={this.state.originCountries}
              searchText={this.state.signupForm.nationality}
              selectCountry={this.onClickNationalityHandler}
              selectedCountry={this.state.selectedNationality}
              onChangeInputCountryCode={this.onChangeInputNationality}
              placeholder={"Select Nationality"}
              onBlur={() => {}}
              {...this.props.data.Countrystyles}
            />
            {this.state.signupFormErr.nationality !== "" && (
              <div className={styles.email_error_msg}>
                {this.state.signupFormErr.nationality}
              </div>
            )}
            <input
              className={styles.email_id}
              id="email"
              type="email"
              autoComplete="off"
              placeholder="Email ID"
              maxLength="50"
              disabled={
                this.props.data.emailId !== "" || this.state.signupForm.email
                  ? true
                  : false
              }
              value={this.state.signupForm.email}
              onChange={this.onChangeSignEmail}
            />
            {this.state.signupFormErr.email !== "" && (
              <div className={styles.email_error_msg}>
                {this.state.signupFormErr.email}
              </div>
            )}
            {this.state.isPasswordRequired ? (
              <>
                <input
                  className={styles.email_id}
                  id="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder="Password"
                  maxLength="50"
                  value={this.state.signupForm.password}
                  onChange={this.onChangeSignPassword}
                />
                {this.state.signupFormErr.password !== "" && (
                  <div className={styles.email_error_msg}>
                    {this.state.signupFormErr.password}
                  </div>
                )}
                <input
                  className={styles.email_id}
                  id="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder="Confirm Password"
                  maxLength="50"
                  value={this.state.signupForm.confirmPassword}
                  onChange={this.onChangeSignCnfPassword}
                />
                {this.state.signupFormErr.confirmPassword !== "" && (
                  <div className={styles.email_error_msg}>
                    {this.state.signupFormErr.confirmPassword}
                  </div>
                )}
              </>
            ) : null}
            <button
              className={styles.btn_login}
              onClick={this.finalSignupClick}
            >
              Sign Up
            </button>
          </>
        );
      case "ForgotPassword":
        return (
          <>
            <div className={styles.login_by_mail_id_text + " " + "my-4"}>
              Forgot Password
            </div>
            <input
              className={styles.email_id + " !mb-0"}
              type="email"
              placeholder="EMAIL ID"
              autoComplete="off"
              maxLength="50"
              onChange={this.onChangeForgotPassEmail}
            />
            <div className={styles.email_error_msg}>
              {this.state.forgotPasswordErr !== ""
                ? this.state.forgotPasswordErr
                : ""}
            </div>
            {!this.state.forgotPassText && (
              <button
                className={styles.btn_login}
                onClick={this.onClickForgotSubimitBtn}
              >
                Submit
              </button>
            )}
            {!this.state.forgotPassText && (
              <div style={{ textAlign: "center" }}>
                <span
                  className={styles.back_to_login}
                  onClick={() => {
                    this.setState({ popupType: "LogIn" });
                  }}
                >
                  Back to Login
                </span>
              </div>
            )}
            {this.state.forgotPassText && (
              <div className={styles.link_to_email}>
                {
                  "Reset password verification link successfully sent on registered email id"
                }
              </div>
            )}
            {this.state.forgotPassText && (
              <div className={styles.valid_link}>
                Verification Link Valid for <span>24 hours</span>
              </div>
            )}
          </>
        );
      case "VerifyEmailId":
        return (
          <>
            <div
              className={styles.back}
              onClick={() => {
                this.setState({ popupType: "LogIn" });
              }}
            >
              Back
            </div>
            <div className={styles.login_by_mail_id_text}>
              Verify Your Email ID
            </div>
            <div className={styles.otp_to_email}>
              OTP has been sent to Email ID:{" "}
              <span>{this.state.loginWithOTPEmail}</span>
            </div>
            <div className="relative w-full">
              <input
                className={styles.email_id + " !mb-0"}
                id="email"
                type="email"
                placeholder="OTP"
                maxLength="50"
                onChange={props.onChangeEmailId}
              />
              <span className={styles.resend_otp}>Resend OTP</span>
            </div>
            <div className={styles.email_error_msg}>{props.OTPErrorMsg}</div>
            <button className={styles.btn_login} onClick={props.onOTPSignUp}>
              Signup
            </button>
            <div className={styles.or_login_with}>or Signup Via</div>
            <FacebookGoogleLogin
              getSignUpForm={this.getSignUpForm}
              signupText={"SignUp"}
              siteData={this.props.data.siteData}
            />
          </>
        );
      case "ChangePassword":
        return (
          <ChangePassword
            cssfile={this.props.data.logincss}
            onClickBackLink={() => {
              this.setState({ popupType: "LogIn" });
            }}
            onClickConfirmbtn={this.onClickConfirmbtn}
            newPasswordErr={this.state.passwordObjErr.newPassword}
            confirmPasswordErr={this.state.passwordObjErr.confirmPassword}
            changePasswordAPIErr={this.state.changePasswordAPIErr}
            onChangeNewPassword={(e) => {
              this.setState({
                passwordObj: {
                  ...this.state.passwordObj,
                  newPassword: e.target.value,
                },
                passwordObjErr: {
                  ...this.state.passwordObjErr,
                  newPassword: "",
                },
              });
            }}
            onChangeConfirmPassword={(e) => {
              this.setState({
                passwordObj: {
                  ...this.state.passwordObj,
                  confirmPassword: e.target.value,
                },
                passwordObjErr: {
                  ...this.state.passwordObjErr,
                  confirmPassword: "",
                },
              });
            }}
          />
        );
      case "PasswordUpdatedSuccessfully":
        return (
          <>
            <div
              className={styles.back}
              onClick={() => {
                /* this.setState({ popupType: "LogIn" }) */ window.location.href =
                  "/";
              }}
            >
              Back
            </div>
            <div
              className={styles.login_by_mail_id_text + " " + "!text-center"}
            >
              Password Updated
            </div>
            <div className={styles.pswd_success}>
              We successfully changed your password.
            </div>
            <button
              className={styles.btn_login}
              onClick={() => {
                /* this.setState({ popupType: "LogIn" }) */ window.location.href =
                  "/";
              }}
            >
              Back to Login
            </button>
          </>
        );
      case "LoginViaOTP":
        return (
          <>
            <div className={styles.personal_login_text}>
              <div className={styles.personal_login_tab_active}>
                <span>PERSONAL LOGIN</span>
              </div>
            </div>
            <div className="mb-2">
              <div className={styles.login_by_mail_id_text}>Login Via OTP</div>
            </div>
            <input
              className={styles.email_id}
              type="email"
              placeholder="Email ID"
              maxLength="50"
              value={this.state.loginWithOTPEmail}
              onChange={this.onChangeLoginOTPEmail}
            />
            <div className={styles.email_error_msg}>
              {this.state.loginOTPEmailErr !== ""
                ? this.state.loginOTPEmailErr
                : ""}
            </div>
            <button className={styles.btn_login} onClick={this.onClickSendOTP}>
              Send OTP
            </button>
            <div className={styles.or_login_with}>Login With</div>
            <FacebookGoogleLogin
              getSignUpForm={this.getSignUpForm}
              siteData={this.props.data.siteData}
            />
            <div className={styles.noaccount}>
              Don't have an account{" "}
              <span
                onClick={() => {
                  this.setState({ popupType: "BeforeSignUp" });
                }}
              >
                Signup
              </span>
            </div>
            <div className={styles.policytext}>
              By proceeding, you agree to Elevate Trips{" "}
              <span> Privacy Policy</span>, <span>User Agreement</span> and{" "}
              <span>T&Cs</span>
            </div>
          </>
        );
      case "VerifyOTP":
        return (
          <>
            <div
              className={styles.back}
              onClick={() => {
                this.setState({ popupType: "LogIn" });
              }}
            >
              Back
            </div>
            <div className={styles.login_by_mail_id_text}>Verify OTP</div>
            <div className={styles.otp_to_email}>
              OTP has been sent to Email ID:{" "}
              <span>{this.state.loginWithOTPEmail}</span>
            </div>
            <div className="relative w-full">
              <input
                className={styles.email_id + " !mb-0"}
                type="text"
                placeholder="OTP"
                maxLength="6"
                onChange={(e) => {
                  this.setState({
                    verifyOTPEmail: e.target.value,
                    verifyOTPEmailErr: "",
                  });
                }}
              />
              <span className={styles.resend_otp}>Resend OTP</span>
            </div>
            <div className={styles.email_error_msg}>
              {this.state.verifyOTPEmailErr}
            </div>
            <button className={styles.btn_login} onClick={this.onOTPLogin}>
              Login
            </button>
            <div className={styles.or_login_with}>or Login With</div>
            <FacebookGoogleLogin
              getSignUpForm={this.getSignUpForm}
              siteData={this.props.data.siteData}
            />
          </>
        );
      case "SignUpSuccessful":
        return (
          <div
            className={`${styles.SignUpSuccessful} ${styles.SignUpSuccessful_mob}`}
          >
            <img src={"images/complete_tick.svg"} alt="" />
            <div className={styles.SignUpSuccessful_inner}>
              <div className={styles.sucess_signup}>Success</div>
              <span>Congratulations !! </span>
              your registration was successful on Elevate Trips..
            </div>
            <button
              className={styles.continue_btn}
              onClick={this.onContinueClickHandler}
            >
              {"CONTINUE".toUpperCase()}
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <ModalPopupComponent
        isMobile={this.props.data.isMobile}
        isPopup={this.state.headerPopup}
        onClosePopup={this.onClosePopup}
        modalCss={this.props.data.modalCss}
      >
        {this.renderLoginPopup(
          this.state.isSuccessPopupVisible
            ? "SignUpSuccessful"
            : this.props.data.directOpenLinkPopup
            ? this.props.data.directOpenLinkPopup
            : this.state.popupType
        )}
      </ModalPopupComponent>
    );
  }
}
