import React from 'react'
import ReactFacebookLogin from 'react-facebook-login';
import { facebooklogin } from '../../../../lib/facebooklogin.js';
import config from '../../../../../config/config.json'

const responseFacebook = (response) => {
    console.log('Facebook response', response);
    let fbrequest = {
        "token": response.accessToken,
        "id": response.id,
        "first_name": response.name,
        "email": response.email,
        "image": response.picture && response.picture.data && response.picture.data.url ? response.picture.data.url : ""
    }
    facebooklogin(fbrequest).then(respons => {
        console.log("api respons", respons);
        if (response.status) {
            location.reload()
        }
    })
}

const FacebookLoginComponent = (props) => {
    // console.log(props,"facebooklogin");
    return (
        <ReactFacebookLogin
            // appId="358159989305248"
            textButton={props.btnText}
            // appId={config.FB_APP_ID}
            appId={props.siteData.facebook_login_key}
            autoLoad={false}
            cssClass={props.style}
            fields="name,email,picture"
            scope="public_profile,email,user_friends"
            callback={responseFacebook}
            icon={<img src={props.imageSrc} alt=""/>} />
    )
}

export default FacebookLoginComponent