import React, { Component } from "react";
import Button from "../UI/button/index";
import commonFunction from "../../lib/utils/common";
class GoogleTranslateWithRadioButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scriptLoaded: false,
      selectedLang: "",
      site_multi_languages: [],
      lang_codes: "",
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.isHomePage) {
      this.setState({
        site_multi_languages: this.props.site_multi_languages,
        lang_codes: this.props.site_multi_languages
          .map((lang) => lang.code)
          .join(","),
      });
    }
    let googtransCookie = commonFunction.getCookie("googtrans");
    // if (googtransCookie == "") {
    //   commonFunction.setCookie("googtrans", "/en/en", 1);
    // }
    let language = googtransCookie != "" ? googtransCookie.split("/")[2] : "en";
    this.setState({ selectedLang: language }, () => {
      this.checkSelectedLang(this.state.selectedLang);
    });
    this.loadGoogleTranslateScript();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target) &&
      !this.props.isMobile &&
      this.props.isHomePage
    ) {
      this.props.closeTranslationModal();
    }
  };

  loadGoogleTranslateScript = () => {
    if (window.google && window.google.translate) {
      this.initializeGoogleTranslate();
    } else {
      const script = document.createElement("script");
      script.src =
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      script.onload = () => this.initializeGoogleTranslate();
      document.body.appendChild(script);
    }
  };

  initializeGoogleTranslate = () => {
    if (!window.google || !window.google.translate) {
      console.error("Google Translate API is not available.");
      return;
    }

    window.googleTranslateElementInit = () => {
      try {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: this.state.lang_codes, // 'en,hi,mr,bn',
            autoDisplay: false,
          },
          "google_translate_element"
        );
        this.setState({ scriptLoaded: true }, this.applyCustomStyles);
      } catch (error) {
        console.error("Error initializing Google Translate:", error);
      }
    };

    if (
      window.google &&
      window.google.translate &&
      window.googleTranslateElementInit
    ) {
      window.googleTranslateElementInit();
    }
  };

  applyCustomStyles = () => {
    setTimeout(() => {
      const style = document.createElement("style");
      style.innerHTML = `
        /* Hide Google Translate iframe */
        .skiptranslate, .goog-te-gadget {
          display: none !important;
        }
        /* Hide the Google Translate toolbar */
        #goog-gt-vt {
          display: none !important;
        }
        /* Hide the translation popup */
        .goog-te-banner-frame {
          display: none !important;
        }
        /* Hide the Google Translate loader */
        .goog-te-spinner, .goog-te-spinner-icon, .goog-te-spinner-text {
          display: none !important;
        }
        /* Adjust the content to fit the viewport */
        body {
          top: 0 !important;
        }
      `;
      document.head.appendChild(style);
    }, 10);
  };

  translatePage = (languageCode) => {
    if (!this.state.scriptLoaded) return;
    const intervalId = setTimeout(() => {
      const selectField = document.querySelector(
        "#google_translate_element select"
      );
      if (selectField) {
        selectField.value = languageCode;
        const event = new Event("change", { bubbles: true });
        selectField.dispatchEvent(event);
        clearTimeout(intervalId);
      }
    }, 10);
  };

  onSelectLang = (lang) => {
    this.setState({ selectedLang: lang }, () => {
      if (!this.props.isMobile) {
        this.applyTranslation(lang);
      }
    });
  };

  handleApplyButtonClick = () => {
    this.applyTranslation(this.state.selectedLang);
  };

  applyTranslation = (lang) => {
    const host = window.location.host;
    commonFunction.deleteCookie("googtrans");
    commonFunction.deleteCookie("googtrans", ".vetravel.io");
    commonFunction.deleteCookie("googtrans", host);
    commonFunction.deleteCookie("googtrans", host.replace('www', ''));
    commonFunction.setCookie("googtrans", "/en/" + lang, 1, "/", "", true);
    //this.translatePage(lang);
    window.location.reload();
  };

  checkSelectedLang = (lang) => {
    return this.state.selectedLang === lang;
  };

  render() {
    return (
      <>
        {(this.props.isHomePage || this.props.isMobile) && (
          <div
            ref={this.containerRef}
            className={this.props.style.translatelanguage}
          >
            <div className={this.props.style.translatelanguage_row1}>
              <div className={this.props.style.translatelanguage_list}>
                <h6>Choose Country</h6>
                <div className={this.props.style.translatelanguage_box}>
                  <img
                    src="/images/india-flag.png"
                    alt="flag"
                    width="20px"
                    height="20px"
                  />
                  <span>India</span>
                </div>
              </div>
              <div className={this.props.style.translatelanguage_list}>
                <h6>Choose Currency</h6>
                <div className={this.props.style.translatelanguage_box}>
                  <span>₹ INR</span>
                </div>
              </div>
            </div>
            {this.props.isHomePage &&
              <>
                <div className={this.props.style.translatelanguage_row2}>
                  <h6>Choose Language</h6>
                  <div className={this.props.style.translatelanguage_overflow}>
                    {this.state.site_multi_languages.map((lang) => (
                      <label
                        key={lang.code}
                        className={
                          this.checkSelectedLang(lang.code)
                            ? this.props.style.translatelanguage_active
                            : ""
                        }
                        onClick={() => this.onSelectLang(lang.code)}
                      >
                        <input
                          type="radio"
                          name="language"
                          value={lang.code}
                          // onChange={() => this.onSelectLang(lang.value)}
                          checked={this.checkSelectedLang(lang.code)}
                        />
                        {lang.name}
                      </label>
                    ))}
                  </div>
                </div>
                {this.props.isMobile && (
                  <div className="">
                    <Button
                      className={this.props.style.translate_button}
                      isHandleOnClick={this.handleApplyButtonClick}
                    >
                      Apply
                    </Button>
                  </div>
                )}

                <div
                  id="google_translate_element"
                  style={{ display: "none" }}
                ></div>
              </>
            }
          </div>
        )}
      </>
    );
  }
}

export default GoogleTranslateWithRadioButtons;
