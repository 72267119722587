import CountryDropdown1 from "./theme1";
import CountryDropdown2 from "./theme2/index";
import CountryDropdown3 from "./theme3/index";
import CountryDropdown4 from "./theme4/index";

export default function CountryDropdown(props){
    const { theme } = props
    switch (theme) {
      case 'theme1':
          return <CountryDropdown1 {...props}/>
      case 'theme2':
          return <CountryDropdown2 {...props}/>
      case 'theme3':
          return <CountryDropdown3 {...props}/>
     case 'theme4':
        return <CountryDropdown4 {...props}/>    
      default:
          return <CountryDropdown1 {...props}/>
    }
}



