import React, { Component, Fragment } from "react";
import Link from "../../../Link";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";

class Footer1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: "",
      linksData: [],
    };
  }

  // const footerPaymentCard = [""
  //     { title: 'visa', imgSrc: "../images/visa.png" },
  //     { title: 'master', imgSrc: "../images/mastercard.png" },
  //     { title: 'discover', imgSrc: "../images/discover.png" },
  //     { title: 'american express', imgSrc: "../images/americanexpress.png" },
  //     { title: 'union pay', imgSrc: "../images/union-pay-01.png" },

  // ]
  // fna=()=>{
  //     console.log("props ka pointer",this.props);
  // }

  onChangeEmail = (data) => {
    this.setState({
      emailId: data.target.value,
    });
  };
  componentDidMount() {
    if (
      !commonFunction.isValueEmpty(this.props) &&
      !commonFunction.isValueEmpty(this.props.data)
    ) {
      if (
        !commonFunction.isValueEmpty(this.props.data.footermenu) &&
        !commonFunction.isValueEmpty(this.props.data.footermenu.menu)
      ) {
        this.setState({ linksData: this.props?.data?.footermenu?.menu });
      }
    }
    console.log("footer props", this.props);
  }
  render() {
    return (
      <footer className={this.props?.data?.cssfile?.footer_main}>
        <div className="flex flex-row justify-between items-start mx-auto">
          <div className={this.props?.data?.cssfile?.footer_new}>
            <div className={this.props?.data?.cssfile?.child_divEmpty}></div>

            <div className={this.props?.data?.cssfile?.section_mainChild}>
              <div
                className={`${this.props?.data?.cssfile?.child_divFlx} container mx-auto px-4 xl:px-8`}
              >
                <div className={this.props?.data?.cssfile?.footer_Mainsection}>
                  {this.state.linksData?.map((link) => {
                    return link.active == "active" ? (
                      <>
                      {link.text.toLowerCase() != 'flights' ? (
                        <Link key={link.text} href={link.url} className="">
                          {link.text}
                        </Link>
                      ) : (
                        <a key={link.text} href={this.props?.data?.isMobile ?"/flights":"/"} target="_blank" className="">{link.text}</a>
                      )
                      }
                      </>

                    ) : (
                      ""
                    );
                  })}
                </div>

                <div
                  className={this.props?.data?.cssfile?.footer_Socialsection}
                >
                  {!commonFunction.isValueEmpty(this.props) &&
                    !commonFunction.isValueEmpty(this.props.data) &&
                    !commonFunction.isValueEmpty(
                      this.props?.data?.footermenu
                    ) &&
                    !commonFunction.isValueEmpty(
                      this.props?.data?.footermenu?.socialmedia
                    ) &&
                    this.props?.data?.footermenu?.socialmedia.map(
                      (sociallink, index) => (
                        <Fragment key={"socialmedia" + index}>
                          {sociallink.active == "active" ? (
                            <Link
                              key={sociallink.text}
                              href={sociallink.url}
                              className=""
                            >
                              <img
                                src={`${
                                  "/shared/api/media/" +
                                  this.props?.data?.siteData?.site.id +
                                  "/" +
                                  sociallink?.icon
                                }`}
                                alt="social"
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </Fragment>
                      )
                    )}
                </div>
              </div>

              <div className={this.props?.data?.cssfile?.child_div}>
                <div className={this.props?.data?.cssfile?.copyright}>
                  {/* All rights reserved © Vernost Marketing Technology Solutions Pvt. Ltd.| www.vernost.com */}
                  <span>{this.props?.data?.footermenu?.text}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={this.props?.data?.cssfile?.footer_logo}>
            <input
              type="image"
              src={`${
                
                "/shared/api/media/" +
                this.props?.data?.siteData?.site.id +
                "/" +
                this.props?.data?.footermenu?.logo
              }`}
              alt="Logo"
            />

            <span>{this.props?.data?.footermenu?.text}</span>
          </div>
          <div className={this.props?.data?.cssfile?.footer_links}>
            {this.state.linksData?.map((link) => {
                return (
                  link.active == "active"?
                  <Link
                        key={link.text}
                        href={link.url}
                        className=""
                      >
                        {link.text}
                      </Link>                
                 : ""
                );
              })}
          </div> */}
          {/* <div className={this.props?.data?.cssfile?.footer_send_email}>
            <div className={this.props?.data?.cssfile?.email_block}>
              <label className="">Signup for our Newsletter</label>
              <input
                type="email"
                placeholder="Your email address"
                value={this.state.emailId}
                onChange={(e) => {
                  this.onChangeEmail(e);
                }}
              />
              <button>Submit</button>
          
            </div>
            <div className={this.props?.data?.cssfile?.social_media}>
              {!commonFunction.isValueEmpty(this.props) &&
                !commonFunction.isValueEmpty(this.props.data) &&
                !commonFunction.isValueEmpty(this.props?.data?.footermenu) &&
                !commonFunction.isValueEmpty(
                  this.props?.data?.footermenu?.socialmedia
                ) &&
                this.props?.data?.footermenu?.socialmedia.map(
                  (sociallink, index) => (
                    <Fragment key={"socialmedia" + index}>
                       {sociallink.active == "active"?
                      <Link
                        key={sociallink.text}
                        href={sociallink.url}
                        className=""
                      >
                      
                        <img
                          src={`${
                            
                            "/shared/api/media/" +
                            this.props?.data?.siteData?.site.id +
                            "/" +
                            sociallink?.icon
                          }`}
                          alt="social"
                        />
                      </Link> : ""}
                    </Fragment>
                  )
                )}
            </div>

            <div className={this.props?.data?.cssfile?.payment_card}>
           
            </div>
          </div> */}
        </div>
      </footer>
    );
  }
}

export default Footer1;
