
import commonFunction from '../../lib/utils/common';


const loginB2B = (props)=> {
//  console.log(props,"propsnewwwwww");
try {
   let userToken = commonFunction.getCookieTocken("userToken");
   // console.log(userToken,"userToken");
   if (props?.props?.home?.data?.site?.business_b2b && !userToken ) {
      window.location.pathname != "/login" ? window.location.href = "/login" : null;
   }
} catch (error) {
   console.log("login b2b error",error);
}

}
export default loginB2B;