import Theme1 from "./theme1"
import Theme4 from "./theme4"
import MobileTheme4 from './theme4/mobile'
// import Theme2 from "./theme2";

export function AssistedBooking(props) {
    const { theme } = props
    switch (theme) {
        case 'theme1':
            return <Theme1 {...props} />
        case 'theme4':
            // if(props.isMobile) {
            //     return <MobileTheme4 {...props} />
            // } else {
            //     return <Theme4 {...props} />
            // }
            return <Theme4 {...props} />
        default:
            return <Theme1 {...props} />
    }
}

export default AssistedBooking;