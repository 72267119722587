
function chat(chatObj, device = false) {
    let show = device ? chatObj?.is_mob : chatObj?.is_web
    try {
        if (chatObj?.active && show) {
            let chat_script = chatObj?.script;
            let v = chat_script.replace(/\\/g, '') //to remove backslashes;
            let striped = v.replace(/<[^>]+>/g, ''); //to remove script tag;
            eval(striped)
        }


    } catch (error) {
        console.log('CHAT SCRIPT ERROR (NEED TO CHANGE)', error)
    }
}
export default chat;