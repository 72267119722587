import React, { Component } from "react";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";
import { logout } from "../../../../shared/lib/logout";
import Menu from "../../Menu/index";
import {
  setTockenInCoockie,
  getCookieTocken,
  removeUserTokenFromCookie,
} from "../../login/userToken";
import { checksession } from "../../../../shared/lib/checksession";
import Link from "../../../Link";
import chat from "../../../lib/chat";

// let MenuList=[
//     {name:'My Profile',href:'profile', activeimg:'/images/my-profile-g.svg',inactiveimg:'/images/my-profile-g.svg'},
//     {name:'My Booking',href:'mybooking',activeimg:'/images/my-booking-g.svg',inactiveimg:'/images/my-booking-g.svg'},
//     {name:'Guest & Traveler',href:'guesttraveler', activeimg:'/images/guestTravel-g.svg',inactiveimg:'/images/guestTravel-g.svg/images/prefernce-g.svg'},
//     {name:'Travel Preferences',href:'travelpreferences', activeimg:'/images/prefernce-g.svg',inactiveimg:'/images/prefernce-g.svg'},
//     {name:'Communication Preferences',href:'compreferences', activeimg:'/images/prefernce-g.svg',inactiveimg:'/images/prefernce-g.svg'},
//     {name:'My Frequent Flyer',href:'frequentflyer', activeimg:'/images/flyer-g.svg',inactiveimg:'/images/flyer-g.svg'},
//     {name:'Email Notifications',href:'emailnotifications', activeimg:'/images/Email-notifications-g.svg',inactiveimg:'/images/Email-notifications-g.svg'},
//     {name:'Change Password',href:'changepassword', activeimg:'/images/changePwd-g.svg',inactiveimg:'/images/changePwd-g.svg'},
//     {name:'Logout',href:'Logout', activeimg:'/images/Logout-g.svg',inactiveimg:'/images/Logout-g.svg'}
//   ]

let MenuList = [
  {
    Linkindex: 0,
    isPartition: false,
    linkItem: "My Profile",
    isActiveLink: true,
    componentName: "ProfileForm",
    imgInActiveSrc: "/images/my-profile-g.svg",
    imgActiveSrc: "/images/my-profile-g.svg",
    href: "profile",
  },
  {
    Linkindex: 1,
    isPartition: false,
    linkItem: "My Booking",
    isActiveLink: false,
    componentName: "BookingForm",
    imgInActiveSrc: "/images/my-booking-g.svg",
    imgActiveSrc: "/images/my-booking-g.svg",
    href: "mybooking",
  },
  {
    Linkindex: 2,
    isPartition: false,
    linkItem: "Guest & Traveler",
    isActiveLink: false,
    componentName: "GuestTravelerForm",
    imgInActiveSrc: "/images/guestTravel-g.svg",
    imgActiveSrc: "/images/guestTravel-g.svg",
    href: "guesttraveler",
  },
  {
    Linkindex: 3,
    isPartition: false,
    linkItem: "Travel Preferences",
    isActiveLink: false,
    componentName: "TravelPreferencesForm",
    imgInActiveSrc: "/images/prefernce-g.svg",
    imgActiveSrc: "/images/prefernce-g.svg",
    href: "travelpreferences",
  },
  // { Linkindex: 4, isPartition: false, linkItem: 'Communication Preferences', isActiveLink: false, componentName: 'CompreferencesForm', imgInActiveSrc: '/images/prefernce-g.svg', imgActiveSrc: '/images/prefernce-g.svg', href:'compreferences' },
  // { Linkindex: 5, isPartition: false, linkItem:'Coupons & More', isActiveLink: false,  componentName: 'CouponsMoreForm', imgInActiveSrc: '/images/coupon-g.svg', imgActiveSrc: '/images/coupon-g.svg' },
  // { Linkindex: 6, isPartition: false, linkItem: 'My Frequent Flyer', isActiveLink: false, componentName: 'FrequentFlyerForm', imgInActiveSrc: '/images/flyer-g.svg', imgActiveSrc: '/images/flyer-g.svg', href: 'frequentflyer' },
  // { Linkindex: 7, isPartition: false, linkItem:'My Wallet', isActiveLink: false,  componentName: 'WalletForm', imgInActiveSrc: '/images/wallet-g.svg', imgActiveSrc: '/images/wallet-g.svg' },
  // { Linkindex: 8, isPartition: false, linkItem:'Payments & More', isActiveLink: false,  componentName: 'PaymentsMoreForm', imgInActiveSrc: '/images/payment-g.svg', imgActiveSrc: '/images/payment-g.svg' },
  // { Linkindex: 9, isPartition: false, linkItem: 'Email notifications', isActiveLink: false, componentName: 'EmailNotificationsForm', imgInActiveSrc: '/images/Email-notifications-g.svg', imgActiveSrc: '/images/Email-notifications-g.svg',href:'emailnotifications' },
  // { Linkindex: 10, isPartition: false, linkItem: 'Change Password', isActiveLink: false, componentName: 'ChangePasswordForm', imgInActiveSrc: '/images/changePwd-g.svg', imgActiveSrc: '/images/changePwd-g.svg', href: 'changepassword' },
  // { Linkindex: 11, isPartition: false, linkItem: 'Logout', isActiveLink: false, componentName: 'Logout', imgInActiveSrc: '/images/Logout-g.svg', imgActiveSrc: '/images/Logout-g.svg', href: 'Logout' },
];

class MobileHeader1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToken: "",
      isShowPopup: false,
      userFirststname: "",
      open: false,
    };
  }
  componentDidMount() {
    /* this.setState({
            newToken: getCookieTocken("userToken") !== undefined && getCookieTocken("userToken") !== null && getCookieTocken("userToken") !== "" && getCookieTocken("userToken")
        }/* , () => {

            this.state.newToken && this.checkSessionApi()
        } )*/
    // console.log("this.props.data.user", this.props.data.user);
    chat(this.props?.data?.siteData?.site?.chat, this.props?.data?.isMobile);
    if (this.props?.data?.user?.status) {
      this.setState({
        userFirststname: this.props?.data?.user?.first_name
          ? this.props?.data?.user?.first_name.charAt(0)
          : this.props?.data?.user?.email.charAt(0),
        newToken: this.props?.data?.user?.accessToken || "",
      });
    } else {
      this.setState({
        userFirststname: "",
        newToken: "",
      });
    }

    // console.log("newTokennewTokennewTokennewToken",this.state.newToken)
  }
  checkSessionApi = () => {
    // console.log("checkSessionApicheckSessionApi called")
    // let checksessionreq = {
    //     "site_id": "6124e1368ede4f08bf2d9ad4",
    //     "accessToken": this.state.newToken
    // }
    // checksession(checksessionreq).then((responseJSON) => {
    //     if (responseJSON.status === true && !commonFunction.isValueEmpty(responseJSON.first_name)) {
    //         let firstChar = responseJSON.first_name.charAt(0)
    //         this.setState({
    //             userFirststname: firstChar
    //         }, () => {
    //         })
    //     } else {
    //     }
    // }).catch(err => {
    //     console.log("error", err)
    // })
  };
  onClickArrow = () => {
    this.setState({
      isShowPopup: !this.state.isShowPopup,
    });
  };
  onMouseLeave = () => {
    // console.log("onMouseLeaveonMouseLeave")
    this.setState({
      isShowPopup: false,
    });
  };

  // componentWillReceiveProps(state, props) {
  //     this.setState({
  //         newToken: getCookieTocken("userToken") !== undefined && getCookieTocken("userToken") !== null && getCookieTocken("userToken") !== "" && getCookieTocken("userToken")
  //     }, () => {
  //         this.state.newToken && this.checkSessionApi()
  //     })
  // }
  onClickProfile = () => {
    commonFunction.redirectTo("/myprofile");
  };
  onClickLogout = () => {
    // let logoutreq = {
    //     "site_id": "6124e1368ede4f08bf2d9ad4",
    //     "accessToken": this.state.newToken
    // }
    logout()
      .then((responseJSON) => {
        if (
          responseJSON.status &&
          responseJSON.message === "Logout successfull"
        ) {
          this.setState({
            newToken: "",
            userFirststname: "",
          });
          location.reload();
          // commonFunction.redirectTo('/')
          // } else {
          //     this.setState({});
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
    // this.setState({
    // isToken=''
    // })
    // this.props.data.setLoginTokenData=""
  };

  onClickMenu = (bool) => {
    this.setState({ open: bool });
  };

  onClickTab = (id) => {
    // console.log("id=========>", id);
    let newdata = MenuList.map((fact, index) => {
      if (id == index) {
        let data = {
          ...fact,
          active: true,
        };
        return data;
      } else {
        let data = {
          ...fact,
          active: false,
        };
        return data;
      }
    });
    MenuList = newdata;
    this.setState({ open: false });
  };
  render() {
    // console.log("mobile heder=====>", this.props);
    return (
      <header className="mobile-header">
        {/* <div>Mobile Header
            <div className={props.data.cssfile.main_logo_header}>
                <div className={props.data.cssfile.logo_header}>
                <img  className={props.data.cssfile.header_logo} src={`${'/shared/api/media/6124e1368ede4f08bf2d9ad4/'+props.data.headermenu.logo}`} alt="logo" />

                </div>
                </div>

                <div className={styles.logosub_header}>
                    <img className={styles.search_icon} src="../images/searchOption.png" />
                    <img className={styles.call_icon} src="./images/callOption.png" />
                    <img className={styles.login_icon} src="../images/profileOption.png" />

                </div>
            </div> */}
        <div className={this.props.data.cssfile.main_logo_header}>
          <div className={this.props.data.cssfile.logo_header}>
            {/* <h2>Theme2</h2> */}
            {/* <a href=onClick={() => { window.location.href = '/' }}> <img className={this.props.data.cssfile.header_logo} src={`${ '/shared/api/media/6124e1368ede4f08bf2d9ad4/' + this.props.data.headermenu.logo}`} alt="logo" />
                        </a> */}
            {/* {console.log('demo',this.props.data.headermenu.menu)} */}
            <Link
              key={""}
              href={"/"}
              // onclick="window.location.href='/'"
              //onClick={() => { window.location.href = this.props.data.headermenu.supportlink }}
              className=""
            >
              <h1>
                <img
                  className={this.props?.data?.cssfile?.header_logo}
                  src={`${
                    "/shared/api/media/" + this.props.data.headermenu.logo
                  }`}
                  alt="logo"
                />
              </h1>
              {/* <img className={this.props?.data?.cssfile?.header_logo} src={`${ '/shared/api/media/' + this.props.data.headermenu.logo}`} alt="logo" /> */}
            </Link>
            {/* <input type="image" className={this.props.data.cssfile.logo_mob} src="../images/logoele.png" alt="" /> */}
          </div>
          <div className={this.props.data.cssfile.logosub_header}>
            {/* <img className={this.props.data.cssfile.search_icon} src="../images/searchOption.png" /> */}
            <a href="tel:+971 58 953 9988">
              <h2>
                <img
                  className={this.props.data.cssfile.call_icon}
                  src="images/call_-01.svg"
                  alt="Call"
                />
              </h2>
            </a>
            {/* <img className={this.props.data.cssfile.login_icon} src="../images/profileOption.png" onClick={this.props.data.onClickHeaderLogin} /> */}
            {this.state.newToken != undefined &&
            this.state.newToken != null &&
            this.state.newToken != "" ? (
              <div className={this.props.data.cssfile.afterlogin}>
                <span
                  className={this.props.data.cssfile.user_code}
                  onClick={() => {
                    this.onClickMenu(!this.state.open);
                  }}
                >
                  {this.state.userFirststname}
                </span>
              </div>
            ) : (
              <span
                className={this.props.data.cssfile.login_btn}
                onClick={this.props.data.onClickHeaderLoginB2C}
              >
                <img
                  className={this.props.data.cssfile.login_icon}
                  src="images/user-login-mob1.svg"
                  onClick={this.props.data.onClickHeaderLoginB2C}
                  alt=""
                />
              </span>
            )}
            <Menu
              clickedTab={this.onClickTab}
              onclickLogout={this.onClickLogout}
              styles={this.props.data.menucssfile}
              isopen={this.state.open}
              MenuList={MenuList}
              id={"main-menu"}
              pathname={
                this.props.data.pathname === "/myprofile"
                  ? new URLSearchParams(window.location.search).get(
                      "Linkindex"
                    ) !== null
                    ? this.props.data.pathname
                    : "myprofile"
                  : this.props.data.pathname
              }
              closeMenusHandler={this.onClickMenu}
            />

            {/* {(this.state.newToken != undefined && this.state.newToken != null && this.state.newToken != "") ?
                        <div className={this.props.data.cssfile.afterlogin}> <span className={this.props.data.cssfile.user_code}>{this.state.userFirststname}</span>
                            <span onClick={() => { this.onClickArrow() }} >
                                <img src="https://uat.elevatetrips.com/image/down_arrow.jpg" alt="" />
                            </span>

                            <div className={this.state.isShowPopup ? this.props.data.cssfile.show : this.props.data.cssfile.profile_details_hide} onMouseLeave={() => { this.onMouseLeave() }}>
                                <span onClick={() => { this.onClickProfile() }}> <img type="image" src="https://www.intermilesresources.com/sharedresources/img/svg/flyout/links/myprofile.svg" alt="" />My Profile</span>
                                <span onClick={() => { this.onClickLogout() }}> <img type="image" src="https://www.intermilesresources.com/sharedresources/img/svg/flyout/links/logout.svg" alt="" />Logout</span>
                                <span> <img type="image" src="https://uat.elevatetrips.com/image/et-support.png" alt="" />Support</span>

                            </div>
                        </div> : 
                        <span className={this.props.data.cssfile.login_btn} onClick={this.props.data.onClickHeaderLogin}>
                            <img className={this.props.data.cssfile.login_icon} src="../images/profileOption.png" onClick={this.props.data.onClickHeaderLogin} />

                        </span>} */}
          </div>
        </div>
      </header>
    );
  }
}

export default MobileHeader1;
