import { Skeleton } from '@mui/material';
import styles from './index.module.css';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import config from '../../skeletonConfig/config.json';

const CoomonSkeleton = (props) => {
  const [progress, setProgress] = useState(0);
  const [theme, setTheme] = useState('');

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         return 0
  //       }
  //       const diff = 2
  //       return Math.min(oldProgress + diff, 100)
  //     })
  //   }, 700)

  //   return () => {
  //     clearInterval(timer)
  //   }
  // }, [])

  useEffect(() => {
    for(let [key, value] of Object.entries(config)) {
      if(window.location.href == key || window.location.href.includes('appType=mobileapp')) setTheme(value)
    }
  }, [theme]);

  switch(theme) {
    case 'theme5':
      return (
        <>
        {!isMobile ?
        (
          <div className={styles.SkeletonMain_Page} >
            <div className={styles.ax_slideparents}>
              <Skeleton variant="rect" width="100%" height={80} style={{ 'marginBottom': '100px' }} />
            </div>
            <div className={styles.searchwidgethome} style={{ 'marginBottom': '80px' }}>
              <div className={styles.searchwidgethometop}>
                <Skeleton variant="rect" width="100%" height={40} />
                <Skeleton variant="rect" width="100%" height={40} />
                <Skeleton variant="rect" width="100%" height={40} />
                <Skeleton variant="rect" width="100%" height={40} />
                <Skeleton variant="rect" width="100%" height={40} />
                <Skeleton variant="rect" width="100%" height={40} />
                <Skeleton variant="rect" width="100%" height={40} />
              </div>
              <div className={styles.searchwidgethomebottom}>
                <div className={styles.searchwidgethomewidget} >
                <Skeleton variant="rect" width="100%" height={20} style={{ 'marginBottom': '10px' }} />
                <Skeleton variant="rect" width="100%" height={20} />
                </div>
                <div className={styles.searchwidgethomewidget} >
                <Skeleton variant="rect" width="100%" height={20} style={{ 'marginBottom': '10px' }} />
                <Skeleton variant="rect" width="100%" height={20} />

                </div>
                <div className={styles.searchwidgethomewidget} >
                <Skeleton variant="rect" width="100%" height={20} style={{ 'marginBottom': '10px' }} />
                <Skeleton variant="rect" width="100%" height={20} />

                </div>
                <div className={styles.searchwidgethomewidget} >
                <Skeleton variant="rect" width="100%" height={60}/>
                </div>
               
             
              </div>
    
    
              {/* <Skeleton variant="rect" width="100%" height={200} /> */}
            </div>
    
            <div className={styles.sixlayout} style={{ 'marginBottom': '40px' }}>
    
              <div className={styles.skeleton} >
                <Skeleton variant="rect" width="380px" height={20} style={{ 'marginBottom': '10px' }} />
                <Skeleton variant="rect" width="300px" height={15} style={{ 'marginBottom': '30px' }} />
              </div>
              <div className={styles.skeletonflex}>
                <Skeleton variant="rect" width="100%" height={300} />
                <Skeleton variant="rect" width="100%" height={300} />
                <Skeleton variant="rect" width="100%" height={300} />
    
              </div>
            </div>
    
    
            <div className={styles.fourlayout} style={{ 'marginBottom': '60px' }}>
    
              <div className={styles.skeleton} >
                <Skeleton variant="rect" width="380px" height={20} style={{ 'marginBottom': '10px' }} />
                <Skeleton variant="rect" width="300px" height={15} style={{ 'marginBottom': '30px' }} />
              </div>
              <div className={styles.skeletonflex}>
                <Skeleton variant="rect" width="100%" height={300} />
                <Skeleton variant="rect" width="100%" height={300} />
                <Skeleton variant="rect" width="100%" height={300} />
                <Skeleton variant="rect" width="100%" height={300} />
              </div>
            </div>
            <div className={styles.threelayout} style={{ 'marginBottom': '60px' }}>
    
              <div className={styles.skeleton} >
                <Skeleton variant="rect" width="380px" height={20} style={{ 'marginBottom': '10px' }} />
                <Skeleton variant="rect" width="300px" height={15} style={{ 'marginBottom': '30px' }} />
              </div>
              <div className={styles.skeletonflex}>
                <Skeleton variant="rect" width="100%" height={150} />
                <Skeleton variant="rect" width="100%" height={150} />
                <Skeleton variant="rect" width="100%" height={150} />
    
              </div>
            </div>
    
            <div className={styles.fivelayout} style={{ 'marginBottom': '40px' }}>
    
              <div className={styles.skeleton} >
                <Skeleton variant="rect" width="380px" height={20} style={{ 'marginBottom': '10px' }} />
                <Skeleton variant="rect" width="300px" height={15} style={{ 'marginBottom': '30px' }} />
              </div>
              <div className={styles.skeletonflex}>
                <Skeleton variant="rect" width="100%" height={300} />
                <Skeleton variant="rect" width="100%" height={300} />
                <Skeleton variant="rect" width="100%" height={300} />
                <Skeleton variant="rect" width="100%" height={300} />
                <Skeleton variant="rect" width="100%" height={300} />
    
              </div>
            </div>
    
    
          </div>
        ): (
          <div className={styles.SkeletonMain_Pagemobile} >
            <div className={styles.ax_slideparents}>
              <Skeleton variant="rect" width="100%" height={65} style={{ 'marginBottom': '20px' }} />
            </div>
          <div className={styles.skeletonflextabwidth}>
          <div className={styles.skeletonflextabmain} style={{ 'marginBottom': '0px' }} >
               <div>
               <div className={styles.skeletonflextab}>
               <Skeleton variant="rect" width="100%" height={45} />
               </div>
               <Skeleton variant="rect" width="60px" height={10} />
               </div>
               <div>
               <div className={styles.skeletonflextab}>
               <Skeleton variant="rect" width="100%" height={45} />
               </div>
               <Skeleton variant="rect" width="60px" height={10} />
               </div>
               <div>
               <div className={styles.skeletonflextab}>
               <Skeleton variant="rect" width="100%" height={45} />
               </div>
               <Skeleton variant="rect" width="60px" height={10} />
               </div>
               <div>
               <div className={styles.skeletonflextab}>
               <Skeleton variant="rect" width="100%" height={45} />
               </div>
               <Skeleton variant="rect" width="60px" height={10} />
               </div>
               <div>
               <div className={styles.skeletonflextab}>
               <Skeleton variant="rect" width="100%" height={45} />
               </div>
               <Skeleton variant="rect" width="60px" height={10} />
               </div>
               <div>
               <div className={styles.skeletonflextab}>
               <Skeleton variant="rect" width="100%" height={45} />
               </div>
               <Skeleton variant="rect" width="60px" height={10} />
               </div>
               <div>
               <div className={styles.skeletonflextab}>
               <Skeleton variant="rect" width="100%" height={45} />
               </div>
               <Skeleton variant="rect" width="60px" height={10} />
               </div>
               
              </div>
          </div>
    
            <div className={styles.searchwidgethome} style={{ 'marginBottom': '30px' }}>
              <div className={styles.searchwidgethometop}>
                <Skeleton variant="rect" width="100%" height={67} style={{ 'marginBottom': '35px' }} />
                <Skeleton variant="rect" width="100%" height={67} style={{ 'marginBottom': '35px' }} />
                <Skeleton variant="rect" width="100%" height={67} style={{ 'marginBottom': '35px' }} />
                <Skeleton variant="rect" width="100%" height={67} style={{ 'marginBottom': '35px' }} />
    
              </div>
    
    
    
              {/* <Skeleton variant="rect" width="100%" height={240} /> */}
            </div>
    
            <div className={styles.sixlayout} style={{ 'marginBottom': '20px' }}>
    
              <div className={styles.skeleton} >
                <Skeleton variant="rect" width="200px" height={20} style={{ 'marginBottom': '10px' }} />
                <Skeleton variant="rect" width="120px" height={15} style={{ 'marginBottom': '30px' }} />
              </div>
              <div className={styles.skeletonflex}>
                <Skeleton variant="rect" width="80%" height={280} />
                <Skeleton variant="rect" width="20%" height={280} />
    
              </div>
            </div>
    
    
            <div className={styles.fourlayout} style={{ 'marginBottom': '20px' }}>
    
              <div className={styles.skeleton} >
              <Skeleton variant="rect" width="200px" height={20} style={{ 'marginBottom': '10px' }} />
              <Skeleton variant="rect" width="120px" height={15} style={{ 'marginBottom': '30px' }} />
              </div>
              <div className={styles.skeletonflex}>
                <Skeleton variant="rect" width="80%" height={150} />
                <Skeleton variant="rect" width="20%" height={150} />
              </div>
            </div>
            <div className={styles.threelayout} style={{ 'marginBottom': '20px' }}>
    
              <div className={styles.skeleton} >
              <Skeleton variant="rect" width="200px" height={20} style={{ 'marginBottom': '10px' }} />
              <Skeleton variant="rect" width="120px" height={15} style={{ 'marginBottom': '30px' }} />
              </div>
              <div className={styles.skeletonflex}>
                <Skeleton variant="rect" width="65%" height={310} />
                <Skeleton variant="rect" width="30%" height={310} />
    
              </div>
            </div>
          </div>
        )}
        </>
      )
    default:
      break;
  }
}

export default CoomonSkeleton