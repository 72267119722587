/* eslint-disable jsx-a11y/anchor-has-content */
import Link from "next/link";

const CustomLink = ({ href, ...rest }) => {
  const isInternalLink = href && href.startsWith("/");
  const isAnchorLink = href && href.startsWith("#");
  const reloadPage = href && href.startsWith("reload");

  if (isInternalLink) {
    return (
      <Link href={href}>
        <a href={href} {...rest} />
      </Link>
      // <a href={href} {...rest} />
    );
  }

  if (isAnchorLink) {
    return <a href={href} {...rest} />;
  }

  if (reloadPage) {
    return (
      <a
        target="_self"
        rel="noopener noreferrer"
        href={window.location.pathname}
        {...rest}
      />
    );
  }

  return <a target="_blank" rel="noopener noreferrer" href={href} {...rest} />;
};

export default CustomLink;
