import React, { Component, Fragment } from "react";
import Link from "../../../Link";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";

class Footer1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: "",
      linksData: [],
    };
  }

  // const footerPaymentCard = [""
  //     { title: 'visa', imgSrc: "../images/visa.png" },
  //     { title: 'master', imgSrc: "../images/mastercard.png" },
  //     { title: 'discover', imgSrc: "../images/discover.png" },
  //     { title: 'american express', imgSrc: "../images/americanexpress.png" },
  //     { title: 'union pay', imgSrc: "../images/union-pay-01.png" },

  // ]
  // fna=()=>{
  //     console.log("props ka pointer",this.props);
  // }

  onChangeEmail = (data) => {
    this.setState({
      emailId: data.target.value,
    });
  };
  componentDidMount() {
    if (
      !commonFunction.isValueEmpty(this.props) &&
      !commonFunction.isValueEmpty(this.props.data)
    ) {
      if (
        !commonFunction.isValueEmpty(this.props.data.footermenu) &&
        !commonFunction.isValueEmpty(this.props.data.footermenu.menu)
      ) {
        this.setState({ linksData: this.props?.data?.footermenu?.menu });
      }
    }
  }
  render() {
    return (
      <footer className={this.props?.data?.cssfile?.footer_main}>
        <div className="container items-start mx-auto xl:px-8">
          <div className={this.props?.data?.cssfile?.footer_main_title}>
            Traveling – It leaves you speechless, then turns you into a
            storyteller. ~ Ibn Battuta
          </div>
          <div className={this.props?.data?.cssfile?.footer_main_label}>
            Travel awakens your senses and sets you free like nothing else. It
            doesn’t matter if you are traveling for business, going on a
            one-week family holiday, or have left everything to pursue the life
            of a wanderer, travel makes you a happier person by building your
            self-conﬁdence. It brings in new experiences and memories, breaking
            the routine life and allowing you to meet new faces and taste
            different cuisine from all over the world.
          </div>
          <div className={this.props?.data?.cssfile?.footer_main_label}>
            Rehlat ﬁnds you the best and the most competitive airfares and hotel
            reservation rates. We aim to empower travelers so they ca the fares
            they can afford. We are there to ﬁnd the best prices for you.
          </div>
          <div className={this.props?.data?.cssfile?.footer_main_title}>
            Book your travel & go explore the world
          </div>
          <div className={this.props?.data?.cssfile?.footer_main_label}>
            Go ahead, complete your ﬂight and hotel booking and tick away those
            destinations from your dream trip list. We manage all your booking
            hassles and ensure to keep in budget, so you can wander freely on
            those London streets, stare at the Burj Khalifa, go for a Bosphorus
            ride, chill at the beaches of Sharm El Sheikh, or go for snorkeling
            at Phuket, Bali, or the Maldives.
          </div>
          <div className={this.props?.data?.cssfile?.footer_main_label}>
            Pack your bags and travel where your heart wishes to go. We promise
            to get you the best deals on ﬂights and hotels and also instant
            cashback discounts.
          </div>
          <div className={this.props?.data?.cssfile?.footer_main_label}>
            Let’s travel the world together because at Rehlat your journey is
            understood.
          </div>
          <div className={this.props?.data?.cssfile?.footer_exp_more_cnt}>
            <div className={this.props?.data?.cssfile?.footer_main_title}>
              Explore More
            </div>
            <div className={this.props?.data?.cssfile?.exp_more_wrap}>
              <div className={this.props?.data?.cssfile?.footer_man_link}>
                <span>Airlines</span>
                <span>About Us</span>
                <span>Careers</span>
                <span>Media</span>
              </div>
              <div className={this.props?.data?.cssfile?.footer_seo_link}>
                <span>Kuwait Airways</span>
                <span>Oman Air</span>
                <span>Turkish Airlines</span>
                <span>British Airways</span>
                <span>Air Arabia</span>
                <span>Flynas</span>
              </div>
              <div className={this.props?.data?.cssfile?.footer_seo_link}>
                <span>Qatar Airways</span>
                <span>Ethiopian Airlines</span>
                <span>Srilankan Airlines</span>
                <span>ﬂydubai</span>
                <span>Middle Ease Airlines</span>
                <span>Suadi Airlines</span>
              </div>
              <div className={this.props?.data?.cssfile?.footer_seo_link}>
                <span>Jazeera Airways</span>
                <span>Gulf Air</span>
                <span>Egyptair</span>
                <span>Philippine Airlines</span>
                <span>Spicejet</span>
                <span>IndiGo Ailines</span>
              </div>
              <div className={this.props?.data?.cssfile?.footer_seo_link}>
                <span>Emirates Airlines</span>
                <span>Ethihad Airways</span>
                <span>Air India</span>
                <span>Lufthansa</span>
                <span>Royal Jordanian</span>
                <span>Air India Express</span>
              </div>
            </div>
            <div className={this.props?.data?.cssfile?.footer_copright_cont}>
              <div
                className={this.props?.data?.cssfile?.footer_copyright_label}
              >
                2021 Ezy Trips. All Rights Reserved.
              </div>
              <div className={this.props?.data?.cssfile?.term_social_link_cont}>
                <div className={this.props?.data?.cssfile?.term_flex}>
                  <div className={this.props?.data?.cssfile?.term_label}>
                    Terms and Conditions |
                  </div>
                  <div className={this.props?.data?.cssfile?.term_label}>
                    &nbsp; Privacy Policy
                  </div>
                </div>
                <div className={this.props?.data?.cssfile?.social_media}>
                  {!commonFunction.isValueEmpty(this.props) &&
                    !commonFunction.isValueEmpty(this.props.data) &&
                    !commonFunction.isValueEmpty(
                      this.props?.data?.footermenu
                    ) &&
                    !commonFunction.isValueEmpty(
                      this.props?.data?.footermenu?.socialmedia
                    ) &&
                    this.props?.data?.footermenu?.socialmedia.map(
                      (sociallink, index) => (
                        <Fragment key={"socialmedia" + index}>
                          {sociallink.active == "active" ? (
                            <Link
                              key={sociallink.text}
                              href={sociallink.url}
                              className=""
                            >
                              {/* {sociallink.text} */}
                              <img
                                src={`${
                                  "/shared/api/media/" +
                                  this.props?.data?.siteData?.site.id +
                                  "/" +
                                  sociallink?.icon
                                }`}
                                alt="social"
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </Fragment>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer1;
