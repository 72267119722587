import React, { Component, Fragment } from 'react'
import commonFunction from '../../../lib/utils/common'
import configUrl from '../../../../config/config.json'
import Link from '../../../Link';
class MobileSubFooter1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowContactUs: false,
            isShowOurPolicy: false
        };
    }
    clickContactUs = () => {
        this.setState({
            isShowContactUs: !this.state.isShowContactUs
        })

    }
    clickOurPolicy = () => {
        this.setState({
            isShowOurPolicy: !this.state.isShowOurPolicy
        })
    }

    onChangeEmail = (data) => {
        // console.log("data============",data.target.value)
        this.setState({
            emailId: data.target.value
        })
    }

    render() {
        // console.log("props===============",this.state)
        return (
            <subfooter>
                <div className={this.props.data.cssfile.footer_mobno_email_flex}>
                    <a href="tel:+971 58 953 9988">
                        <div className={this.props.data.cssfile.footer_phone}>
                            <div className={this.props.data.cssfile.footer_phone_flx}>
                                <div className={this.props.data.cssfile.footer_phone_icon}>
                                    <img src="/images/ftrcall-01.svg" alt="" className={this.props.data.cssfile.footer_phone_img} />
                                </div>
                                <div className={this.props.data.cssfile.footer_phone_txt}>
                                    <span> +971 58 953 9988</span>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a href="mailto:connect@elevatetrips.com">
                        <div className={this.props.data.cssfile.footer_phone}>
                            <div className={this.props.data.cssfile.footer_phone_flx}>
                                <div className={this.props.data.cssfile.footer_phone_icon}>
                                    <img src="/images/ftremail-01.svg" alt="" className={this.props.data.cssfile.footer_phone_img} />
                                </div>
                                <div className={this.props.data.cssfile.footer_phone_txt}>
                                    <span>connect@elevatetrips.com</span>
                                </div>

                            </div>
                        </div>
                    </a>
                </div>


                <div className={this.props.data.cssfile.footer_info_footer_1}>
                    <div className={this.props.data.cssfile.contact_us_mobile}>
                        <div className={this.props.data.cssfile.contact_us_mobile_main}>
                            <div className={this.props.data.cssfile.contact_us_button_mob} onClick={() => { this.clickContactUs() }}>
                                <div className={this.props.data.cssfile.contact_us_txt}>CONTACT US</div>
                                <div className={this.props.data.cssfile.up_down_arrow}><img src="/images/arrow-01.svg" alt="" className={this.props.data.cssfile.up_dwn_arrow} /></div>
                            </div>
                            <div className={this.state.isShowContactUs ? this.props.data.cssfile.contact_us_content_mob : this.props.data.cssfile.contact_us_content_mob_hide}>
                                <div className={this.props.data.cssfile.conatct_us_add}>
                                    <div className={this.props.data.cssfile.conatct_us_add_txt}>Address:
                                        <span>Office 2801, Cluster M, Jumeirah Lake Towers, Dubai, UAE</span>
                                    </div>
                                </div>
                                <div className={this.props.data.cssfile.conatct_us_phn_email_add}>
                                    <div className={this.props.data.cssfile.conatct_us_phn_email_flx}>
                                        <div className={this.props.data.cssfile.conatct_us_phn_email_txt}>Phone: <span>(+971) 4 123 4567</span></div>
                                        <div className={this.props.data.cssfile.conatct_us_phn_email_txt}>Mobile: <span>(+971) 55 123 4567</span></div>
                                    </div>
                                    <div className={this.props.data.cssfile.conatct_us_phn_email_flx}>
                                        <div className={this.props.data.cssfile.conatct_us_phn_email_txt}>Email: <span> info@elevatetrips.com</span></div>
                                        <div className={this.props.data.cssfile.conatct_us_phn_email_txt}>Website: <span>www.elevatetrips.com </span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={this.props.data.cssfile.contact_us_mobile}>
                        <div className={this.props.data.cssfile.contact_us_mobile_main}>
                            <div className={this.props.data.cssfile.contact_us_button_mob} onClick={() => { this.clickOurPolicy() }}>
                                <div className={this.props.data.cssfile.contact_us_txt}>OUR POLICIES</div>
                                <div className={this.props.data.cssfile.up_down_arrow}><img src="/images/arrow-01.svg" alt="" className={this.props.data.cssfile.up_dwn_arrow} /></div>
                            </div>
                            <div className={this.state.isShowOurPolicy ? this.props.data.cssfile.contact_us_content_mob : this.props.data.cssfile.contact_us_content_mob_hide}>
                                <div className={this.props.data.cssfile.conatct_us_add}>
                                    {/* {!commonFunction.isValueEmpty(this.props) && !commonFunction.isValueEmpty(this.props.data) && !commonFunction.isValueEmpty(this.props.data.footermenu) && !commonFunction.isValueEmpty(this.props.data.footermenu.menu) && this.props.data.footermenu.menu.map((link, index) => (
                                        <div className={this.props.data.cssfile.conatct_us_add_txt_policy_txt}>
                                            <Link
                                                key={"footermenu" + index}
                                                href={link.url}
                                                className=''
                                            >
                                                {link.text}
                                            </Link>
                                        </div>
                                    ))} */}
                                    <div className={this.props.data.cssfile.conatct_us_add_txt_policy_txt}><a target="_blank" href="/aboutus">About us</a></div>
                                    <div className={this.props.data.cssfile.conatct_us_add_txt_policy_txt}><a target="_blank" href="/Privacy_policy">Privacy Policy</a></div>
                                    <div className={this.props.data.cssfile.conatct_us_add_txt_policy_txt}><a target="_blank" href="/PaymentSecurity">Payment Policy</a></div>
                                    <div className={this.props.data.cssfile.conatct_us_add_txt_policy_txt}><a target="_blank" href="/tnc">Terms &amp; Conditions</a></div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={this.props.data.cssfile.signup_text}>Signup for our Newsletter</div>
                    <div className={this.props.data.cssfile.signup_newletter}>
                        <div className={this.props.data.cssfile.search_container}>
                            <form action="">
                                <input type="text" placeholder="Your email address" name="search" className={this.props.data.cssfile.footer_search} autoComplete="off" onChange={(e) => { this.onChangeEmail(e) }} />
                                <button type="submit">SUBMIT</button>
                            </form>
                        </div>
                    </div>

                    <div className={this.props.data.cssfile.comp_name_1}>

                        <span className={this.props.data.cssfile.comp}>© 2021 Elevate Trips DMCC. All Rights Reserved</span>

                    </div>

                    <div className={this.props.data.cssfile.social_media_ft_social_media}>

                        <span className={this.props.data.cssfile.social_media_logo}>
                            <a href="https://www.facebook.com/elevatetripsdubai/?ref=page_internal" target="_blank" rel="noopener noreferrer">
                                <input className={this.props.data.cssfile.sm_logo} type="image" src="" alt="facebook"/>
                            </a>
                        </span>

                        <span className={this.props.data.cssfile.social_media_logo}>
                            <a href="https://www.instagram.com/elevatetrips/" target="_blank" rel="noopener noreferrer">
                                <input className={this.props.data.cssfile.sm_logo} type="image" src="" alt="instagram"/>
                            </a>
                        </span>

                        <span className={this.props.data.cssfile.social_media_logo}>
                            <a href="https://twitter.com/elevatetrips" target="_blank" rel="noopener noreferrer">
                                <input className={this.props.data.cssfile.sm_logo} type="image" src="" alt="twitter"/>
                            </a>
                        </span>

                        <span className={this.props.data.cssfile.social_media_logo}>
                            <a href="https://www.youtube.com/channel/UC5gLIUNp-QuW4OcevVDdGIg" target="_blank" rel="noopener noreferrer">
                                <input className={this.props.data.cssfile.sm_logo} type="image" src="" alt="youtube"/>
                            </a>
                        </span>

                        <span className={this.props.data.cssfile.social_media_logo}>
                            <a href="https://www.linkedin.com/company/elevatetrips" target="_blank" rel="noopener noreferrer">
                                <input className={this.props.data.cssfile.sm_logo} type="image" src="" alt="linkedin"/>
                            </a>
                        </span>

                    </div>

                    <div className={this.props.data.cssfile.social_media_social_media_lower_footer}>

                        <span className={this.props.data.cssfile.social_media_logo}>
                            <input type="image" className={this.props.data.cssfile.sm_logo_sm_logo_lwr_ftr} src="" alt="visa"/>
                        </span>

                        <span className={this.props.data.cssfile.social_media_logo}>
                            <input type="image" className={this.props.data.cssfile.sm_logo_sm_logo_lwr_ftr} src="" alt="mastercard"/>
                        </span>

                        <span className={this.props.data.cssfile.social_media_logo}>
                            <input type="image" className={this.props.data.cssfile.sm_logo_sm_logo_lwr_ftr} src="" alt="discover"/>
                        </span>

                        <span className={this.props.data.cssfile.social_media_logo}>
                            <input type="image" className={this.props.data.cssfile.sm_logo_sm_logo_lwr_ftr} src="" alt="americanexpress"/>
                        </span>

                        <span className={this.props.data.cssfile.social_media_logo}>
                            <input type="image" className={this.props.data.cssfile.sm_logo_sm_logo_lwr_ftr} src="" alt="union-pay"/>
                        </span>

                    </div>



                </div>
            </subfooter >
        )
    }
}

export default MobileSubFooter1;