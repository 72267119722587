import React, { Component } from "react";
import {
  authenticatedUser,
  getCookieTocken,
  removeUserTokenFromCookie,
  setTockenInCoockie,
} from "../../login/userToken";
import Link from "../../../Link";
import UploadLogo from "store/uploadLogo";
import assistedBooking from "../../../lib/assistedBooking";
import assistedNewToken from "../../../lib/assistedNewToken";
import chat from "../../../lib/chat";
import { checksession } from "../../../lib/checksession";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";
import { isNull } from "lodash";
import { logout } from "../../../lib/logout";
import { withRouter } from "next/router";

// import styles from "./index.module.css"

class Header1 extends Component {
  constructor(props) {
    super(props);
    console.log("propsheaders", props);
    this.state = {
      isShowPopup: false,
      userFirststname: "",
      userType: "",
      openInitialPopup: false,
      onHoverSupport: false,
    };
  }
  componentDidMount() {
    // console.log(this.props,"header");
    //check if varification token is there
    document.addEventListener("mousedown", this.handleCalendarClickOutside);
    // checksession().then(response => {
    //     console.log("response---------", response)
    //     if (response.status) {
    //         this.setState({
    //             userFirststname: response?.first_name ? response?.first_name.charAt(0) : response?.email.charAt(0),
    //             userType: response.type
    //         })
    //     } else {
    //         this.setState({
    //             userFirststname: "",
    //             userType: ""
    //         })
    //     }
    // })

    // crisp chat
    // window.$crisp = [];
    // window.CRISP_WEBSITE_ID = "f2897580-9f38-4425-93fa-079d3932625f";
    // (() => {
    // const d = document;
    // const s = d.createElement("script");
    // s.src = "https://client.crisp.chat/l.js";
    // s.async = 1;
    // d.getElementsByTagName("body")[0].appendChild(s);
    // })();
    //
    chat(this.props?.data?.siteData?.site?.chat, this.props?.data?.isMobile);

    if (this.props?.data?.user?.status) {
      this.setState({
        userFirststname: this.props?.data?.user?.first_name
          ? this.props?.data?.user?.first_name.charAt(0)
          : this.props?.data?.user?.email.charAt(0),
        userType: this.props?.data?.user?.type,
      });
    } else {
      this.setState({
        userFirststname: "",
        userType: "",
      });
    }
    // this.checkSessionApi()
  }

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleCalendarClickOutside);
  };

  setCalendarWrapperRef = (node) => {
    this.calendarWrapperRef = node;
  };

  handleCalendarClickOutside = (event) => {
    if (
      !this.calendarWrapperRef ||
      !this.calendarWrapperRef.contains(event.target)
    ) {
      this.setState({ openInitialPopup: false });
    }
  };

  checkSessionApi = () => {
    if (
      !commonFunction.isValueEmpty(this?.props?.data?.sessionData) &&
      !commonFunction.isValueEmpty(this?.props?.data?.sessionData) &&
      this?.props?.data?.sessionData?.status === true
    ) {
      this.setState(
        {
          userFirststname:
            !commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.email
            ) &&
            (!commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.first_name
            )
              ? this?.props?.data?.sessionData?.first_name.charAt(0)
              : this?.props?.data?.sessionData?.email.charAt(0)),
          userType:
            !commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.type
            ) && this?.props?.data?.sessionData?.type,
        },
        () => {}
      );
    } else {
      this.setState({
        userFirststname: "",
        userType: "",
      });
    }
  };
  onClickArrow = () => {
    this.setState({
      isShowPopup: !this.state.isShowPopup,
    });
  };
  onMouseLeave = () => {
    this.setState({
      isShowPopup: false,
    });
  };
  // componentWillReceiveProps(state, props) {
  //     this.checkSessionApi()
  // }
  onClickProfile = () => {
    // commonFunction.redirectTo('/myprofile')
    window.location.href = "/myprofile";
  };
  onClickLogout = () => {
    logout()
      .then((responseJSON) => {
        if (
          responseJSON.status === true ||
          responseJSON.message === "Logout successfull"
        ) {
          this.setState({
            userFirststname: "",
            userType: "",
          });
          // location.reload()

          if (this.props?.data?.siteData?.site?.business_b2b) {
            window.location.pathname != "/login"
              ? (window.location.href = "/login")
              : null;
          } else {
            // commonFunction.redirectTo('/')
            window.location.href = "/";
          }
        } else {
        }
      })
      .catch((err) => {});
  };

  myBookingClickHandler = () => {
    // commonFunction.redirectTo('/myprofile', { Linkindex: 1 })
    window.location.href = `/myprofile?Linkindex=1`;
  };

  handleAssistedBooking = async () => {
    if (this.props?.data?.user?.assit_bookerId === null) {
      console.log("this:", this.props.data);
      const { onDataReceived } = this.props.data;
      const data = await assistedBooking(this.props.data.user.organisation.id);
      onDataReceived(data); // Call the function passed as a prop with the data
    } else {
      const reqBody = {
        user_id: this.props?.data?.user?.assit_bookerId,
        assit_login: false,
        site_id: this.props?.data?.siteData?.data?.site_id || "",
      };
      const data = await assistedNewToken(reqBody);
      const { accessToken } = data;
      console.log("New token:", accessToken);
      if (accessToken) {
        console.log("Check session getting called");
        // this.props.router.reload();
        window.location.href = "/";
      }
    }
  };

  logoUrlHandler = () => {
    if (
      this.props.data.headermenu.supportlink != "" &&
      window.location.pathname == "/"
    ) {
      window.location.href = this.props.data.headermenu.supportlink;
    } else {
      window.location.href = "/";
    }
  };

  render() {
    console.log("demo", this.props);
    return (
      <header className="new-header" ref={this.setFamilyWrapperRef}>
        <div className={this.props?.data?.cssfile?.header_section}>
          <div className="flex flex-row justify-between items-center container mx-auto px-4 xl:px-8">
            <h1>
              <Link
                key={""}
                // href={"/"}
                // onclick="window.location.href='/'"
                onClick={() => {
                  this.logoUrlHandler();
                }}
                className=""
              >
                <img
                  className={this.props?.data?.cssfile?.header_logo}
                  src={`${
                    "/shared/api/media/" +
                    (UploadLogo?._currentValue?.isLogo
                      ? this.props?.data?.headermenu?.logo
                      : this.props?.data?.headermenu?.logo)
                  }`}
                  alt="logo"
                />
                {/* <img className={this.props?.data?.cssfile?.header_logo} src={`${ '/shared/api/media/' + this.props.data.headermenu.logo}`} alt="logo" /> */}
              </Link>
            </h1>
            <div>
              <h2>
                <div className={this.props.data.cssfile.header_all_section}>
                  {!commonFunction.isValueEmpty(this.props) &&
                    !commonFunction.isValueEmpty(this.props.data) &&
                    !commonFunction.isValueEmpty(this.props.data.headermenu) &&
                    !commonFunction.isValueEmpty(
                      this.props.data.headermenu.menu
                    ) &&
                    this.props.data.headermenu.menu.map(
                      (link, i) =>
                        link?.status.toLowerCase() === "active" && (
                          <div
                            key={"headerMenu" + i}
                            className={this.props.data.cssfile.header_sections}
                          >
                            {link.url == "/holiday/" ||
                            link.url == "/holiday" ? (
                              <Link
                                key={link.text}
                                onClick={() => {
                                  window.location.href = link.url;
                                }}
                                className=""
                              >
                                {link.text}
                                <img
                                  src={`${
                                    "/shared/api/media/" +
                                    this.props?.data?.siteData?.site.id +
                                    "/" +
                                    link.icon
                                  }`}
                                  alt=""
                                />
                              </Link>
                            ) : (
                              <Link
                                key={link.text}
                                href={link.url}
                                //onClick={() => { window.location.href = link.url }}
                                className=""
                              >
                                {link.text}
                                <img
                                  src={`${
                                    "/shared/api/media/" +
                                    this.props?.data?.siteData?.site.id +
                                    "/" +
                                    link.icon
                                  }`}
                                  alt=""
                                />
                              </Link>
                            )}
                          </div>
                        )
                    )}
                </div>
              </h2>
            </div>

            {this.state.userType === "B2B" ? (
              <div className={this.props.data.cssfile.cmpny_agnt_nme}>
                <div className={this.props.data.cssfile.cmpny_nme_wp}>
                  <span className={this.props.data.cssfile.cmpny_title}>
                    Company Name:{" "}
                  </span>
                  <span
                    className={this.props.data.cssfile.cmpny_name}
                    title={this.props?.data?.user?.company_name}
                  >
                    {" "}
                    {this.props?.data?.user?.company_name}
                  </span>
                </div>
                <div className={this.props.data.cssfile.cmpny_nme_wp}>
                  <span className={this.props.data.cssfile.cmpny_title}>
                    Available Limit: AED{" "}
                  </span>
                  <span
                    style={{ paddingLeft: 5 }}
                    className={this.props.data.cssfile.cmpny_prce_limit}
                  >
                    {Intl.NumberFormat("en-US").format(
                      this.props?.data?.user?.agent_balance?.Booking_Balance
                    )}
                  </span>
                </div>
                {this.props?.data?.user?.is_assisted_booking_allowed && (
                  <button
                    className={this.props.data.cssfile.assisted_btn}
                    onClick={this.handleAssistedBooking}
                  >
                    {this.props?.data?.user?.assit_bookerId === null
                      ? "Assisted Booking"
                      : "Back To Own Branch"}
                  </button>
                )}
              </div>
            ) : null}
            {(this.props?.data?.headermenu?.is_show_login == undefined ||
              this.props?.data?.headermenu?.is_show_login) && (
              <div
                className={this.props.data.cssfile.afterlogin}
                ref={this.setCalendarWrapperRef}
              >
                {this.state.userFirststname != undefined &&
                this.state.userFirststname != null &&
                this.state.userFirststname != "" ? (
                  <div className={this.props.data.cssfile.afterlogin}>
                    {" "}
                    <span className={this.props.data.cssfile.user_code}>
                      {this.state.userFirststname}
                    </span>
                    <span
                      onClick={() => {
                        this.onClickArrow();
                      }}
                    >
                      <img src="/images/down_arrow.jpg" alt="" />
                    </span>
                    <div
                      className={
                        this.state.isShowPopup
                          ? this.props.data.cssfile.show
                          : this.props.data.cssfile.profile_details_hide
                      }
                      onMouseLeave={() => {
                        this.onMouseLeave();
                      }}
                    >
                      {/* <div className={styles.profile_frst}> */}
                      <div className={this.props.data.cssfile.profile_frst}>
                        <span
                          onClick={() => {
                            this.onClickProfile();
                          }}
                        >
                          {" "}
                          <div className={this.props.data.cssfile.menu_icon}>
                            <img
                              type="image"
                              src="/images/profile-01.svg"
                              alt="profile"
                            />
                          </div>
                          My Profile
                        </span>
                        <span onClick={this.myBookingClickHandler}>
                          {" "}
                          <div className={this.props.data.cssfile.menu_icon}>
                            <img
                              type="image"
                              src="/images/booking-01.svg"
                              alt="mybooking"
                            />
                          </div>
                          My Booking
                        </span>
                        <span
                          onClick={() => {
                            this.onClickLogout();
                          }}
                        >
                          {" "}
                          <div className={this.props.data.cssfile.menu_icon}>
                            <img
                              type="image"
                              src="/images/logout-01.svg"
                              alt="Logout"
                            />
                          </div>
                          Logout
                        </span>
                      </div>
                      {/* <div className={`${styles.profile_frst} ${styles.support_scnd}`}> */}
                      <div
                        className={`${this.props.data.cssfile.profile_frst} ${this.props.data.cssfile.support_scnd}`}
                      >
                        <div class={this.props.data.cssfile.support_heading}>
                          Support
                        </div>
                        <a
                          href={`tel:${this.props?.data?.siteData?.site?.support_number}`}
                        >
                          <span>
                            {" "}
                            <div className={this.props.data.cssfile.menu_icon}>
                              <img
                                type="image"
                                src="/images/call-01.svg"
                                alt="Call"
                              />
                            </div>
                            {this.props?.data?.siteData?.site?.support_number}{" "}
                          </span>
                        </a>
                        <a
                          href={`mailto:${this.props?.data?.siteData?.site?.support_email}`}
                        >
                          <span>
                            <div className={this.props.data.cssfile.menu_icon}>
                              <img
                                type="image"
                                src="/images/email-01.svg"
                                alt="Support"
                              />
                            </div>
                            {this.props?.data?.siteData?.site?.support_email}
                          </span>
                        </a>

                        {/* <a href="tel:+971 58 953 9988">
                                                <span> <div className={this.props.data.cssfile.menu_icon}><img type="image" src="/images/call-01 (1).svg" alt="" /></div>+971 58 953 9988</span>
                                            </a>
                                            <a href="mailto:connect@elevatetrips.com"><span><div className={this.props.data.cssfile.menu_icon}><img type="image" src="/images/email-01.svg" alt="" /></div>connect@elevatetrips.com</span></a> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={this.props.data.cssfile.login_btn}
                    // onClick={this.props.data.onClickHeaderLogin}
                    onClick={() => {
                      this.setState({ openInitialPopup: true });
                    }}
                  >
                    <img
                      type="image"
                      src="/images/sign-in-user.png"
                      alt="Login"
                    />
                    <button>LOGIN OR SIGNUP</button>
                    <img
                      type="image"
                      src="/images/imgpsh_fullsize_anim.png"
                      alt="Sign Up"
                    />
                  </div>
                )}

                {this.state.openInitialPopup && (
                  <div
                    className="logindrop"
                    id="loginDrop"
                    style={{ display: "block" }}
                  >
                    <div
                      className="ax-head-menu"
                      style={{ marginBottom: "0px" }}
                      onClick={() => {
                        this.props.data.onClickHeaderLogin("LogIn");
                      }}
                    >
                      <img type="image" src="/images/loginew.svg" alt="Login" />
                      Login
                    </div>
                    <div
                      className="ax-head-menu"
                      style={{ marginBottom: "0px" }}
                      onClick={() => {
                        this.props.data.onClickHeaderLogin("BeforeSignUp");
                      }}
                    >
                      <img
                        type="image"
                        src="/images/registere.svg"
                        alt="Sign Up"
                      />
                      Sign Up
                    </div>
                    <div
                      className="ax-head-menu etsupport etsupport-desktop"
                      id="etsupport"
                      onMouseEnter={() => {
                        this.setState({ onHoverSupport: true });
                      }}
                      onMouseLeave={() => {
                        this.setState({ onHoverSupport: false });
                      }}
                    >
                      <span className="support-txt">
                        <img
                          type="image"
                          id="supporticon"
                          className="supp"
                          src="/images/profile-01.svg"
                          alt="Support"
                          width="24px"
                          height="24px"
                        />
                        Support
                      </span>
                      {this.state.onHoverSupport && (
                        <div
                          className="supportdrop"
                          style={{ display: "block" }}
                        >
                          <a href="tel:+971 58 953 9988">
                            <div
                              className="ax-head-menu"
                              style={{ marginBottom: "0px" }}
                            >
                              <img src="/images/et-hd-phn.png" alt="" />
                              {/* +971 58 953 9988 */}
                              {this.props?.data?.siteData?.site?.support_number}
                            </div>
                          </a>
                          <a href="#">
                            <div
                              className="ax-head-menu"
                              style={{ marginBottom: "0px" }}
                            >
                              <img
                                src="/images/et-hd-email.png"
                                alt=""
                                width="20px"
                                height="20px"
                              />
                              {/* connect@elevatetrips.com */}
                              {this.props?.data?.siteData?.site?.support_email}
                            </div>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header1);
