export function signup(req) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType"),
    },
    body: JSON.stringify(req),
  };
  console.log("POST Signup", requestOptions);
  return fetch("/shared/api/signup", requestOptions).then((response) =>
    response.json()
  );
}

export function contactus(req) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType"),
    },
    body: JSON.stringify(req),
  };
  console.log("POST Signup", requestOptions);
  return fetch("/shared/api/contactus", requestOptions).then((response) =>
    response.json()
  );
}

export function checkregisterstatus(req) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType"),
    },
    body: JSON.stringify(req),
  };
  return fetch("/shared/api/checkregisterstatus", requestOptions).then(
    (response) => response.json()
  );
}
