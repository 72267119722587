import { getSystemDetails } from "./getSystemDetails";

export async function login(req) {
  const { ip, userAgent } = await getSystemDetails();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Client-IP": ip,
      "X-Client-User-Agent": userAgent,
    },
    body: JSON.stringify(req),
  };
  console.log("POST Login", requestOptions);
  return fetch("/shared/api/login", requestOptions).then((response) =>
    response.json()
  );
}

export async function userLogin(req) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req),
  };
  console.log("POST userLogin", requestOptions);
  return fetch("/shared/api/userLogin", requestOptions).then((response) =>
    response.json()
  );
}

export async function userVerify(req) {
  const { ip, userAgent } = await getSystemDetails();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Client-IP": ip,
      "X-Client-User-Agent": userAgent,
    },
    body: JSON.stringify(req),
  };
  console.log("POST userVerify", requestOptions);
  return fetch("/shared/api/userVerify", requestOptions).then((response) =>
    response.json()
  );
}

export function resendOtp(req) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req),
  };
  console.log("POST resendotp", requestOptions);
  return fetch("/shared/api/resendOtp", requestOptions).then((response) =>
    response.json()
  );
}
