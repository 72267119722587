import React, { Component, Fragment } from "react";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";
import Link from "../../../Link";

class Footer2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "red",
      emailId: "",
      linksData: [],
    };
  }

  onChangeEmail = (data) => {
    this.setState({
      emailId: data.target.value,
    });
  };
  componentDidMount() {
    if (
      !commonFunction.isValueEmpty(this.props) &&
      !commonFunction.isValueEmpty(this.props.data)
    ) {
      if (
        !commonFunction.isValueEmpty(this.props.data.footermenu) &&
        !commonFunction.isValueEmpty(this.props.data.footermenu.menu)
      ) {
        this.setState({ linksData: this.props?.data?.footermenu?.menu });
      }
    }
  }

  render() {
    return (
      <>
        <footer className={this.props?.data?.cssfile?.footer_main}>
          <div
            className={`${this.props?.data?.cssfile?.footer_nwContainer} flex flex-row justify-between container items-start mx-auto xl:px-8`}
          >
            <div className={this.props?.data?.cssfile?.footer_logo}>
              <input
                type="image"
                src={`${
                  "/shared/api/media/" +
                  this.props?.data?.siteData?.site.id +
                  "/" +
                  this.props?.data?.footermenu?.logo
                }`}
                alt="Logo"
              />
            </div>
            <div className={this.props?.data?.cssfile?.footer_links}>
              {this.state.linksData?.map((link) => {
                return link.active == "active" ? (
                  <Link
                    title={link.text}
                    key={link.text}
                    href={link.url}
                    className=""
                  >
                    {link.text}
                  </Link>
                ) : (
                  ""
                );
              })}
            </div>
            <div className={this.props?.data?.cssfile?.footer_send_email}>
              {/* to hide newsletter bug */}
              {/* <div className={this.props?.data?.cssfile?.email_block}>
                <label className="">Signup for our Newsletter</label>
                <input
                  type="email"
                  placeholder="Your email address..."
                  value={this.state.emailId}
                  onChange={(e) => {
                    this.onChangeEmail(e);
                  }}
                />
                <button>Subscribe</button>
              </div> */}
              <div className={this.props?.data?.cssfile?.social_media}>
                {!commonFunction.isValueEmpty(this.props) &&
                  !commonFunction.isValueEmpty(this.props.data) &&
                  !commonFunction.isValueEmpty(this.props?.data?.footermenu) &&
                  !commonFunction.isValueEmpty(
                    this.props?.data?.footermenu?.socialmedia
                  ) &&
                  this.props?.data?.footermenu?.socialmedia.map(
                    (sociallink, index) => (
                      <Fragment key={"socialmedia" + index}>
                        {sociallink.active == "active" ? (
                          <Link
                            title={sociallink.text}
                            key={sociallink.text}
                            href={sociallink.url}
                            className=""
                          >
                            {/* {sociallink.text} */}
                            <img
                              src={`${
                                "/shared/api/media/" +
                                this.props?.data?.siteData?.site.id +
                                "/" +
                                sociallink?.icon
                              }`}
                              alt={sociallink.text}
                            />
                          </Link>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    )
                  )}
              </div>

              {/* <div className={this.props?.data?.cssfile?.payment_card}> */}
              {/* {footerPaymentCard.map((sociallink) => (
                            <img src={sociallink.imgSrc} alt="" />

                        ))} */}
              {/* </div> */}
            </div>
          </div>
          <div
            className={`${this.props?.data?.cssfile?.footer_nwContainer} flex container xl:px-8 pt-4`}
          >
            <div className={this.props?.data?.cssfile?.wp_wrapper}>
              <ul className={this.props?.data?.cssfile?.menu_footer_new}>
                {this.props?.data?.footermenu?.policy?.map((link, index) => (
                  <>
                    {link.active == "active" ? (
                      <li>
                        <Link
                          title={link.text}
                          href={link.url}
                          key={index}
                          // target="_blank"
                        >
                          {link.text}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </>
                ))}

                {/* {this.props?.data?.footermenu?.status == 'Active' &&
                <>
                 <li><a title="" href="#">Terms & use</a></li>
                 <li><a title="" href="#">Payment Security</a></li>
                 <li><a title="" href="#">Privacy Policy</a></li>
                 <li><a title="" href="#">Cookies Policy</a></li>
                 </>
                } */}
              </ul>

              <div className={this.props?.data?.cssfile?.wpb_wrap_contact}>
                <a
                  href={
                    "mailto:" + this.props?.data?.siteData?.site?.support_email
                  }
                  target="_blank"
                  title="Support Email"
                >
                  <div className={this.props?.data?.cssfile?.ax_head_menu}>
                    {/* <img src="/images/et-hd-email.png" alt="connect" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"></path>
                    </svg>
                    {this.props?.data?.siteData?.site?.support_email}
                  </div>
                </a>
                <a
                  href={
                    "tel:" + this.props?.data?.siteData?.site?.support_number
                  }
                  title="Support Number"
                >
                  <div className={this.props?.data?.cssfile?.ax_head_menu}>
                    {/* <img src="/images/et-hd-phn.png" alt="call" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-telephone"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path>{" "}
                    </svg>
                    {this.props?.data?.siteData?.site?.support_number}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className={this.props?.data?.cssfile?.travel_copyright}>
            {/* <span>Copyright © elevatetrips 2023. All Rights Reserved.</span> */}
            <span>{this.props?.data?.footermenu?.text}</span>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer2;
