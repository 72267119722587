import React, { Component, createRef } from "react";

import assistedBookingAgent from "../../../lib/assistedBookingAgent";
import assistedNewToken from "../../../lib/assistedNewToken";
// import { checksession } from "../../../lib/checksession";
import { withRouter } from "next/router";
import config from '../../../../config/config.json'

class MobileTheme4 extends Component {
  dropdownRef = createRef();
  state = {
    selectedSubBranch: "",
    selectedAgency: "",
    selectedSubAgency: "",
    filteredSubagencies: [],
    retrievedAgents: [],
    selectedAgent: "",
    selectedAgentName: "",
    site_id: "",
    channels: [],
    selectedChannel: "",
    showDropdown: false,
    loading: false,
  };

  componentDidMount() {
    const channels = this.props?.data?.data?.org?.childs
    const channelItems = Object.keys(channels)
    let channelArray = []
    if(channelItems.length > 0) {
      channelItems.map(item => {
        channelArray.push(item)
      })
      channelArray.push('Agent')
    } else {
      channelArray.push('Agent')
    }
    this.setState({channels: channelArray})
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
 
  handleClickOutside = e => {
    if (
      this.state.showDropdown && this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)
    ) {
      this.setState({ showDropdown: false });
    }
  }

  handleChannelChange = async (e) => {
    if(e.target.value === 'Agent') {
      this.setState({loading: true, selectedChannel: 'Agent'})
      const data = await assistedBookingAgent(this.props?.data?.data?.org?._id || '');
      const { agents } = data.org;
      this.setState({selectedChannel: 'Agent' , retrievedAgents: agents, loading: false});
    } else {
      this.setState({ selectedChannel: e.target.value, retrievedAgents: [], filteredSubagencies: [], selectedSubAgency: "", selectedAgency: "", selectedSubBranch: "", selectedAgentName: "", showDropdown: false });
    }
  };

  handleSubBranchChange = (e) => {
    this.setState({ selectedSubBranch: e.target.value });
  };

  handleAgencyChange = async (e) => {
    const selectedAgencyId = e.target.value;
    if(selectedAgencyId === 'Agent') {
      this.setState({ selectedAgency: 'Agent', loading: true });
      const data = await assistedBookingAgent(this.state.selectedSubBranch);
      const { agents } = data.org;
      this.setState({  retrievedAgents: agents, loading: false });
    } else {
      const filteredSubagencies =
      this.props?.data?.data?.org?.childs?.subagency?.filter(
        (sub) => sub.parent_id === selectedAgencyId
      );
    this.setState({ retrievedAgents: [], selectedAgency: selectedAgencyId, filteredSubagencies });
    }
  };

  handleSubAgencyChange = async (e) => {
    const selectedSubAgencyId = e.target.value;
    this.setState({ selectedSubAgency: e.target.value === this.state.selectedAgency ? "" : selectedSubAgencyId, loading: true });
    const data = await assistedBookingAgent(selectedSubAgencyId);
    const { agents } = data.org;
    this.setState({ retrievedAgents: agents, loading: false });
  };

  handleAgentChange = (e) => {
    // const siteId = e.target.options[e.target.selectedIndex].getAttribute("id");
    this.setState({ selectedAgentName: e.target.value });
  };

  onUserInputClicked = (name, id) => {
    this.setState({selectedAgent: id, selectedAgentName: name})
  }
  

  submitButton = async () => {
    const { selectedAgent, retrievedAgents } = this.state;
    const reqBody = {
      user_id: selectedAgent,
      assit_login: true,
      site_id: retrievedAgents[0]?.site_id || '',
    };
    const data = await assistedNewToken(reqBody);
    const { accessToken } = data;
    if (accessToken) {
      window.location.href = '/'
    }
  };

  render() {
    const {
      selectedChannel,
      selectedSubBranch,
      selectedAgency,
      selectedSubAgency,
      filteredSubagencies,
      retrievedAgents,
      selectedAgentName,
      showDropdown,
      loading
    } = this.state;
    const { styles, data, setAssistedBokingPopup} = this.props;
    const channelOptions = this.state.channels.map((org, index) => (
      <option key={index} value={org}>
        {org}
      </option>
    ));

    const subAgencies = data?.data?.org?.childs?.subagency?.map((org, index) => (
      <option key={index} value={org._id}>
        {org.org_name}
      </option>
    ));

    const subBranches = data?.data?.org?.childs?.subbranch?.map((org, index) => (
      <option key={index} value={org._id}>
        {org.org_name}
      </option>
    ));

    const agencyOptions = data?.data?.org?.childs?.agency?.map((org, index) => (
      <option key={index} value={org._id}>
        {org.org_name}
      </option>
    ));

    const subAgencyOptions = filteredSubagencies.map((org, index) => (
      <option key={index} value={org._id}>
        {org.org_name}
      </option>
    ));

    const agentOptions = retrievedAgents.filter(item => {
      const searchItem = selectedAgentName.toLocaleLowerCase()
      const name = item.first_name.toLowerCase()
      const emailId = item.email.toLocaleLowerCase()
      return (name.includes(searchItem) || emailId.includes(searchItem)) && name !== searchItem && this.props?.user?._id !== item?._id
    }).slice(0,4).map((agent, index) => {
      return (
        <div key={index} className={styles.users}  onClick={() => this.onUserInputClicked(agent.first_name, agent._id)} >
          {agent.first_name}
        </div>
      );
    });


    return (
      <div className={styles.modal_overlay}>
        <div className={styles.modal}>
          <div className={styles.crossbutton} onClick={setAssistedBokingPopup} >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="times"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path></svg>
                  </div>
          <div className={styles.assist_book_cont}>
          <h2 className={styles.agnc_head}>Channel</h2>
            <select className={styles.select_bx} value={selectedChannel} onChange={this.handleChannelChange}>
              <option value="">Select Channel</option>
              {channelOptions}
            </select>
            {
              selectedChannel === 'subbranch' && 
              <>
              <h2 className={styles.agnc_head}>Select an Sub Branch:</h2>
            <select className={styles.select_bx} value={selectedSubBranch} onChange={this.handleSubBranchChange}>
              <option value="">Select</option>
              {subBranches}
            </select>
              </>
            }
            {
            selectedChannel === 'subagency' && 
              <>
              <h2 className={styles.agnc_head}>Select an Sub Agency:</h2>
            <select className={styles.select_bx} value={selectedSubAgency} onChange={this.handleSubAgencyChange}>
              <option value="">Select</option>
              {subAgencies}
            </select>
              </>
            }
            {
              ((selectedChannel === 'agency') || (selectedSubBranch !== "")) && selectedChannel !== 'Agent' &&
              <>
                <h2 className={styles.agnc_head}>Select an Agency:</h2>
            <select className={styles.select_bx} value={selectedAgency} onChange={this.handleAgencyChange}>
              <option value="">Select</option>
              {agencyOptions}
              {selectedChannel === 'subbranch' && <option value={'Agent'}>Agents</option>}
            </select>

            {selectedAgency === 'Agent' || selectedAgency === '' ? null : (
              <>
            <h2 className={styles.agnc_head}>Subagencies:</h2>
              <select className={styles.select_bx}
                value={selectedSubAgency}
                onChange={this.handleSubAgencyChange}
              >
                <option value="">Select</option>
                {subAgencyOptions}
                {((selectedChannel === 'agency') || (selectedChannel === 'subbranch')) && <option value={selectedAgency} >Agent</option>}
              </select>
              </>
            )}
              </>
            }

            {loading && <div className={styles.login_loader} />}
            {(retrievedAgents.length > 0 || selectedChannel === 'Agent') && !loading ? (
              <>
            <h2 className={styles.agnc_head}>Agents:</h2>
                <input type="text" placeholder="Select an Agent" 
                className={styles.select_bx} 
                value={selectedAgentName} 
                onChange={this.handleAgentChange}
                onClick={() => {this.setState({showDropdown: true})}}
                 />
                 {
                  showDropdown &&   
                 <div className={styles.dropdown} ref={this.dropdownRef}>
                  {agentOptions}
                  </div>
                 }
                <button
                className={styles.ok_btn}
                  onClick={this.submitButton}
                >
                  Ok
                </button>
              </>
            ) : (
              // <p className={styles.subagncy_label}>No Agents found</p>
              null
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MobileTheme4);
